import api from '../api';

export type PortfolioType = 'recommends' | 'results';

export type PortfolioRootCategoryIdType = 1 | 6 | 7;

export type PortfolioParamsMeta = Record<string, number[]>;

export interface PortfolioParams {
  category_id?: number;
  subCategoryId?: number;
  recommendPortfolioId?: number;
  type?: PortfolioType;
  meta?: PortfolioParamsMeta;
  sort?: string;
  isBookmark?: boolean;
}

interface Gig {
  PID: number;
  title: string;
  category_id: number;
}

export interface GetMyGigsResponse {
  gig_list: Gig[];
}

interface GetMyGigsParams {
  userId: number;
  category_id: number;
}

const getMyGigs = async (params: GetMyGigsParams) => {
  const { data } = await api.get<GetMyGigsResponse>('/api/portfolio/v1/seller/gigs', {
    params,
  });

  return data?.gig_list ?? [];
};

export interface CategoryFormatTypeData {
  id: number;
  name: string;
}

export type CategoryFormatType = 'SELECT' | 'MULTIPLE' | 'COLOR_SELECT';

export interface CategoryFormat {
  id: number;
  title: string;
  type: CategoryFormatType;
  type_data: CategoryFormatTypeData[];
}

export interface GetCategoryFormatsResponse {
  format_list: CategoryFormat[];
}

const getCategoryFormats = async (
  category_id: number = 1,
  sub_category_id: number | null = null,
) => {
  const { data } = await api.get<GetCategoryFormatsResponse>('/api/portfolio/v1/seller/formats', {
    params: {
      category_id,
      sub_category_id,
    },
  });

  return data.format_list;
};

export interface CategorySkillFormatData {
  id: number;
  title: string;
  placeholder: string | null;
  description: string;
}

export interface CategorySkillFormat {
  parent_id: number;
  title: string;
  data: CategorySkillFormatData[];
}

export interface GetCategorySkillFormatsResponse {
  skill_format_list: CategorySkillFormat[];
}

const getCategorySkillFormats = async (category_id: number = 1) => {
  const { data } = await api.get<GetCategorySkillFormatsResponse>('/api/portfolio/v1/seller/skill-formats', {
    params: {
      category_id,
    },
  });

  return data.skill_format_list ?? [];
};

export interface Answer {
  format_id: number;
  type: CategoryFormatType;
  answer: string;
  item_list: CategoryFormatTypeData[];
}

export type OmitedItemListByAnswer = Omit<Answer, 'item_list'>;

export type AnswersParams = OmitedItemListByAnswer & {
  item_list: number[];
};

export interface Skill {
  id: number;
  format_id: number;
  parent_id: number;
  data_list: string[];
}

export type SkillParams = Omit<Skill, 'format_id'>;

export interface PostPortfolioParams {
  root_category_id: number;
  sub_category_id: number;
  title: string;
  main_image: {
    id: number;
    name: string;
  };
  content_files: number[];
  keywords: string[];
  skill_list?: SkillParams[];
  client?: string;
  start_date?: string;
  end_date?: string;
  description?: string;
  answer_list?: AnswersParams[];
  gig_list?: number[];
}

const postPortfolio = async (params: PostPortfolioParams) => {
  const { data } = await api.post('/api/portfolio/v1/seller/portfolios', params);

  return data;
};

export interface UploadPortfolioFilesParams {
  files: File[];
  type: 'IMAGE' | 'AUDIO' | 'VIDEO' | 'FILE';
  content_type: 'MAIN' | 'CONTENT';
}

type UpdatePortfolioParams = PostPortfolioParams;

const updatePortfolio = async (portfolioId: number, params: UpdatePortfolioParams) => {
  const { data } = await api.put(`/api/portfolio/v1/seller/portfolios/${portfolioId}`, params);

  return data;
};

export interface UploadPortfolioFilesResponse {
  id: number;
  url: string;
  name: string;
}

const uploadPortfolioFiles = async ({
  files,
  type,
  content_type,
}: UploadPortfolioFilesParams) => {
  const { data } = await api.post<UploadPortfolioFilesResponse>('/api/portfolio/v1/seller/files', {
    params: {
      files,
      type,
      content_type,
    },
  });

  return data;
};

export interface ContentFile {
  id: number;
  name: string;
  url: string;
}

type MainImage = ContentFile;

interface AnswerDetail {
  format_id: number;
  format_title: string;
  answer: string;
  item_list: CategoryFormatTypeData[];
}

interface GigDetail {
  PID: number;
  title: string;
  thumbnail: string;
  price: number;
}

export interface GetPortfolioDetailsResponse {
  id: number;
  root_category_id: number;
  sub_category_id: number;
  title: string;
  main_image: MainImage;
  content_files: ContentFile[];
  keywords: string[];
  skill_list: Skill[];
  description: string;
  client: string;
  start_date: string;
  end_date: string;
  answer_list: AnswerDetail[];
  gig_list: GigDetail[];
}

const getPortfolioDetails = async (portfolioId: number) => {
  const { data } = await api.get<GetPortfolioDetailsResponse>(`/api/portfolio/v1/seller/portfolios/${portfolioId}`);

  return data;
};

export interface PortfolioSubCategory {
  id: number;
  name: string;
  modules: CategoryModule[];
}

export type CategoryModule = 'IMAGE' | 'VIDEO';

export interface PortfolioCategory {
  rootCategoryId: number;
  rootCategoryName: string;
  subCategories: PortfolioSubCategory[];
}

export interface GetPortfolioCategoriesResponse {
  categories: PortfolioCategory[];
}

const getPortfolioCategories = async () => {
  const { data } = await api.get<GetPortfolioCategoriesResponse>('/api/portfolio/v2/categories');

  return data?.categories;
};

interface GetPortfolioFilterFormatsParams {
  rootCategoryId: number;
  subCategoryId?: number | null;
}

export interface Filter {
  id: number;
  title: string;
  items: FormatItem[];
}

export interface FormatItem {
  id: number;
  name: string;
}

export interface GetPortfolioFilterFormatsResponse {
  formats: Filter[];
}

const getPortfolioFilterFormats = async ({ rootCategoryId, subCategoryId }: GetPortfolioFilterFormatsParams) => {
  const { data } = await api.get<GetPortfolioFilterFormatsResponse>('/api/portfolio/v1/formats', {
    params: {
      category_id: rootCategoryId,
      sub_category_id: subCategoryId,
    },
  });

  return data.formats;
};

interface GetPortfoliosByFiltersParams {
  rootCategoryId: number;
  subCategoryId?: number;
  page?: number;
  sort?: 'created_at' | 'ranking_points';
  meta?: string;
  isBookmark?: boolean;
}

export interface PortfolioCardBase {
  id: number;
  thumbnail: string;
  title: string;
  keywords: string[];
  rootCategoryId: number;
  seller: PortfolioSeller;
  isCurrentUserBookmark: boolean;
  bookmarkCount: number;
}

/*
  @TODO: 포폴카드 api 수정되고 나면, seller required로 변경 필요
*/
export interface TempPortfolioCardBase {
  portfolioId: number;
  thumbnail: string;
  title: string;
  keywords: string[];
  category: TempPortfolioCategory;
  isCurrentUserBookmark: boolean;
  bookmarkCount: number;
  seller?: PortfolioSeller;
}

/*
  @TODO: 포폴카드 api 수정되고 나면, 카테고리 null 타입들 삭제 필요
*/
export interface TempPortfolioCategory {
  rootCategoryId: number;
  rootCategoryName: string | null;
  subCategoryId: number | null;
  subCategoryName: string | null;
}

export interface GetPortfoliosByFiltersResponse {
  total: number;
  lastPage: number;
  portfolios: PortfolioCardBase[];
}

const getPortfoliosByFilters = async (params: GetPortfoliosByFiltersParams) => {
  const { data } = await api.get<GetPortfoliosByFiltersResponse>('/api/portfolio/v3/portfolios', {
    params,
  });

  return data;
};

export interface PopularPortfolioWithSeller {
  seller: PortfolioSeller;
  portfolio: PopularPortfolio;
}

export interface PortfolioSeller {
  userId: number;
  nickname: string;
  thumbnail: string;
}

export interface PopularPortfolio {
  id: number;
  title: string;
  subCategoryId: number;
  rootCategoryId: number;
  thumbnail: string;
}

export interface GetPopularPortfoliosResponse {
  popularPortfolios: PopularPortfolioWithSeller[];
}

const getPopularPortfolios = async (rootCategoryId: number) => {
  const { data } = await api.get<GetPopularPortfoliosResponse>(`/api/portfolio/v1/recommend/${rootCategoryId}/popular`);

  return data;
};

export interface GetRecommendMetadataPortfoliosResponse {
  items: MetadataPortfolioItem[];
}

export interface MetadataPortfolioItem {
  itemId: number;
  contents: string;
  formatId: number;
  portfolios: PortfolioCardBase[];
}

const getRecommendMetadataPortfolios = async (rootCategoryId: number) => {
  const { data } = await api.get<GetRecommendMetadataPortfoliosResponse>(`/api/portfolio/v2/recommend/${rootCategoryId}/meta-data`);

  return data;
};

export interface GetAuthenticatedPortfoliosResponse {
  authenticatedPortfolios: AuthenticatedPortfolios[];
}

export interface AuthenticatedPortfolios extends PortfolioSeller {
  rootCategoryId: number;
  portfolios: AuthenticatedPortfolio[];
}

export interface AuthenticatedPortfolio {
  id: number;
  subCategoryId: number;
  thumbnail: string;
}

const getAuthenticatedPortfolios = async (rootCategoryId: number) => {
  const { data } = await api.get<GetAuthenticatedPortfoliosResponse>(`/api/portfolio/v1/recommend/${rootCategoryId}/new-authenticated`);

  return data;
};
export interface GetRecommendSubCategoriesResponse {
    subCategories: RecommendSubCategory[];
  }

export interface RecommendSubCategory {
    id: number;
    name: string;
  }

const getRecommendSubCategories = async (rootCategoryId: number) => {
  const { data } = await api.get<GetRecommendSubCategoriesResponse>(`/api/portfolio/v1/recommend/${rootCategoryId}/sub-categories`);

  return data;
};

export interface PortfolioCardsByCategoryIdsResponse {
  portfolios: TempPortfolioCardBase[];
}

const getPortfolioCardsByCategoryIds = async (portfolioIds: string) => {
  const { data } = await api.get<PortfolioCardsByCategoryIdsResponse>('/api/msa/gig-app/seller-profile/portfolio/v1/portfolio-cards', { params: { portfolioIds } });

  return data;
};

export interface PopularPortfolioCardsResponse {
  portfolios: TempPortfolioCardBase[];
}

export const portfolioApi = {
  getMyGigs,
  getCategoryFormats,
  getCategorySkillFormats,
  postPortfolio,
  updatePortfolio,
  uploadPortfolioFiles,
  getPortfolioDetails,
  getPortfolioCategories,
  getPortfolioFilterFormats,
  getPortfoliosByFilters,
  getPopularPortfolios,
  getRecommendMetadataPortfolios,
  getAuthenticatedPortfolios,
  getRecommendSubCategories,
  getPortfolioCardsByCategoryIds,
};
