declare global {
  interface Window {
    lintrk: (eventType: string, properties: { [key in string]: unknown }) => void;
  }
}

const internalLinkedIn = (eventType: string, properties: { [key in string]: unknown }) => {
  if (typeof window === 'undefined') {
    return;
  }

  if (!window.lintrk) {
    return;
  }

  window.lintrk(eventType, properties);
};

const trackCustom = (conversionId: number) => {
  internalLinkedIn('track', {
    conversion_id: conversionId,
  });
};

export default {
  trackCustom,
};
