import { CLOUDFRONT_URL } from '@kmong-service/utils';
import api from '../api';
import type { TempGig } from '../gig';

export interface PopularResponse {
  gigs: TempGig[];
}

interface PopularsParams {
  subCategoryId: number;
  takeCount?: number;
}

const getPopulars = async ({ subCategoryId, takeCount = 20 }: PopularsParams) => {
  const { data } = await api.get<PopularResponse>(`/api/msa/gig-app/gig/v1/top-order-gigs/sub-category?takeCount=${takeCount}&subCategoryId=${subCategoryId}`);

  return data?.gigs ?? [];
};

export interface PopularTabCategory {
  categoryId: number;
  categoryName: string;
}

export interface PopularTabCategoriesResponse {
  categories: PopularTabCategory[];
}

const getPopularTabCategories = async (categoryId: number): Promise<PopularTabCategoriesResponse | null> => {
  try {
    const data = await fetch(`${CLOUDFRONT_URL}/assets/gateway/categories/${categoryId}/top-order-gig/categories.json`);

    return data.json();
  } catch {
    return null;
  }
};

export const popularApi = {
  getPopulars,
  getPopularTabCategories,
};
