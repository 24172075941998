import api from '../api';

interface GetActiveAdsCountResponse {
  active_ads_count: number;
}

const getActiveAdsCount = async (): Promise<number> => {
  const { data } = await api.get<GetActiveAdsCountResponse>('/api/v5/my-kmong/SELLER/kmong-ads.info');

  return data?.active_ads_count ?? 0;
};

export enum AdsStatus {
  ALL = 'ALL',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export interface GetAdsHistoriesParams {
  status: AdsStatus;
  start_date: string;
  end_date: string;
}

export interface AdsHistoryPayment {
  type: string;
  name: string;
  amount: number;
  pay_type?: string | null;
  pay_type_name?: string | null;
  TID?: string | null;
  LGD_OID?: number | null;
  cash_seq_no?: string | null;
  store_id?: string | null;
  pay_type_lg?: string | null;
  hash_data?: string | null;
  account_name?: string | null;
  account_number?: string | null;
  account_payer?: string | null;
  expiration_date?: string | null;
}

export interface AdsHistoryJumpUp {
  title: string;
  jumped_at: string;
}

export interface AdsHistoryProduct {
  title: string;
  image: string;
  OID: number;
}

export interface AdsHistory {
  id: number;
  type: string;
  type_id: number;
  status: string;
  amount: number;
  status_ko: string;
  started_at: string;
  ended_at: string;
  ad_title: string;
  ad_type: string;
  ad_type_str: string;
  link: string;
  product: AdsHistoryProduct;
  payments: AdsHistoryPayment[];
  jumped_up_top_histories: AdsHistoryJumpUp[];
}

interface GetAdsHistoriesResponse {
  histories: AdsHistory[];
  last_page: number;
  next_page_link: string | null;
  previous_page_link: string | null;
  total: number;
}

const getAdsHistories = async (params: GetAdsHistoriesParams): Promise<GetAdsHistoriesResponse> => {
  const { data } = await api.get<GetAdsHistoriesResponse>('/api/v5/my-kmong/SELLER/kmong-ads', { params });

  return data;
};

export interface GetTaxInvoiceHistoriesParams {
  page: number;
  perPage: number;
}

export interface TaxInvoiceHistory {
  sellerOrderTaxInvoiceId: number;
  paidAmount: number;
  termsDateTime: string;
}

interface GetTaxInvoiceHistoriesResponse {
  totalItemCount: number;
  lastPage: number;
  currentPage: number;
  perPage: number;
  items: TaxInvoiceHistory[];
}

const getTaxInvoiceHistories = async (params: GetTaxInvoiceHistoriesParams) => {
  const { data } = await api.get<GetTaxInvoiceHistoriesResponse>('/api/msa/order-app/payment/v1/payments/seller/seller-order/tax-invoice/histories', { params });

  return data;
};

interface SellerOrderTaxInvoiceAmount {
  paidAmount: number;
  supplyCostAmount: number;
  vatAmount: number;
}

export interface GetTaxInvoiceDetailHistory {
  sellerOrderTaxInvoiceId: number;
  sellerUserId: number;
  sellerOrderId: number;
  productName: string;
  sellerOrderTaxInvoiceAmount: SellerOrderTaxInvoiceAmount;
  termsDateTime: string;
  startedDateTime: string;
}

interface AggregateAmount {
  paidAmount: number;
  supplyCostAmount: number;
  vatAmount: number;
}

export interface GetTaxInvoiceDetailResponse {
  histories: GetTaxInvoiceDetailHistory[];
  aggregateAmount: AggregateAmount;
}

const getTaxInvoiceDetail = async (sellerOrderTaxInvoiceId: number) => {
  const { data } = await api.get<GetTaxInvoiceDetailResponse>(`/api/msa/order-app/payment/v1/payments/seller/seller-order/tax-invoice/${sellerOrderTaxInvoiceId}/histories`);

  return data;
};

export const advertisementApis = {
  getActiveAdsCount,
  getAdsHistories,
  getTaxInvoiceHistories,
  getTaxInvoiceDetail,
};
