import { CLOUDFRONT_URL } from '@kmong-service/utils';
import api from '../api';
import type { SubCategoryWithRootCategory } from './categories';
import type { TopSellersWithCategory } from './topSellers';
import type { TempGig } from '../gig';
import type { TempPortfolioCardBase } from '../portfolio';

interface DisplaySearchKeywordsResponse {
  keywords: string[];
}

interface DisplayTopSellersResponse {
  topSellers: TopSellersWithCategory[];
}

export interface GigsResponse {
  gigs: TempGig[];
}

interface GetPopularPortfoliosResponse {
  portfolios: TempPortfolioCardBase[];
}

interface GetRecommendCategoriesResponse {
  categories: SubCategoryWithRootCategory[];
}

const getDisplaySearchKeywords = async (takeCount: number = 5) => {
  const { data } = await api.get<DisplaySearchKeywordsResponse>('/api/display/v1/search-keywords', {
    params: {
      takeCount,
    },
  });

  return data?.keywords;
};

const getDisplayTopSellers = async () => {
  const { data } = await api.get<DisplayTopSellersResponse>('/api/display/v1/top-sellers');

  return data?.topSellers;
};

const getRecentBookmarkGigs = async (takeCount: number) => {
  const { data } = await api.get<GigsResponse>('/api/msa/gig-app/gig/v1/recent-bookmark-gigs', {
    params: {
      takeCount,
    },
  });

  return data?.gigs;
};

interface GetPopularPortfoliosParams {
  rootCategoryId: number;
  takeCount: number;
}

const getPopularPortfolios = async ({ rootCategoryId, takeCount }: GetPopularPortfoliosParams) => {
  const { data } = await api.get<GetPopularPortfoliosResponse>(`/api/msa/gig-app/seller-profile/portfolio/v1/${rootCategoryId}/popular-portfolios`, {
    params: {
      takeCount,
    },
  });

  return data?.portfolios;
};

const getTopOrderGigs = async (takeCount: number) => {
  const { data } = await api.get<GigsResponse>('/api/msa/gig-app/gig/v1/top-order-gigs', {
    params: {
      takeCount,
    },
  });

  return data?.gigs;
};

interface GigIdWithTakeCountParams {
  gigId: number;
  takeCount: number;
}

const getQuicklyCompletedGigs = async ({ gigId, takeCount }: GigIdWithTakeCountParams) => {
  const { data } = await api.get<GigsResponse>('/api/msa/gig-app/gig/v1/quickly-completed-gigs', {
    params: {
      gigId,
      takeCount,
    },
  });

  return data?.gigs;
};

const getRecentViewedGigs = async (gigs: string) => {
  const { data } = await api.get<GigsResponse>('/api/msa/gig-app/gig/v1/recent-viewed-gigs', {
    params: {
      gigIds: gigs,
    },
  });

  return data?.gigs;
};

const getRecommendGigs = async (takeCount: number) => {
  const { data } = await api.get<GigsResponse>('/api/msa/gig-app/gig/v1/recommend-gigs', {
    params: {
      takeCount,
    },
  });

  return data?.gigs;
};

const getSimilarViewedGigs = async ({ gigId, takeCount }: GigIdWithTakeCountParams) => {
  const { data } = await api.get<GigsResponse>('/api/msa/gig-app/gig/v1/similar-viewed-gigs', {
    params: {
      gigId,
      takeCount,
    },
  });

  return data?.gigs;
};

const getRecommendCategories = async (takeCount: number) => {
  const { data } = await api.get<GetRecommendCategoriesResponse>('/api/msa/gig-app/category/v1/recommend-categories', {
    params: {
      takeCount,
    },
  });

  return data?.categories;
};

const getSimilarPurchasedGigs = async (takeCount: number) => {
  const { data } = await api.get<GigsResponse>('/api/msa/gig-app/gig/v1/similar-purchased-gigs', {
    params: {
      takeCount,
    },
  });

  return data?.gigs;
};

export interface PopularCategoryGigsParams {
  subCategoryId: number;
  takeCount: number;
}

const getPopularCategoryGigs = async ({ subCategoryId, takeCount }: PopularCategoryGigsParams) => {
  const { data } = await api.get<GigsResponse>('/api/msa/gig-app/gig/v1/top-order-gigs/sub-category', {
    params: {
      takeCount,
      subCategoryId,
    },
  });

  return data?.gigs;
};

export interface PopularCategoriesResponse {
  categories: PopularCategory[];
}

export interface PopularCategory {
  categoryId: number;
  categoryName: string;
}

const getPopularCategories = async (): Promise<PopularCategoriesResponse | null> => {
  const data = await fetch(`${CLOUDFRONT_URL}/assets/feeds/popularCategories.json`);

  return data.json();
};

export const indexThemesApi = {
  getDisplayTopSellers,
  getDisplaySearchKeywords,
  getRecentBookmarkGigs,
  getPopularPortfolios,
  getTopOrderGigs,
  getQuicklyCompletedGigs,
  getRecentViewedGigs,
  getRecommendGigs,
  getSimilarViewedGigs,
  getRecommendCategories,
  getSimilarPurchasedGigs,
  getPopularCategoryGigs,
  getPopularCategories,
};
