import React from 'react';
import Script from 'next/script';
import { markup } from '@kmong/utils';
import {
  getBreadcrumbJsonLd,
  getFaqJsonLd,
  getMinifyJsonLd,
  getOrganizationJsonLd,
  getPersonJsonLd,
  getProductJsonLd,
  getWebSiteJsonLd,
} from './jsonLd';

export interface LastItemListElement {
  name: string;
  position: number;
}

export interface ItemListElement extends LastItemListElement {
  item: string;
}

/**
 * 하나 이상의 ItemListElement를 가지며, 마지막 요소는 item(url)이 제외됩니다.
 * 단, 배열에 항목이 하나만 있으면 item(Url)이 포함됩니다.
 * (참고: https://kmong.slack.com/archives/CTNB351GX/p1595207878006600)
 */
export type BreadcrumbItemListJsonLd =
  | [ItemListElement] // 하나일 때 item 포함
  | [...ItemListElement[], Omit<ItemListElement, 'item'>]; // 여러 개일 때 마지막 요소는 item 제외

export interface FAQJsonLd {
  question: string;
  answer: string;
}

export interface AddressJsonLd {
  streetAddress: string;
  addressLocality: string;
  addressRegion?: string;
  postalCode: string;
  addressCountry: string;
}

export interface OrganizationJsonLd {
  name: string;
  url: string;
  alternateName?: string;
  description?: string;
  logo?: string;
  sameAs?: string[];
  address?: AddressJsonLd | AddressJsonLd[];
}

export interface PersonJsonLd {
  name: string;
  url: string;
  alternateName?: string;
  description?: string;
  sameAs?: string[];
}

interface Offers {
  lowPrice: number;
  highPrice: number;
  priceCurrency: string;
  offerCount?: number;
}

interface ReviewRating {
  ratingValue: number;
  bestRating: number;
  worstRating: number;
}

interface Author {
  name: string;
}

interface Review {
  reviewBody: string;
  reviewRating: ReviewRating;
  author: Author;
}

interface AggregateRating {
  bestRating: number;
  worstRating: number;
  ratingValue?: number;
  ratingCount?: number;
  reviewCount?: number;
}

export interface ProductJsonLd {
  name: string;
  description: string;
  image: string;
  sku?: string;
  offers?: Offers;
  reviews?: Review[];
  aggregateRating?: AggregateRating;
}

export interface PotentialActionJsonLd {
  urlTemplate: string;
  queryInput: string;
}

export interface WebSiteJsonLd {
  url: string;
  potentialAction: PotentialActionJsonLd | PotentialActionJsonLd[];
}

export interface BuildJsonLdTagsProps {
  organization?: OrganizationJsonLd;
  person?: PersonJsonLd;
  product?: ProductJsonLd;
  website?: WebSiteJsonLd;
  breadcrumbItemList?: BreadcrumbItemListJsonLd;
  faqs?: FAQJsonLd[];
}

export const buildJsonLdTags = ({
  organization,
  person,
  product,
  website,
  breadcrumbItemList,
  faqs,
}: BuildJsonLdTagsProps): JSX.Element[] => {
  const jsonLdData = [
    breadcrumbItemList && { key: 'breadcrumb-item-list', data: getBreadcrumbJsonLd(breadcrumbItemList) },
    faqs?.length && { key: 'faq-list', data: getFaqJsonLd(faqs) },
    organization && { key: 'organization', data: getOrganizationJsonLd(organization) },
    person && { key: 'person', data: getPersonJsonLd(person) },
    product && { key: 'product', data: getProductJsonLd(product) },
    website && { key: 'website', data: getWebSiteJsonLd(website) },
  ].filter((item): item is { key: string; data: string } => Boolean(item));

  return jsonLdData?.map(({ key, data }) => (
    // eslint-disable-next-line @next/next/no-before-interactive-script-outside-document
    <Script
      key={key}
      id={key}
      strategy="beforeInteractive"
      type="application/ld+json"
      dangerouslySetInnerHTML={markup(getMinifyJsonLd(data))}
    />
  ));
};
