import { CLOUDFRONT_URL } from '@kmong-service/utils';

export interface LuckyResultItem {
  id: number;
  taste: string;
  fish_outer_img: string;
  title_img: string;
  today_message: string;
  color: string;
  VIP_initial: string;
  lucky_item: string;
  positive_fish: string;
  positive_fish_img: string;
  negative_fish: string;
  negative_fish_img: string;
}

const getLuckyJSONItems = async () => {
  try {
    const response = await fetch(`${CLOUDFRONT_URL}/assets/lucky-fish/LUCKY_FISH_ITEMS.json`, {
      cache: 'force-cache',
    });
    const messages = await response.json() as LuckyResultItem[];

    return messages;
  } catch {
    return [];
  }
};

export const luckyFishApi = {
  getLuckyJSONItems,
};
