import api from './api';
import type { File, ProjectType } from './customProject';
import type { MyPortfolio } from './seller/seller';

type ProposalButtonType = 'BOOKMARK' | 'MEETING_REQUEST' | 'INBOX'
 | 'ORDER' | 'TRACK' | 'CLOSED' | 'PENALTY' | 'VACATION'
 | 'RETRACT' | 'RECANT_SELLER' | 'RECANT_BUYER' | 'RESEND_PROPOSAL';

export type ProposalStatus = 'APPROVED' | 'REJECTED' | 'CLOSED' | 'RECANT' | 'ONGOING' | 'WAITING';

type RequestStatus = 'APPROVAL' | 'CLOSED' | 'REJECTED' | 'ORDER' | 'DELETED';

interface Request {
  USERID: number;
  days: number;
  id: number;
  project_type: ProjectType;
  proposal_count: number;
  root_category_id: number;
  root_category_name: string;
  sub_category_id: number;
  sub_category_name: string;
  title: string;
  status: RequestStatus;
  username: string;
}

interface Seller {
  USERID: number;
  is_recommended_seller: boolean;
  username: string;
}

interface AmountDetail {
  contents: string;
  price: string;
  title: string;
  type: string;
}

export interface Proposal {
  OID: number | null;
  after_proposal_id: string | null;
  amount: number;
  amount_details: AmountDetail[] | null;
  before_proposal_id: string | null;
  content: string;
  days: number;
  files: File[];
  id: number;
  image: string;
  is_available_to_inquiry: boolean;
  is_bookmarked: boolean;
  is_requested_interview: boolean;
  is_show_interview_button: boolean;
  portfolio: MyPortfolio | null;
  request: Request;
  seller: Seller;
  status: ProposalStatus;
}

const getProposalDetail = async (proposalId: number) => {
  const { data } = await api.get<Proposal>(`/api/custom-project/v2/proposals/${proposalId}`);

  return data;
};

export interface ProposalButton {
  type: ProposalButtonType;
  text: string;
  isDisabled?: boolean;
  meetingRequestLeft?: number;
}

export interface ProposalButtonsResponse {
  buttons: ProposalButton[];
}

const getProposalButtons = async (proposalId: number) => {
  const { data } = await api.get<ProposalButtonsResponse>(`/api/custom-project/v2/proposals/${proposalId}/buttons`);

  return data;
};

interface UpdateBookmarkStatusProps {
  requestId: number;
  proposalId: number;
  isBookmark: boolean;
}

const updateBookmarkStatus = async ({ requestId, proposalId, isBookmark }: UpdateBookmarkStatusProps) => {
  if (isBookmark) {
    await api.put(`/api/v5/custom-project/buyer/requests/${requestId}/proposals/${proposalId}/bookmark`);

    return;
  }

  await api.post(`/api/v5/custom-project/buyer/requests/${requestId}/proposals/${proposalId}/bookmark`);
};

interface Meta {
  msg: string;
  status: number;
}

export interface RequestDirectOrderResponse {
  DOID: number;
  meta: Meta;
}

const requestDirectOrder = async (proposalId: number) => {
  const { data } = await api.post<RequestDirectOrderResponse>('/payments/types/CUSTOM_PROJECT/direct-orders', { proposal_id: proposalId });

  return data;
};

export type MeetingStatus = 'APPROVAL' | 'ACCEPTED' | 'CANCELED';

export interface MeetingSchedule {
  id: number;
  status: MeetingStatus;
  isResponded: boolean | null;
  meetingAt: string | null;
  meetingLink: string | null;
  canceledMessage: string | null;
}

const getMeetingSchedules = async (proposalId: number, userId: number) => {
  const { data } = await api.get<MeetingSchedule>(`/api/msa/enterprise/v1/proposal/${proposalId}/meeting?userId=${userId}`);

  return data;
};

export const proposalApi = {
  getProposalDetail,
  updateBookmarkStatus,
  requestDirectOrder,
  getProposalButtons,
  getMeetingSchedules,
};
