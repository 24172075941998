import { CLOUDFRONT_URL } from '@kmong-service/utils';
import api from './api';

interface GetIsExpectedSEOParams {
  providerType: 'GIG' | 'USER';
  provider: number;
}

interface GetIsExpectedSEOResponse {
  isExceptedSEO: boolean;
}

/**
 * @returns isExceptedSEO의 값이 true 인 경우 색인이 차단되어야 합니다. (noindex, nofollow)
 */
const getIsExpectedSEO = async (params: GetIsExpectedSEOParams) => {
  try {
    const { data } = await api.get<GetIsExpectedSEOResponse>('/api/msa/user-app/user/v1/users/excepted-seo', {
      params,
    });

    return data?.isExceptedSEO ?? false;
  } catch {
    return false;
  }
};

export interface AttentionCategory {
  id: number;
  name: string;
  is_selected: boolean;
}

export interface GetAttentionCategoryResponse {
  attention_categories: AttentionCategory[];
}

const getAttentionCategories = async () => {
  const { data } = await api.get<GetAttentionCategoryResponse>('/api/category/v1/attention-categories');

  return data.attention_categories;
};

export interface SaveInterestedCategoriesParams {
  USERID: number;
  category_list: string;
}

const saveInterestedCategories = async (params: SaveInterestedCategoriesParams) => {
  const { data } = await api.post('/api/v5/campaign/user-category/', params);

  return data;
};

export interface SaveAttentionCategoriesParams {
  attention_categories: string;
}

const patchAttentionCategories = async (payload: SaveAttentionCategoriesParams) => {
  const { data } = await api.patch('/api/user/v2/attention-categories', payload);

  return data;
};

export interface MarketingReportsResponse {
  total_user_count: number;
  total_rating_average: number;
  total_seller_count: number;
  total_gig_count: number;
  total_order_count: number;
  total_rating_count: number;
  total_category_count: number;
}

const getMarketingReports = async (): Promise<MarketingReportsResponse> => {
  const data = await fetch(`${CLOUDFRONT_URL}/assets/feeds/kmong-marketing-reports.json`);

  return data.json();
};

interface TermLinkProps {
  title: string;
  url: string;
}

export interface GetWebsiteTermLinksProps {
  termsOfService: TermLinkProps;
  personalInfoCollection: TermLinkProps;
  marketingConsent: TermLinkProps;
  salesTerms: TermLinkProps;
  notices: TermLinkProps;
  faq: TermLinkProps;
  termsAndPolicies: TermLinkProps;
  privacyPolicy: TermLinkProps;
}

const getWebsiteTermLinks = async (): Promise<GetWebsiteTermLinksProps> => {
  const data = await fetch(`${CLOUDFRONT_URL}/assets/static-json/website_terms_link.json`);

  return data.json();
};

interface GetCheckLatestBuildVersionResponse {
  version: string;
}

const getCheckLatestBuildVersion = async () => {
  try {
    const { data } = await api.get<GetCheckLatestBuildVersionResponse>('/api/next/revalidate/check-latest-app-version');

    return data?.version ?? '';
  } catch {
    return '';
  }
};

export type LinkTarget = 'CATEGORY' | 'GIG_DETAIL' | 'SEARCH' | 'WEB_VIEW' | 'DEEP_LINK' | 'EXTERNAL_LINK' | 'COUPON_DIRECT_DOWNLOAD_ID';

export interface LinkInfo {
  target: LinkTarget;
  value: string;
}

export enum CommonErrorCode {
  COMMON_ENTITY_NOT_FOUND = 'COMMON_ENTITY_NOT_FOUND',
}

export const commonApi = {
  getIsExpectedSEO,
  getAttentionCategories,
  saveInterestedCategories,
  patchAttentionCategories,
  getMarketingReports,
  getCheckLatestBuildVersion,
  getWebsiteTermLinks,
};
