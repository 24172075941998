import type { User } from '@kmong/rest-client/src/auth';
import { getConvertedProperties, logTracker } from '../../utils';

declare global {
  interface Window {
    ChannelIO?: (...args: unknown[]) => void;
    ChannelIOInitialized: boolean;
    attachEvent: (...args: unknown[]) => void;
  }
}

const internalChannelTalk = (): typeof window.ChannelIO | null => {
  if (typeof window === 'undefined') {
    return null;
  }

  if (typeof window.ChannelIO === 'function') {
    return window.ChannelIO;
  }

  return null;
};

const boot = (settings: Record<string, unknown>, callback?: () => void) => {
  internalChannelTalk()?.('boot', {
    pluginKey: process.env.NEXT_PUBLIC_CHANNEL_TALK_ENTEPRISE_PLUGIN_KEY,
    ...settings,
  }, callback);
};

const track = (eventName: string, properties: { [key in string]: unknown }) => {
  internalChannelTalk()?.('track', eventName, properties);
  const convertedProperties = getConvertedProperties(properties);

  logTracker({
    eventName,
    vender: 'ChannelTalk',
    properties: JSON.stringify(convertedProperties),
  });
};

const shutdown = () => {
  internalChannelTalk()?.('shutdown');
};

const updateUser = (user: User) => {
  internalChannelTalk()?.('updateUser', {
    profile: {
      USERID: user.USERID,
      email: user.email,
      name: user.username,
      sellerGrade: user.rating,
    },
    memberId: user.USERID,
  });
};

const setPage = (url: string) => {
  internalChannelTalk()?.('setPage', `${window.location.origin}${url}`);
};

const showMessenger = () => {
  internalChannelTalk()?.('showMessanger');
};

const hideMessenger = () => {
  internalChannelTalk()?.('hideMessanger');
};

const showChannelButton = () => {
  internalChannelTalk()?.('showChannelButton');
};

const hideChannelButton = () => {
  internalChannelTalk()?.('hideChannelButton');
};

const pageView = () => {
  track('PageView', {});
};

export default {
  boot,
  track,
  shutdown,
  showMessenger,
  hideMessenger,
  setPage,
  updateUser,
  showChannelButton,
  hideChannelButton,
  pageView,
};
