declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rtbhEvents: any[];
  }
}

const internalRTB = () => {
  if (typeof window === 'undefined') {
    return null;
  }

  if (!window.rtbhEvents) {
    return null;
  }

  return window.rtbhEvents;
};

/**
 * 이름은 initialize 이지만 사실상 pageView 로 쓰임
 */
const initialize = () => {
  internalRTB()?.push({
    eventType: 'init',
    value: process.env.NEXT_PUBLIC_RTBHOUSE_KEY,
    dc: 'asia',
  });
};

const viewIndexPage = () => {
  internalRTB()?.push(
    {
      eventType: 'home',
    },
    {
      eventType: 'uid',
      id: '',
    },
  );
};

const viewCategoryPage = (lastCategoryId: string) => {
  internalRTB()?.push(
    {
      eventType: 'category',
      categoryId: lastCategoryId,
    },
    {
      eventType: 'uid',
      id: '',
    },
  );
};

const viewGigDetailPage = (gigId: string) => {
  internalRTB()?.push(
    {
      eventType: 'offer',
      offerId: String(gigId),
    },
    {
      eventType: 'uid',
      id: '',
    },
  );
};

const viewSearchPage = () => {
  internalRTB()?.push(
    {
      eventType: 'listing',
      offerIds: '',
    },
    {
      eventType: 'uid',
      id: '',
    },
  );
};

export default {
  initialize,
  viewIndexPage,
  viewGigDetailPage,
  viewCategoryPage,
  viewSearchPage,
};
