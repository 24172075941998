import { captureException } from '@sentry/nextjs';

declare global {
  interface Window {
    ttq: {
      track: (eventName: EventName, params: TikTokParams) => void;
      load: (id: string) => void;
      page: () => void;
    };
  }
}

export interface TikTokViewParams {
  content_type: 'product_group';
  quantity: number;
  content_id: number;
  currency: 'KRW';
  value: number;
}

export interface CompleteRegistrationProps {
  USERID: string;
}

type TikTokParams = TikTokViewParams | CompleteRegistrationProps;

type EventName = 'PageView' | 'ViewContent' | 'AddToCart' | 'CompleteRegistration';

function internalTrack(eventName: 'AddToCart', params: TikTokViewParams): void;
function internalTrack(eventName: 'PageView', params: TikTokViewParams): void;
function internalTrack(eventName: 'ViewContent', params: TikTokViewParams): void;
function internalTrack(eventName: 'CompleteRegistration', params: CompleteRegistrationProps): void;

function internalTrack(eventName: EventName, params: TikTokParams) {
  internalTiktok()?.track(eventName, params);
}

const internalTiktok = () => {
  if (typeof window === 'undefined') {
    return null;
  }

  if (!window.ttq) {
    return null;
  }

  return window.ttq;
};

const load = () => {
  const TIKTOK_ID = process.env.NEXT_PUBLIC_TIKTOK ?? '';
  internalTiktok()?.load(TIKTOK_ID);
};

const pageView = () => {
  internalTiktok()?.page();
};

const viewContent = (params: TikTokViewParams) => {
  try {
    internalTrack('ViewContent', params);
  } catch (e) {
    captureException(e);
  }
};

const addToCart = (params: TikTokViewParams) => {
  try {
    internalTrack('AddToCart', params);
  } catch (e) {
    captureException(e);
  }
};

const completeRegistration = (params: CompleteRegistrationProps) => {
  try {
    internalTrack('CompleteRegistration', params);
  } catch (e) {
    captureException(e);
  }
};

export default {
  load,
  pageView,
  viewContent,
  addToCart,
  completeRegistration,
};
