import api from './api';

interface SendGCLIDParams {
  googleClickId: string;
  enteredDateTime: string;
}

interface SendGCLIDResponse {
  userGoogleClickId: number;
}

const postGCLID = async (params: SendGCLIDParams) => {
  const { data } = await api.post<SendGCLIDResponse>('/api/msa/user-app/user/v1/users/user-google-click-id', params);

  return data;
};

export const gclidApi = {
  postGCLID,
};
