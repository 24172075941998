import type { Metadata } from 'next';
import { defaultKeywords } from './defaultKeywords';
import getFavicon from './utils/getFavicon';

const {
  title, description, ogImage,
} = defaultKeywords;

/**
 * @link https://nextjs.org/docs/app/api-reference/functions/generate-viewport#width-initialscale-maximumscale-and-userscalable
 * @description viewport는 자동으로 설정됩니다. 추가 설정이 필요한 경우에만 설정하세요.
 * @description generateMetadata는 동적 렌더링으로 변경되므로 필요한 페이지 내에서 따로 정의하세요.
 * 순서: rootLayout -> nestedLayout -> page
 */
export const defaultMetadata: Metadata = {
  other: {
    'X-UA_Compatible': 'IE=edge',
  },
  verification: {
    google: 'fzUsdY8AFahsHxAgVke2Wh1Y_N6hE_dYMWomEwRmn8c',
    other: {
      'naver-site-verification': 'f79c29666f9a1588a8e5ac73f8efc9b06ae92e26',
    },
  },
  manifest: '/manifest.json',
  title,
  description,
  openGraph: {
    type: 'website',
    images: [ogImage],
    url: 'https://kmong.com',
    locale: 'ko_KR',
    siteName: '크몽',
  },
  icons: {
    icon: getFavicon(),
  },
  metadataBase: new URL('https://kmong.com'),
  alternates: {
    canonical: './',
  },
};
