import { cookies, CookiesKeyEnum } from '@kmong-service/utils';
import type { OptionsType } from 'cookies-next/lib/types';

export const getAlreadySignupUsersCookie = (options: OptionsType): string | null => {
  const alreadySignupUserCookie = cookies.getItem(CookiesKeyEnum.ALREADY_SIGNUP_USERS, options);

  if (!alreadySignupUserCookie) {
    return null;
  }

  return parseCookieValue(alreadySignupUserCookie);
};

const parseCookieValue = (cookie: string): string | null => (cookie ? JSON.parse(cookie) : null);
