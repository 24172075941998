import api from '../api';
import type { PageParams, PageResponse } from '../types';

export enum PostCounselCoinRefundCheckoutsErrorCode {
  COUNSEL_COIN_REFUND_ERROR_IN_COUNSEL_PROGRESS = 'COUNSEL_COIN_REFUND_ERROR_IN_COUNSEL_PROGRESS',
  AUTH_NOT_LOGGED_IN = 'AUTH_NOT_LOGGED_IN',
  COUNSEL_COIN_REFUND_ERROR_ALREADY_EXIST_REFUND_REQUEST = 'COUNSEL_COIN_REFUND_ERROR_ALREADY_EXIST_REFUND_REQUEST',
  COUNSEL_COIN_REFUND_ERROR_NOT_EXIST_AMOUNT = 'COUNSEL_COIN_REFUND_ERROR_NOT_EXIST_AMOUNT',
  REFUND_ACCOUNT_NOT_FOUND = 'REFUND_ACCOUNT_NOT_FOUND',
  COUNSEL_COIN_REFUND_ERROR_COUNSEL_ORDER_CREATE_PROGRESS = 'COUNSEL_COIN_REFUND_ERROR_COUNSEL_ORDER_CREATE_PROGRESS',
}

const postCounselCoinRefundCheckouts = async () => {
  const { data } = await api.delete('/api/counsel-coin/payment/refund/v1/refund-checkouts');

  return data;
};

export interface BuyerCounselHistory {
  counselOrder: BuyerCounselOrder;
  counselProduct: BuyerCounselProduct;
}

interface CounselOrder {
  id: number;
  counselStartedDateTime: string;
  counselEndedDateTime: string;
  counselDurationSeconds: number;
  createdDateTime: string;
  completedDateTime: string;
  orderAmount: number;
}

export interface BuyerCounselOrder extends CounselOrder {
  reviewWritableType: CoinHistoryReviewWritableType;
}

interface BuyerCounselProduct extends CounselCategories {
  id: number;
  title: string;
  extensionNumber: number;
  image: string;
}

export interface CounselCategories {
  rootCategoryId: number;
  rootCategoryName: string;
  subCategoryId: number;
  subCategoryName: string;
}

export type CoinHistoryReviewWritableType = 'WRITEABLE' | 'ALREADY_WRITTEN' | 'EXPIRED' | 'INSUFFICIENT_COUNSEL_TIME';

export type GetBuyerCounselHistoriesResponse = PageResponse<BuyerCounselHistory>;

const getBuyerCounselHistories = async ({ page = 1, perPage = 10 }: PageParams) => {
  const { data } = await api.get<GetBuyerCounselHistoriesResponse>('/api/msa/order-app/counsel/order/v1/orders/buyer/histories', {
    params: {
      page,
      perPage,
    },
  });

  return data;
};

export type GetSellerCounselHistoriesResponse = PageResponse<SellerCounselHistory>;

export interface SellerCounselHistory {
  counselOrder: SellerCounselOrder;
  buyer: SellerCoinHistoryBuyer;
}

interface SellerCounselOrder extends CounselOrder {
  expectedProfitAmount: number;
}

interface SellerCoinHistoryBuyer {
  id: number;
  nickname: string;
  image: string;
}

const getSellerCounselHistories = async ({ page = 1, perPage = 10 }: PageParams) => {
  const { data } = await api.get<GetSellerCounselHistoriesResponse>('/api/msa/order-app/counsel/order/v1/orders/seller/histories', {
    params: {
      page,
      perPage,
    },
  });

  return data;
};

export interface CounselProfitAmountResponse {
  expectedProfitAmount: number;
  withdrawnCompletedProfitAmount: number;
}

const getCounselProfitAmount = async () => {
  const { data } = await api.get<CounselProfitAmountResponse>('/api/msa/order-app/counsel/profit/v1/orders/profit/amount/aggregate');

  return data;
};

export type GetCoinsHistoriesResponse = PageResponse<CoinsHistory>;

export interface CoinsHistory {
  coinAccounts: CoinAccountItem[];
  orderAggregate: CoinOrderAggregate | null;
}

export type CoinAccountItem = {
  id: number;
  amount: number;
  expiredDateTime: string;
  createdDateTime: string;
} & CoinAccountItemBase;

type CoinAccountItemBase = BasePaymentType | MileageCheckoutType;

interface BasePaymentType {
  payType: CoinPayTypeEnum;
  type: CoinActionTypeEnum;
  checkoutType: CoinCheckoutTypeEnum;
}

interface MileageCheckoutType {
  payType: null;
  type: CoinActionTypeEnum.CHARGED | CoinActionTypeEnum.PAID;
  checkoutType: CoinCheckoutTypeEnum.MILEAGE;
}

export enum CoinPayTypeEnum {
  CARD = 'CARD',
  BANK = 'BANK',
  VBANK = 'VBANK',
}

export enum CoinActionTypeEnum {
  PAID = 'PAID',
  REFUNDED = 'REFUNDED',
  CHARGED = 'CHARGED',
  EXPIRED = 'EXPIRED',
}

export enum CoinActionLabelEnum {
  PAID = '상담 완료',
  REFUNDED = '환불',
  CHARGED = '코인 충전',
  EXPIRED = '만료',
}

export enum CoinCheckoutTypeEnum {
  PAYMENT = 'PAYMENT',
  MILEAGE = 'MILEAGE',
}

export interface CoinOrderAggregate {
  id: number;
  thumbnail: string;
  title: string;
  extensionNumber: string;
  subCategoryId: number;
  subCategoryName: string;
  counselDurationSeconds: number;
}

const getCoinsHistories = async ({ page = 1, perPage = 10 }: PageParams) => {
  const { data } = await api.get<GetCoinsHistoriesResponse>('/api/msa/order-app/counsel/coin/v1/coins/histories', {
    params: {
      page,
      perPage,
    },
  });

  return data;
};

export enum CounselAreaLabel {
  LOVE = '애정∙궁합',
  SEPARATION = '재회∙이별',
  BUSINESS = '사업∙재물',
  EMPLOYMENT = '취업∙시험',
  OCCUPATION = '진로∙직업',
  FAMILY = '가족',
  HEALTH = '건강',
  LIFE_FORTUNE = '인생총운',
}

export type CounselAreaType = keyof typeof CounselAreaLabel;

export interface PostCounselOrderDetailReviewPayload {
  counselReviewAreaType: CounselAreaType;
  comment: string;
}

const postCounselOrderDetailReview = async (counselOrderId: number, payload: PostCounselOrderDetailReviewPayload) => {
  const { data } = await api.post(`/api/msa/order-app/counsel/order/v1/orders/${counselOrderId}/reviews`, payload);

  return data;
};

interface GetCounselorStatusResponse {
  isAvailableCounselStatus: boolean;
}

const getCounselorStatus = async () => {
  const { data } = await api.get<GetCounselorStatusResponse>('/api/msa/gig-app/counsel/v1/counselor/status');

  return data;
};

export interface PutCounselorStatus {
  isAvailableCounselStatus: boolean;
}

export enum PutCounselorStatusErrorCode {
  CANNOT_UPDATE_COUNSELOR_STATUS_ERROR = 'CANNOT_UPDATE_COUNSELOR_STATUS_ERROR',
}

interface ErrorMessage {
  title: string;
  html: string;
}

export const COUNSEL_STATUS_ERRORS: Record<PutCounselorStatusErrorCode, ErrorMessage> = {
  CANNOT_UPDATE_COUNSELOR_STATUS_ERROR: {
    title: '상담중에는 부재중으로<br />변경할 수 없어요',
    html: '',
  },
};

const putCounselorStatus = async (payload: PutCounselorStatus) => {
  const { data } = await api.put<PutCounselorStatus>('/api/msa/gig-app/counsel/v1/counselor/status', payload);

  return data;
};

export const phoneCounselingMyKmongApi = {
  postCounselCoinRefundCheckouts,
  getBuyerCounselHistories,
  getSellerCounselHistories,
  getCounselProfitAmount,
  getCoinsHistories,
  postCounselOrderDetailReview,
  getCounselorStatus,
  putCounselorStatus,
};
