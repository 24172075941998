declare global {
  interface Window {
    Kakao: {
      isInitialized: () => boolean;
      init: (kakaoKey: string) => void;
      Share: {
        createCustomButton: ({
          container,
          templateId,
          templateArgs,
          installTalk,
        }: CreateCustomButtonProps) => void;
        sendCustom: ({
          templateId,
          templateArgs,
          installTalk,
        }: SendCustomProps) => void;
      };
    };
  }
}

export interface CreateCustomButtonProps {
  container: string;
  templateId: number;
  templateArgs: {
    url: string;
  };
  installTalk?: boolean;
}

export interface SendCustomProps {
  templateId: number;
  templateArgs: {
    url: string;
  };
  installTalk?: boolean;
}

const internalKakao = () => {
  if (typeof window === 'undefined') {
    return null;
  }

  if (!window.Kakao) {
    return null;
  }

  return window.Kakao;
};

const isInitialized = () => internalKakao()?.isInitialized();

const init = () => {
  if (!isInitialized()) {
    const KAKAO_KEY = process.env.NEXT_PUBLIC_KAKAO_KEY ?? '';
    internalKakao()?.init(KAKAO_KEY);
  }
};

/**
 * @see https://developers.kakao.com/docs/latest/ko/message/js-link#custom-template-msg-default
 */

const createCustomButton = ({
  container,
  templateId,
  templateArgs,
  installTalk = true,
}: CreateCustomButtonProps) => {
  init();
  internalKakao()?.Share.createCustomButton({
    container,
    templateId,
    templateArgs,
    installTalk,
  });
};

/**
 * @see https://developers.kakao.com/docs/latest/ko/message/js-link#custom-template-msg-custom
 */

const sendCustom = ({
  templateId,
  templateArgs,
  installTalk = true,
}: SendCustomProps) => {
  init();
  internalKakao()?.Share.sendCustom({
    templateId,
    templateArgs,
    installTalk,
  });
};

export default {
  createCustomButton,
  sendCustom,
};
