import api from '../api';

interface GetAmountResponse {
  amount: number;
}

const getSellerFundsRemainingAmount = async (): Promise<number> => {
  const { data } = await api.get<GetAmountResponse>('/api/msa/order-app/payment/v1/payments/seller/funds/biz-money/amount/remaining');

  return data?.amount ?? 0;
};

const getSellerFundsCashAmount = async (): Promise<number> => {
  const { data } = await api.get<GetAmountResponse>('/api/msa/order-app/payment/v1/payments/seller/funds/cash/amount/remaining');

  return data?.amount ?? 0;
};

const getSellerFundsMileageAmount = async (): Promise<number> => {
  const { data } = await api.get<GetAmountResponse>('/api/msa/order-app/payment/v1/payments/seller/funds/mileage/amount/remaining');

  return data?.amount ?? 0;
};

const getExpectedToBeExpiredAmount = async (): Promise<number> => {
  const { data } = await api.get<GetAmountResponse>('/api/msa/order-app/payment/v1/payments/seller/funds/biz-money/amount/expected-to-be-expired');

  return data?.amount ?? 0;
};

const getExpectedToBeAccumulatedAmount = async (): Promise<number> => {
  const { data } = await api.get<GetAmountResponse>('/api/msa/order-app/payment/v1/payments/seller/funds/mileage/amount/expected-to-be-accumulated');

  return data?.amount ?? 0;
};

export interface GetBizMoneyHistoriesParams {
  yearMonth: string;
}

export interface BizMoneyHistory {
  date: string;
  chargedAmount: number;
  accumulatedAmount: number;
  deductedAmount: number;
  refundedAmount: number;
  expiredAmount: number;
  revertedAmount: number;
  repaymentAmount: number;
}

interface GetBizMoneyHistoriesResponse {
  histories: BizMoneyHistory[];
}

const getBizMoneyHistories = async (params: GetBizMoneyHistoriesParams): Promise<GetBizMoneyHistoriesResponse> => {
  const { data } = await api.get<GetBizMoneyHistoriesResponse>('/api/msa/order-app/payment/v1/payments/seller/funds/biz-money/histories', { params });

  return data;
};

const refundBizMoney = async () => {
  await api.delete('/payments/seller/biz-money');
};

export enum PaymentStatusType {
  SUCCEED = 'SUCCEED',
  REFUNDED = 'REFUNDED',
  CANCELED = 'CANCELED',
  WAITING_PAY = 'WAITING_PAY',
  EXPIRED_PAY_DATE = 'EXPIRED_PAY_DATE',
}

export enum PaymentType {
  CARD = 'CARD',
  BANK = 'BANK',
  VBANK = 'VBANK',
  CELLPHONE = 'CELLPHONE',
  BIZMONEY = 'BIZMONEY',
}

export const paymentTypeLabel: Record<PaymentType, string> = {
  [PaymentType.CARD]: '신용카드',
  [PaymentType.BANK]: '계좌이체',
  [PaymentType.VBANK]: '가상계좌',
  [PaymentType.CELLPHONE]: '휴대폰',
  [PaymentType.BIZMONEY]: '비즈머니',
};

export interface BankAccount {
  depositor: string;
  accountNumber: string;
  bankName: string;
  expirationDateTime: string;
}

interface SellerOrder {
  sellerOrderId: string | null;
}

export enum ProviderReasonType {
  KMONG_FREE = 'KMONG_FREE',
  ADVERTISEMENT = 'ADVERTISEMENT',
  BIZ_MONEY = 'BIZ_MONEY',
}

export interface GetPaymentInformationResponse {
  paymentId: number;
  sellerUserId: number;
  paymentStatusType: PaymentStatusType;
  paymentType: PaymentType;
  totalAmount: number;
  paymentAmount: number;
  bizMoneyAmount: number;
  bankAccount: BankAccount | null;
  providerReasonType: ProviderReasonType;
  sellerOrder: SellerOrder | null;
}

const getPaymentInformation = async (paymentId: number): Promise<GetPaymentInformationResponse | null> => {
  try {
    const { data } = await api.get<GetPaymentInformationResponse>(`/api/msa/order-app/payment/v1/payments/seller/payment-confirmed/${paymentId}`);

    return data;
  } catch {
    return null;
  }
};

export interface GetSellerFundsCashHistoriesParams {
  page: number;
  yearMonth: string;
  perPage?: number;
}

export enum PaymentMethodType {
  CARD = 'CARD',
  BANK = 'BANK',
  VBANK = 'VBANK',
  CELLPHONE = 'CELLPHONE',
}

export const paymentMethodTypeLabel: Record<PaymentMethodType, string> = {
  [PaymentMethodType.CARD]: '신용카드',
  [PaymentMethodType.BANK]: '계좌이체',
  [PaymentMethodType.VBANK]: '가상계좌',
  [PaymentMethodType.CELLPHONE]: '휴대폰',
};

export enum SellerFundsPaymentPayStatusType {
  CHARGED = 'CHARGED',
  REFUNDED = 'REFUNDED',
  WAITING_PAY = 'WAITING_PAY',
}

interface SellerFundsPayment {
  paymentId: number;
  amount: number;
  payMethodType: PaymentMethodType;
  payStatusType: SellerFundsPaymentPayStatusType;
  paidDateTime: string;
  createdDateTime: string;
  expiredDateTime: string | null;
}

interface SellerFundsReceipt {
  marketId: string;
  pgOrderId: string;
  tradeId: string;
  hashdata: string;
  payMethodCode: string;
  cashSeqNo: string | null;
  cashReceiptNumber: string | null;
}

interface SellerFundsOrder {
  sellerOrderId: number;
  advertisementTitle: string;
}

export interface SellerFundsCashBankAccount {
  bankName: string;
  accountNumber: string;
  payer: string;
}

export interface SellerFundsCashHistory {
  providerReasonType: ProviderReasonType;
  payment: SellerFundsPayment;
  receipt: SellerFundsReceipt;
  sellerOrder: SellerFundsOrder | null;
  bankAccount: SellerFundsCashBankAccount | null;
}

interface GetSellerFundsCashHistoriesResponse {
  totalItemCount: number;
  lastPage: number;
  currentPage: number;
  perPage: number;
  items: SellerFundsCashHistory[];
}

const getSellerFundsCashHistories = async ({ yearMonth, page, perPage = 10 }: GetSellerFundsCashHistoriesParams): Promise<GetSellerFundsCashHistoriesResponse> => {
  const { data } = await api.get<GetSellerFundsCashHistoriesResponse>('/api/msa/order-app/payment/v1/payments/seller/funds/cash/histories', { params: { yearMonth, page, perPage } });

  return data;
};

export const bizMoneyApis = {
  getSellerFundsRemainingAmount,
  getSellerFundsCashAmount,
  getSellerFundsMileageAmount,
  getExpectedToBeExpiredAmount,
  getExpectedToBeAccumulatedAmount,
  getBizMoneyHistories,
  refundBizMoney,
  getPaymentInformation,
  getSellerFundsCashHistories,
};
