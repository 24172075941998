import airbridge from 'airbridge-web-sdk-loader';
import type { EventOption } from 'airbridge-web-sdk-loader/build/airbridge_api';

const {
  setUserID,
  setUserEmail,
  setDeeplinks,
} = airbridge;

type StandardEvent =
  'airbridge.user.signup' |
  'airbridge.ecommerce.home.viewed' |
  'airbridge.ecommerce.productList.viewed' |
  'airbridge.ecommerce.searchResults.viewed' |
  'airbridge.ecommerce.product.viewed' |
  'airbridge.ecommerce.order.completed';

type CustomEvent =
  'view_article' |
  'click_article_link' |
  'view_customproject_main' |
  'completed_customproject_request' |
  'received_phone_counsel_coin' |
  'view_phone_counsel_list' |
  'view_phone_counsel_product_detail';

const events = (event: StandardEvent | CustomEvent, options?: EventOption) => airbridge.events.send(event, options);

let isInitialized = false;
const init = (config: { app: string; webToken: string; utmParsing: boolean }) => {
  if (!isInitialized) {
    airbridge.init({
      ...config,
      cookieWindow:
      DEFAULT_COOKIE_WINDOW,
      utmParameterValueReplaceMap,
      urlQueryMapping: {
        ad_group: 'ad_group',
        ad_creative: 'ad_creative_id',
        sub_id_1: 'sub_id_1',
        sub_id_2: 'sub_id_2',
      },
    });
    isInitialized = true;
  }
};

const utmParameterValueReplaceMap = {
  utm_source: {
    facebook: 'facebook.business',
    gfa: 'naver.performance_da',
    m_naver: 'naver.searchad',
    naver: 'naver.searchad',
    naver_sa_co_mo: 'naver.searchad',
    naver_sa_co_pc: 'naver.searchad',
    naver_sa_po_mo: 'naver.searchad',
    naver_sa_pd_pc: 'naver.searchad',
    pc_naver: 'naver.searchad',
    tictok: 'tiktok',
    google: 'google_pc',
    fb: 'facebook.business',
    daangn: 'danggeun_market',
    kakaoda: 'kakao_da',
  },
};

const DEFAULT_COOKIE_WINDOW = 30;

export default {
  init,
  setUserID,
  setUserEmail,
  setDeeplinks,
  events,
};
