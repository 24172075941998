import api from '../api';

export interface PrepaidProfitAccountInformation {
  contractId: number;
  orderId: number;
  prepaidProfitAmount: number;
  remainAmount: number;
  profitChangeType: ProfitChangeType;
  createdDateTime: string;
}

export interface PrepaidProfitChargedAccount extends PrepaidProfitAccountInformation {
  profitChangeType: 'CHARGED';
}

export interface PrepaidOrder {
  id: number;
  contractId: number;
  directOrderId: number;
  orderId: number;
  orderData: null;
  buyerUserId: number;
  buyerUserData: {
    userId: number;
    userName: string;
    image: string;
  };
  sellerUserId: number;
  sellerUserData: {
    userId: number;
    userName: string;
    image: string;
  };
  gigId: number;
  gigData: {
    gigLogId: number;
    gigId: number;
    title: string;
    image: string;
    rootCategoryId: number;
    subCategoryId: number;
    categoryId: number;
  };
  totalAmount: number;
  expectedProfitAmount: number;
  isRequestedTax: boolean;
  orderStartedDateTime: string;
  orderCompletedDateTime: string;
  createdDateTime: string;
}

export type PrepaidProfitContents = PrepaidProfitAccount | PrepaidProfitAccountChargeType;

export interface PrepaidProfitAccount {
  account: PrepaidProfitAccountInformation;
  prepaidOrder: PrepaidOrder;
}

export interface PrepaidProfitAccountChargeType {
  account: PrepaidProfitChargedAccount;
  prepaidOrder: null;
}

export interface GetPrepaidProfitAccountResponse {
  total: number;
  lastPage: number;
  page: number;
  pageSize: number;
  contents: PrepaidProfitContents[];
  pageOption: {
    previous: number;
    next: number;
    hasPrevious: boolean;
  };
}

export type ProfitChangeType = 'CHARGED' | 'DELETED' | 'DEDUCTED' | '';

interface GetPrepaidProfitAccountPayload {
  USERID: number;
  page: string;
  orderStartedDate: string;
  orderEndedDate: string;
  profitChangeType: ProfitChangeType;
}

const getPrepaidProfitAccount = async (payload: GetPrepaidProfitAccountPayload) => {
  // TODO 추후 jwt 완료되면 jwt 토큰 값 넣고 X-Kmong-Authorization 헤더 붙여야 함
  const { data } = await api.get<GetPrepaidProfitAccountResponse>(`/api/msa/order-app/order/v2/orders/prepaid-profit-accounts/user/${payload.USERID}`, {
    params: {
      ...payload,
      pageSize: 10,
    },
  });

  return data;
};

export interface GetPrepaidProfitContractsResponse {
  contractId: number;
  contractStartedAt: string;
  contractEndedAt: string;
  status: 'REQUEST' | 'WAITING' | 'ONGOING' | 'CLOSE' | 'EXHAUST';
  remainAmount: number;
  prepaidProfitAmount: number;
}

const getPrepaidProfitContracts = async (userId: number) => {
  // TODO 추후 jwt 완료되면 jwt 토큰 값 넣고 X-Kmong-Authorization 헤더 붙여야 함
  const { data } = await api.get<GetPrepaidProfitContractsResponse>(`/api/msa/order-app/order/v2/prepaid-profit-contracts/user/${userId}`);

  return data;
};

export interface GetPrepaidProfitContractsExistResponse {
  isContracted: boolean;
}

const getPrepaidProfitContractsExist = async (userId: number) => {
  // TODO 추후 jwt 완료되면 jwt 토큰 값 넣고 X-Kmong-Authorization 헤더 붙여야 함
  const { data } = await api.get<GetPrepaidProfitContractsExistResponse>(`/api/msa/order-app/order/v2/prepaid-profit-contracts/user/${userId}/exist`);

  return Boolean(data?.isContracted);
};

export const prepaidProfitApi = {
  getPrepaidProfitAccount,
  getPrepaidProfitContracts,
  getPrepaidProfitContractsExist,
};
