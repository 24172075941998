import { captureException } from '@sentry/nextjs';
import { getConvertedProperties, logTracker } from '../utils';

declare global {
  interface Window {
    braze: {
      changeUser: (userId: string) => void;
      getUser: () => {
        setEmail: (email: string | null) => void;
        setCustomUserAttribute: (attribute: string, value: unknown) => void;
      };
      unregisterPush: () => void;
      logCustomEvent: (eventName: string, properties?: { [key: string]: unknown }) => void;
      automaticallyShowInAppMessages: () => void;
      openSession: () => void;
    };
  }
}

const internalBraze = () => {
  if (typeof window === 'undefined') {
    return null;
  }

  if (!window.braze) {
    return null;
  }

  return window.braze;
};

const setUserId = (userId: string) => {
  internalBraze()?.changeUser(userId);
};

const setUserEmail = (email: string) => {
  if (internalBraze()) {
    internalBraze()?.getUser().setEmail(email);
  }
};

const unregisterPush = () => {
  internalBraze()?.unregisterPush();
};

const sendBrazeCustomEvent = (
  eventName: string,
  properties?: { [key: string]: unknown },
) => {
  try {
    const convertedProperties = getConvertedProperties(properties);
    internalBraze()?.logCustomEvent(eventName, convertedProperties);
    logTracker({
      eventName,
      vender: 'Braze',
      properties: JSON.stringify(convertedProperties),
    });
  } catch (e) {
    captureException(e);
  }
};

const setCustomAttributes = (attribute: string, value: unknown) => {
  try {
    internalBraze()?.getUser().setCustomUserAttribute(attribute, value);
  } catch (e) {
    captureException(e);
  }
};

const automaticallyShowInAppMessages = () => {
  try {
    internalBraze()?.automaticallyShowInAppMessages();
  } catch (e) {
    captureException(e);
  }
};

const openSession = () => {
  try {
    internalBraze()?.openSession();
  } catch (e) {
    captureException(e);
  }
};

export default {
  setUserId,
  setUserEmail,
  unregisterPush,
  sendBrazeCustomEvent,
  setCustomAttributes,
  automaticallyShowInAppMessages,
  openSession,
};
