import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  Button, breakpoints, colors, Carousel,
} from '@kmong/ui';
import type { CarouselProps } from '@kmong/ui';
import { CLOUDFRONT_URL } from '@kmong-service/utils';
import View from './View';

function HeroBanner() {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  return (
    <MainBannerSection ref={ref}>
      <MainBanner>
        <Title>
          크몽까지 내 능력
        </Title>
        <SubTitle>
          프리랜서 마켓 No.1, 크몽
        </SubTitle>
        <MainBannerActions>
          <View platform="desktop">
            <DesktopButton
              color="yellow"
              href="https://kmong.onelink.me/BwxQ?af_dp=kmong://kmong.com&af_web_dp=https://kmong.com/"
              size="xlarge"
              variant="contained"
            >
              크몽 서비스 이용하기
            </DesktopButton>
          </View>
        </MainBannerActions>
      </MainBanner>
      <View platform="desktop">
        <CarouselWrapper inView={inView} />
      </View>
      <View platform="mobile">
        <CarouselWrapper inView={inView} />
        <MobileActionWrapper>
          <Button
            fullWidth
            color="yellow"
            href="https://kmong.onelink.me/BwxQ?af_dp=kmong://kmong.com&af_web_dp=https://kmong.com/"
            size="xlarge"
            variant="contained"
          >
            서비스 둘러보기
          </Button>
        </MobileActionWrapper>
      </View>
    </MainBannerSection>
  );
}

const MobileActionWrapper = styled.div`
  padding: 0 20px;
  margin: 8px 0 20px 0;
`;

const HERO_HUMANS = [{
  id: 1,
  src: `${CLOUDFRONT_URL}/assets/kmong_is/ci-pjt-hero-p-01.webp`,
  srcSet: `${CLOUDFRONT_URL}/assets/kmong_is/ci-pjt-hero-p-01@2x.webp 2x, ${CLOUDFRONT_URL}/assets/kmong_is/ci-pjt-hero-p-01@3x.webp 3x`,
  ballon: {
    title: '나는 마케팅 전문가입니다',
    company: '호랑이마케팅',
    position: 'left',
    textAlign: 'right',
  },
}, {
  id: 2,
  src: `${CLOUDFRONT_URL}/assets/kmong_is/ci-pjt-hero-p-02.webp`,
  srcSet: `${CLOUDFRONT_URL}/assets/kmong_is/ci-pjt-hero-p-02@2x.webp 2x, ${CLOUDFRONT_URL}/assets/kmong_is/ci-pjt-hero-p-02@3x.webp 3x`,
  ballon: {
    title: '나는 디자인 전문가입니다',
    company: '그리미아트',
    position: 'right',
    textAlign: 'left',
  },
}, {
  id: 3,
  src: `${CLOUDFRONT_URL}/assets/kmong_is/ci-pjt-hero-p-03_v2.png`,
  srcSet: `${CLOUDFRONT_URL}/assets/kmong_is/ci-pjt-hero-p-03@2x_v2.png 2x, ${CLOUDFRONT_URL}/assets/kmong_is/ci-pjt-hero-p-03@3x_v2.png 3x`,
  ballon: {
    title: '나는 영상·사진·음향 전문가입니다',
    company: '그랩스튜디오',
    position: 'left',
    textAlign: 'right',
  },
}, {
  id: 4,
  src: `${CLOUDFRONT_URL}/assets/kmong_is/ci-pjt-hero-p-04.webp`,
  srcSet: `${CLOUDFRONT_URL}/assets/kmong_is/ci-pjt-hero-p-04@2x.webp 2x, ${CLOUDFRONT_URL}/assets/kmong_is/ci-pjt-hero-p-04@3x.webp 3x`,
  ballon: {
    title: '우리는 세무·법무·노무 전문가입니다',
    company: '라움세무회계',
    position: 'right',
    textAlign: 'left',
  },
}, {
  id: 5,
  src: `${CLOUDFRONT_URL}/assets/kmong_is/ci-pjt-hero-p-05_v4.png`,
  srcSet: `${CLOUDFRONT_URL}/assets/kmong_is/ci-pjt-hero-p-05@2x_v4.png 2x, ${CLOUDFRONT_URL}/assets/kmong_is/ci-pjt-hero-p-05@3x_v4.png 3x`,
  ballon: {
    title: '나는 IT·프로그래밍 전문가입니다',
    company: '똑똑한개발자',
    position: 'left',
    textAlign: 'right',
  },
}] as const;

const SLICK_OPTIONS = {
  infinite: true,
  arrows: false,
  dots: true,
  autoplay: true,
  autoplaySpeed: 2000,
  variableWidth: true,

  appendDots: (dots) => <ul css={dotsStyle}>{dots}</ul>,
} as CarouselProps;

const dotsStyle = css`
  position: relative;
  top: -30px;

  & > li {
    border-radius: 50%;
    background-color: ${colors.gray[300]};
    width: 8px;
    height: 8px;
    margin: 2px;
  }

  & .slick-active {
    background-color: ${colors.yellow[400]};
  }

  & > li > button {
    opacity: 0;
  }

  @media screen and (max-width: ${breakpoints.large}px){
    flex-direction: column;
    top: -10px;
  }
`;

function CarouselWrapper({ inView }: { inView: boolean }) {
  const [activeSlide, setActiveSlide] = useState<number>();

  useEffect(() => {
    setTimeout(() => {
      setActiveSlide(0);
    }, 100);
  }, []);

  return (
    <CarouselWrapperBlock>
      <Carousel
        {...SLICK_OPTIONS}
        beforeChange={(_, nextSlide) => setActiveSlide(nextSlide)}
      >
        {HERO_HUMANS.map(({
          id, src, srcSet, ballon,
        }, index) => (
          <CarouselItem key={id}>
            <HumanImage
              alt={`히어로 전문가 이미지 ${id}`}
              inView={activeSlide === index}
              src={src}
              srcSet={srcSet}
            />
            <BallonBlock
              inView={activeSlide === index}
              position={ballon.position}
            >
              <BallonTitle>{ballon.title}</BallonTitle>
              <BallonCompany align={ballon.textAlign}>{ballon.company}</BallonCompany>
            </BallonBlock>
          </CarouselItem>
        ))}
      </Carousel>
      <BackgroundImage
        alt="히어로 백그라운드 이미지"
        inView={inView}
        src={`${CLOUDFRONT_URL}/assets/kmong_is/ci-pjt-hero-arrow@2x.webp`}
      />
    </CarouselWrapperBlock>
  );
}

interface BallonBlockProps {
  position: string;
  inView: boolean;
}

const BallonBlock = styled.div<BallonBlockProps>`
  position: absolute;
  bottom: 100px;
  border-radius: 18px;
  padding: 16px;

  ${({ position }) => (position === 'right' ? css`
    left: 436px;
    border-top-left-radius: 0;

    @media screen and (max-width: ${breakpoints.large}px){
      left: 200px;
      bottom: 30px;
    }
  ` : css`
    left: 134px;
    border-top-right-radius: 0;

    @media screen and (max-width: ${breakpoints.large}px){
      left: 70px;
      bottom: 30px;
    }
  `)};

  font-size: 13px;

  @media screen and (max-width: ${breakpoints.large}px){
    padding: 12px 8px;
  }

  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.95);

  transform: translateY(40px);
  transition: opacity 0.9s cubic-bezier(0, 0.21, 0.03, 1.01) 0s, transform 0.9s cubic-bezier(0, 0.21, 0.03, 1.01) 0s;
  opacity: 0;

  ${(props) => props.inView && css`
    transform: translateY(0);
    opacity: 1;
  `}
`;

const BallonTitle = styled.div`
  font-size: 13px;

  @media screen and (max-width: ${breakpoints.large}px){
    font-size: 10px;
  }
`;

const BallonCompany = styled.div<{ align: string }>`
  text-align: ${({ align }) => align};
  margin-top: 4px;
  font-size: 18px;
  font-weight: 500;

  @media screen and (max-width: ${breakpoints.large}px){
    font-size: 13px;
  }
`;

const CarouselWrapperBlock = styled.div`
  position: relative;
  width: 690px;

  @media screen and (max-width: ${breakpoints.large}px){
    max-width: 375px;
    margin: 0 auto;
  }

  & .slick-slider {
    z-index: 2;
  }

`;

const CarouselItem = styled.div`
  position: relative;

  &:focus-visible {
    outline: none;
  }
`;

const MainBannerSection = styled.section`
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;

  @media screen and (max-width: ${breakpoints.large}px){
    flex-direction: column;
  }
`;

const MainBanner = styled.div`
  width: 100%;
  max-width: 1280px;
  padding: 0 16px;

  @media screen and (max-width: ${breakpoints.large}px){
    margin-top: 40px;
    padding: 0 32px;
  }
`;

const Title = styled.h1`
  font-size: 55px;
  font-weight: 500;
  margin: 0;
  word-break: keep-all;
  line-height: 64px;
  color: ${colors.gray[800]};

  @media screen and (max-width: ${breakpoints.large}px){
    font-size: 38px;
    line-height: normal;
  }
`;

const SubTitle = styled.h2`
  margin: 0;
  margin-top: 12px;
  font-size: 30px;
  color: ${colors.gray[800]};
  font-weight: 400;

  @media screen and (max-width: ${breakpoints.large}px){
    margin: 0;
    font-size: 21px;
    line-height: normal;
  }
`;

const MainBannerActions = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: row;

  > div {
    width: 100%;
  }

  @media screen and (max-width: ${breakpoints.large}px){
    position: absolute;
    left: 0;
    right: 0;
    bottom: 64px;
    width: 100%;
    padding: 16px;
  }
`;

const DesktopButton = styled(Button)`
  width: 238px;
`;

const BackgroundImage = styled.img<{ inView: boolean }>`
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 1;

  opacity: 0;
  transform: translateX(40px);
  transition: opacity 0.9s cubic-bezier(0, 0.21, 0.03, 1.01) 0s, transform 0.9s cubic-bezier(0, 0.21, 0.03, 1.01) 0s;

  ${(props) => props.inView && css`
    transform: translateX(0);
    opacity: 1;
  `}
`;

const HumanImage = styled.img<{ inView: boolean }>`
  opacity: 0;
  transition: opacity 0.2s cubic-bezier(0, 0.21, 0.03, 1.01) 0s, transform 0.9s cubic-bezier(0, 0.21, 0.03, 1.01) 0s;

  ${(props) => props.inView && css`
    opacity: 1;
  `}

  @media screen and (max-width: ${breakpoints.large}px){
    max-width: 375px;
  }
`;

export default HeroBanner;
