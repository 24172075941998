import type { IncomingMessage, ServerResponse } from 'http';

export interface AsyncHttpRequest {
  set?: (req: IncomingMessage) => void;
  get?: () => IncomingMessage | undefined;
  destroy?: () => void;
}

export interface AsyncHttpResponse {
  set?: (headers: ServerResponse) => void;
  get?: () => ServerResponse | undefined;
  destroy?: () => void;
}

export interface AsyncHttpContext {
  req: AsyncHttpRequest;
  res: AsyncHttpResponse;
}

export const asyncHttpContext: AsyncHttpContext = {
  req: {
    set: undefined,
    get: undefined,
    destroy: undefined,
  },
  res: {
    set: undefined,
    get: undefined,
    destroy: undefined,
  },
};

if (typeof window === 'undefined') {
  // eslint-disable-next-line func-names
  (async function () {
    const asyncHooks = await import('async_hooks');
    const store: {
      req: Map<number, IncomingMessage | undefined>;
      res: Map<number, ServerResponse | undefined>;
    } = {
      req: new Map(),
      res: new Map(),
    };

    const asyncReqHook = asyncHooks.createHook({
      init: (asyncId, _, triggerAsyncId) => {
        if (store.req.has(triggerAsyncId)) {
          store.req.set(asyncId, store.req.get(triggerAsyncId));
        }
      },
      destroy: (asyncId) => {
        if (store.req.has(asyncId)) {
          store.req.delete(asyncId);
        }
      },
    });

    const asyncResHook = asyncHooks.createHook({
      init: (asyncId, _, triggerAsyncId) => {
        if (store.res.has(triggerAsyncId)) {
          store.res.set(asyncId, store.res.get(triggerAsyncId));
        }
      },
      destroy: (asyncId) => {
        if (store.res.has(asyncId)) {
          store.res.delete(asyncId);
        }
      },
    });

    asyncReqHook.enable();
    asyncResHook.enable();

    asyncHttpContext.req.set = (headers: IncomingMessage) => {
      store.req.set(asyncHooks.executionAsyncId(), headers);
    };
    asyncHttpContext.res.set = (headers: ServerResponse) => {
      store.res.set(asyncHooks.executionAsyncId(), headers);
    };

    asyncHttpContext.req.get = () => store.req.get(asyncHooks.executionAsyncId());
    asyncHttpContext.res.get = () => store.res.get(asyncHooks.executionAsyncId());
  }());
}
