import type { InboxMessage } from '@kmong/rest-client';
import { CLOUDFRONT_URL } from '@kmong-service/utils';
import stringToDomTextContent from '../stringToDomTextContent';

const isProduction = process.env.NEXT_PUBLIC_MODE === 'production';
const baseUrl = isProduction ? 'https://www.kmong.com' : 'https://market.dev.kmong.run';

// 알림을 활성화 시킬 여부 flag
let isActiveNotification: boolean = true;

let channel: null | BroadcastChannel = null;

function browserNotification(params: InboxMessage) {
  if (channel === null) {
    initializeBroadcastChannel();
  }

  const inboxGroupId = Number(new URLSearchParams(window.location.search).get('inbox_group_id') ?? 0);
  const inboxLink = `${baseUrl}/inboxes?inbox_group_id=${params.inbox_group_id}&partner_id=${params.sender.USERID}`;

  const isVisible = document.visibilityState === 'visible';
  const isCurrentChat = getIsCurrentChat(inboxGroupId, Number(params.inbox_group_id)) && isVisible;
  // 알림이 허용된 상태이고, 내가 보낸 메시지의 메시지가 아니고, 현재 보고 있는 채팅방이 아닌 경우에만 알림을 띄운다.
  const isShowNotification = Notification.permission === 'granted' && !params.is_mine;

  // 현재 보고 있는 채팅방일 경우 알림 비활성화
  if (isCurrentChat && channel !== null) {
    channel.postMessage({ nonActiveNotification: true });
    isActiveNotification = false;
  }

  // onmessage가 수행되는 시점을 알 수 없어 setTimeout으로 처리 (1초안에 완료되었다고 가정)
  setTimeout(() => {
    // 알림 표시 조건이 충족되었을 때만 알림을 띄움
    if (isShowNotification && isActiveNotification) {
      activeNotification(params, inboxLink);
    }
    // flag 초기화
    isActiveNotification = true;
  }, 1000);
}

/**
 * params로 온 inboxGroupId를 비교하여 현재 보고 있는 채팅방인지 확인한다.
 * @param inboxGroupId
 * @param paramsInboxGroupId
 * @returns
 */
function getIsCurrentChat(inboxGroupId: number, paramsInboxGroupId: number): boolean {
  return inboxGroupId === paramsInboxGroupId;
}

/**
 * 알림을 띄운다.
 * @param params
 * @param inboxLink
 */
function activeNotification(params: InboxMessage, inboxLink: string) {
  const audio = new Audio(`${CLOUDFRONT_URL}/assets/sound/iphone-notification.ogg`);
  audio.play();

  const notification = new Notification('크몽 메시지 알림이 도착했어요', {
    body: stringToDomTextContent(params.message),
    tag: `${params.MID}`,
  });

  notification.onclick = () => {
    window.open(inboxLink, '_blank');
  };
}

/**
 * BroadcastChannel을 초기화한다.
 */
function initializeBroadcastChannel() {
  channel = new BroadcastChannel('notification-channel');
  channel.onmessage = (event) => {
    if (event.data.nonActiveNotification) {
      isActiveNotification = false;
    }
  };
}

export default browserNotification;
