import type { InboxGroup, InboxMessage } from '@kmong/rest-client';
import { getBaseURL } from '@kmong/rest-client/src/api/lib/utils';
import Pusher from 'pusher-js';
import browserNotification from './browserNotification';
import { PusherSocketEnum } from './types/pusherSocketType';
import type { Channel } from 'pusher-js';

type ConnectionContext = 'header' | 'inbox';

type BindCallback = ((params: InboxMessage) => void) | ((params: InboxGroup) => void) | ((params: ReadMessage) => void);

class KmongPusher {
  private pusher: Pusher | null;

  private privateUserChannel: Channel | null;

  private callbacks: {
    [contextKey in ConnectionContext]: {
      [PusherSocketEnum.inboxReceived]: ((params: InboxMessage) => void) | null;
      [PusherSocketEnum.track_receive]: (() => void) | null;
      [PusherSocketEnum.emitInboxGroupEvent]: ((params: InboxGroup) => void) | null;
      [PusherSocketEnum.inboxReadMessages]: ((params: ReadMessage) => void) | null;
    };
  };

  constructor() {
    this.pusher = null;
    this.privateUserChannel = null;
    this.initializePusher();
    this.callbacks = {
      header: {
        inboxReceived: null, track_receive: null, emitInboxGroupEvent: null, inboxReadMessages: null,
      },
      inbox: {
        inboxReceived: null, track_receive: null, emitInboxGroupEvent: null, inboxReadMessages: null,
      },
    };
  }

  initializePusher = () => {
    if (this.pusher) {
      return;
    }

    this.pusher = new Pusher(process.env.NEXT_PUBLIC_PUSHER ?? '', {
      cluster: 'ap1',
      authEndpoint: `${getBaseURL()}/api/v4/pusher_auth`,
      forceTLS: true,
    });
  };

  subscribeChannel = (channelName: string) => {
    if (this.privateUserChannel) {
      return;
    }

    this.privateUserChannel = this.pusher?.subscribe(channelName) ?? null;
  };

  clearPusher = () => {
    this.pusher = null;
  };

  bindInboxReceived = (cb: (params: InboxMessage) => void, context: ConnectionContext) => {
    this.inboxReceivedUnbind();
    this.callbacks[context].inboxReceived = cb;
    this.bindEvent(PusherSocketEnum.inboxReceived, this.inboxReceivedCallbacks);
  };

  bindTrackReceived = (cb: () => void, context: ConnectionContext) => {
    this.trackReceivedUnbind();
    this.callbacks[context].track_receive = cb;
    this.bindEvent(PusherSocketEnum.track_receive, this.trackReceivedCallbacks);
  };

  bindInboxGroupReceived = (cb: (params: InboxGroup) => void, context: ConnectionContext) => {
    this.emitInboxGroupEventUnbind();
    this.callbacks[context].emitInboxGroupEvent = cb;
    this.bindEvent(PusherSocketEnum.emitInboxGroupEvent, this.emitInboxGroupEventCallbacks);
  };

  bindInboxReadMessages = (cb: (params: ReadMessage) => void, context: ConnectionContext) => {
    this.inboxReadMessagesUnbind();
    this.callbacks[context].inboxReadMessages = cb;
    this.bindEvent(PusherSocketEnum.inboxReadMessages, this.inboxReadMessagesCallbacks);
  };

  private bindEvent = (event: PusherSocketEnum, cb: BindCallback) => this.privateUserChannel?.bind(event, cb);

  private inboxReceivedCallbacks = (params: InboxMessage) => {
    this.callbacks.header[PusherSocketEnum.inboxReceived]?.(params);
    this.callbacks.inbox[PusherSocketEnum.inboxReceived]?.(params);

    const isSafari = /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent);

    if (!isSafari) {
      browserNotification(params);
    }
  };

  private trackReceivedCallbacks = () => {
    this.callbacks.header[PusherSocketEnum.track_receive]?.();
    this.callbacks.inbox[PusherSocketEnum.track_receive]?.();
  };

  private emitInboxGroupEventCallbacks = (params: InboxGroup) => {
    this.callbacks.header[PusherSocketEnum.emitInboxGroupEvent]?.(params);
    this.callbacks.inbox[PusherSocketEnum.emitInboxGroupEvent]?.(params);
  };

  private inboxReadMessagesCallbacks = (params: ReadMessage) => {
    this.callbacks.header[PusherSocketEnum.inboxReadMessages]?.(params);
    this.callbacks.inbox[PusherSocketEnum.inboxReadMessages]?.(params);
  };

  inboxReceivedUnbind = () => {
    this.privateUserChannel?.unbind(PusherSocketEnum.inboxReceived);
  };

  trackReceivedUnbind = () => {
    this.privateUserChannel?.unbind(PusherSocketEnum.track_receive);
  };

  emitInboxGroupEventUnbind = () => {
    this.privateUserChannel?.unbind(PusherSocketEnum.emitInboxGroupEvent);
  };

  inboxReadMessagesUnbind = () => {
    this.privateUserChannel?.unbind(PusherSocketEnum.inboxReadMessages);
  };

  clearPrivateUserChannel = () => {
    this.privateUserChannel = null;
  };
}

// inbox group id에 read 된 메시지 ids 가 오지만 아묻따 해당 group id 의 모든 메시지를 읽은 것으로 처리해야함
export interface ReadMessage {
  inbox_group_id: number;
  message_ids: number[];
}

export default new KmongPusher();
