import { CLOUDFRONT_URL } from '@kmong-service/utils';
import api from '../api';

export interface AvailableValue {
  id: number;
  value: string;
  isSelected: boolean;
}

interface FileType {
  FID: number;
  fname: string;
  file_size: string;
  question_id: number | null;
  preview_url: string | null;
}

export enum ApplyFormInputType {
  TEXT = 'TEXT',
  RADIO = 'RADIO',
  MULTI_SELECT = 'MULTI_SELECT',
  FILE = 'FILE',
}

export interface LifeStyleTypesQuestion {
  id: number;
  inputType: ApplyFormInputType;
  dataType: string;
  title: string;
  inquiryTitle: string;
  placeholder: string;
  isMandatory: boolean;
  value: string;
  hasError: boolean;
  availableValues?: AvailableValue[];
  image?: string;
  content?: string;
  files?: FileType[];
}

export interface PostLifeStyleTypesInquiresData {
  questions: LifeStyleTypesQuestion[];
}

interface OpenGraph {
  alt: string;
  image: string;
  title: string;
  description: string;
}

interface PersonalInformationTerms {
  title: string;
  content: string;
}

export interface GetLifeStyleTypesInquiresData {
  inquiryType: string;
  og: OpenGraph;
  bannerImage: string;
  title: string;
  description: string;
  questions: LifeStyleTypesQuestion[];
  personalInformationTerms: PersonalInformationTerms[];
  isFormDisabled?: boolean;
  completeNotices?: string[];
}

export const getApplyFormQuestions = async (type: string): Promise<GetLifeStyleTypesInquiresData> => {
  const data = await fetch(`${CLOUDFRONT_URL}/assets/static-json/apply-form/${type}.json`);

  return data.json();
};

export const postAirConditionerInquires = async (inquiryType: string, data: PostLifeStyleTypesInquiresData) => {
  await api.post(`/api/event/v1/life-style-types/${inquiryType}/inquiries`, data);
};
