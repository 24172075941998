import { CLOUDFRONT_URL } from '@kmong-service/utils';

import api from '.';
import type { GradeType } from '.';

export interface BestSEO {
  title: string;
  description: string;
}

export type BestSearchSort =
  '_score' |
  'ratings' |
  'recommendation_point' |
  'ranking_points' |
  'created_at';

export interface FAQ {
  question: string;
  answer: string;
}

export interface Best {
  id: number;
  keyword: string;
  rootCategoryId: number;
  heroTitle: string;
  SEO: BestSEO;
  searchKeyword: string;
  searchSort: BestSearchSort;
  averagePrice: number;
  orderIds: number[];
  FAQList: FAQ[];
}

export interface Guide {
  imageURL: string;
  title: string;
  description: string;
}

export interface RootCategory {
  id: number;
  name: string;
  imageURL: string;
  mainColor: string;
}

interface GetBestGigDetailResponse {
  bestList: Best[];
  guideList: Guide[];
  commonFAQList: FAQ[];
  rootCategoryList: RootCategory[];
}

const getBestGigDetail = async (): Promise<GetBestGigDetailResponse> => {
  const data = await fetch(`${CLOUDFRONT_URL}/assets/best-gig/best-gig-detail-data.json`);

  return data.json();
};

export interface BestReview {
  reviewId: number;
  buyerUserNickname: string;
  buyerUserThumbnail: string;
  gigId: number;
  comment: string;
  score: number;
  status: 'ACTIVE' | 'DELETED' | 'BLINDED';
  createdDateTime: string;
}

interface Seller {
  nickname: string;
  grade: GradeType;
  thumbnail: string;
  isOnline: boolean;
  isAvailableTax: boolean;
}

interface GigCardReview {
  reviewAverage: number;
  reviewCount: number;
}

export interface BestGigCard {
  gigId: number;
  title: string;
  images: string[];
  price: number;
  seller: Seller;
  review: GigCardReview;
  hasVideos: boolean;
  hasPackages: boolean;
  isPrime: boolean;
}

export interface BestReviewData {
  review: BestReview;
  gigCard: BestGigCard;
}

interface GetBestGigDetailReviewListResponse {
  reviews: BestReviewData[];
}

const getBestGigDetailReviewList = async (orderIds: string) => {
  const { data } = await api.get<GetBestGigDetailReviewListResponse>(`/api/msa/order-app/order/v1/orders/SEO/target/reviews?orderIds=${orderIds}`);
  const reviewList = data?.reviews.slice(0, 4) ?? [];
  const rowCount = Math.floor(reviewList.length / 2);

  return rowCount ? reviewList.slice(0, rowCount * 2) : [];
};

export const bestGigApi = {
  getBestGigDetail,
  getBestGigDetailReviewList,
};
