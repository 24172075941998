import { CLOUDFRONT_URL } from '@kmong-service/utils';

const SEOTitle = '세상 모든 노하우를 배울 수 있는 곳, 크몽 전자책·템플릿';
const SEODescription = '월급 외 수익 100만원 만들기, 무자본 창업하기, 자동화 수익 만들기, 전자책과 동영상 중 원하는 방식으로 세상 모든 노하우를 배워보세요.';

export const knowhowSEO = {
  title: SEOTitle,
  description: SEODescription,
  openGraph: {
    title: SEOTitle,
    description: SEODescription,
    image: {
      url: `${CLOUDFRONT_URL}/assets/knowhow/og_image.jpg`,
      width: 1200,
      height: 630,
      alt: SEOTitle,
    },
  },
};

export const knowhowBreadCrumbJsonLd = {
  itemListElements: [
    {
      item: 'https://kmong.com',
      name: '홈',
      position: 1,
    },
    {
      name: '전자책∙템플릿',
      position: 2,
    },
  ],
};
