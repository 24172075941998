import api from './api';

const readNotification = async (id: number) => {
  await api.get(`/api/v5/user/notifications/${id}/read`);
};

export type NotificationType = 'KMONG' | 'ORDER' | 'ALL';

const readAllNotification = (notificationType: NotificationType = 'ALL') => api.put(
  '/api/v5/user/notifications/read/all',
  {},
  {
    params: {
      notification_type: notificationType,
    },
  },
);

export interface Message {
  MID: number;
  MSGFROM: number;
  MSGTO: number;
  files: unknown;
  inbox_group_id: number;
  is_custom_project: boolean;
  is_exist_ongoing_orders: boolean;
  link: string;
  message: string;
  time: string;
  unread: 0 | 1;
  user: {
    USERID: number;
    profilepicture: string;
    username: string;
  };
}

export interface MessagesDateGroup {
  date: string;
  messages: Message[];
}

export interface GetUserMessagesResponse {
  dates: MessagesDateGroup[];
  last_page: number;
  next_page_link?: string | null;
  previous_page_link: string | null;
  total: number;
}

const getUserMessages = (page: number) => api.get<GetUserMessagesResponse>('/api/v5/user/messages', {
  params: {
    page,
  },
});

export interface UserNotificationDateTheme {
  theme: 'DATE';
  message: string;
}

export interface UserNotificationDefaultTheme {
  theme: 'NOTIFICATION';
  gig: {
    category_id: number;
  };
  category_id: number;
  group_id: number;
  id: number;
  image: string;
  is_unread: boolean;
  is_warning: 0 | 1;
  link: string;
  provider: string;
  time: string;
  type: string;
  unread: 0 | 1;
  message: string;
  user: {
    USERID: number;
    thumbnail: string;
    username: string;
  };
}

export type UserNotification =
  | UserNotificationDefaultTheme
  | UserNotificationDateTheme;

export interface GetUserNotificationsParams {
  type: 'KMONG' | 'ORDER';
  page: number;
}

export interface GetUserNotificationsResponse {
  notifications: UserNotification[];
  last_page: number;
  next_page_link: string | null;
  previous_page_link: string | null;
  total: number;
}

const getUserNotifications = (params: GetUserNotificationsParams) => api.get<GetUserNotificationsResponse>('/api/v5/user/notifications', {
  params,
});

export interface GetUnreadAlarmsResponse {
  inbox_count: number;
  order_count: number;
  kmong_count: number;
  inboxes: number;
  order_notifications: number;
  kmong_notifications: number;
  notification_count: number;
}

export interface GetUnreadAlarmsFormattedResponse {
  data: {
    inbox_count: number;
    order_count: number;
    kmong_count: number;
  };
}

const getUnreadAlarms = async (): Promise<GetUnreadAlarmsFormattedResponse> => {
  const {
    data: {
      inbox_count,
      order_count,
      kmong_count,
    },
  } = await api.get<GetUnreadAlarmsResponse>('/api/user/v2/unread-alarms');

  return {
    data: {
      inbox_count,
      order_count,
      kmong_count,
    },
  };
};

export interface GetPenaltyResponse {
  totalPenaltyDay: number;
  expirationDateTime: string | null;
}

const getPenalty = async () => {
  const { data } = await api.get<GetPenaltyResponse>('/api/msa/user-app/user/v1/users/penalty');

  return data;
};

export interface GetInviteFriendsCodeResponse {
  inviteCode: string;
}

const getInviteFriendsCode = async () => {
  const { data } = await api.get<GetInviteFriendsCodeResponse>('/api/event/v1/invite-friends/code');

  return data;
};

export interface GetResponseTimeResponse {
  averageResponseTimesInMinutes: number;
  averageResponseRate: number;
  /**
   * @example ex) 2시간 이내 | 2시간 이상 | 아직 몰라요 등등
   */
  averageResponseTimesInMinutesKo: string;
}

const getResponseTime = async () => {
  const { data } = await api.get<GetResponseTimeResponse>('/api/msa/user-app/user/v1/users/response-time');

  return data;
};

export const userApi = {
  readNotification,
  readAllNotification,
  getUserMessages,
  getUserNotifications,
  getUnreadAlarms,
  getPenalty,
  getInviteFriendsCode,
  getResponseTime,
};
