import api from '../api';
import type { TempGig } from '../gig';
import type { GigDetailUser } from '../gig-detail';

export enum InquiryOptionType {
  Text = 'TEXT',
  Email = 'EMAIL',
  Dropdown = 'DROPDOWN',
  Textarea = 'TEXTAREA',
  Date = 'DATE',
  Price = 'PRICE',
  Radio = 'RADIO',
  Switch = 'SWITCH',
  File = 'FILE',
}

export interface InquiryOptionFormNameBase {
  id: number | null;
  data_type: string;
  input_type: InquiryOptionType;
}

export interface InquiryOptionBase extends InquiryOptionFormNameBase {
  title: string;
  description?: string;
  placeholder: string;
  is_mandatory: boolean;
  has_error: boolean;
}

export interface TextOption extends InquiryOptionBase {
  input_type: InquiryOptionType.Text;
  options?: {
    min: number;
    max: number;
  };
}

export interface EmailOption extends InquiryOptionBase {
  input_type: InquiryOptionType.Email;
  options?: {
    min: string;
    max: string;
  };
  value: string;
}

export interface DateOption extends InquiryOptionBase {
  input_type: InquiryOptionType.Date;
}

export interface PriceOption extends InquiryOptionBase {
  input_type: InquiryOptionType.Price;
  extra_data?: {
    is_possible_to_discuss: boolean;
  };
  options: {
    min: number;
    max: number;
    unit: number;
    unit_ko: string;
  };
}

export interface RadioOption extends InquiryOptionBase {
  input_type: InquiryOptionType.Radio;
  available_values: {
    value: string;
    is_selected: boolean;
  }[];
}

export interface DropdownOption extends InquiryOptionBase {
  input_type: InquiryOptionType.Dropdown;
  available_values: {
    value: string;
    is_selected: boolean;
  }[];
}

export interface TextareaOption extends InquiryOptionBase {
  input_type: InquiryOptionType.Textarea;
  options?: {
    min: number;
    max: number;
    show_file_input: boolean;
  };
  files: unknown;
  value: string;
}

export interface SwitchOption extends InquiryOptionBase {
  input_type: InquiryOptionType.Switch;
  value: boolean;
}

export interface FileOption extends InquiryOptionBase {
  input_type: InquiryOptionType.File;
  value: string;
}

export type OptionTypes =
  | TextOption
  | EmailOption
  | PriceOption
  | DateOption
  | DropdownOption
  | TextareaOption
  | RadioOption
  | SwitchOption
  | FileOption;

export interface GetEnterpriseInquiryResponse {
  inquiryQuestions: OptionTypes[];
  memberInfo: {
    fullname: string;
    mobile: string;
    email: string;
  };
}

export interface GetGigsInquiryResponse {
  user: GigDetailUser;
  gig_inquiries: OptionTypes[];
}

export interface SaveGigsInquiryResponse {
  inbox_group_id: number;
  MID: number;
  is_first_message: boolean;
}

export interface GetResidentProfileInquiryResponse {
  gig_inquiries: [OptionTypes];
}

export interface SaveGigsInquiryPayload {
  answers: {
    [key: number]: unknown;
  }[];
}
export interface SaveGigsInquiryResponse {
  inbox_group_id: number;
  MID: number;
  is_first_message: boolean;
}
const saveGigsInquiry = async (
  PID: number,
  payload: SaveGigsInquiryPayload,
) => {
  const { data } = await api.post<SaveGigsInquiryResponse | undefined>(
    `/api/v5/gigs/${PID}/inquiry-questions`,
    payload,
  );

  return data;
};

export interface MultisendInquiryAnswer {
  id: number;
  value: string;
}

export interface MultiSendInquiryPayload {
  baseGigId: number;
  targetGigIds: number[];
  answers: MultisendInquiryAnswer[];
}

export interface MultiSendInquiryResponse {
  gigId: number;
  isSent: boolean;
  isFirstMessage: boolean;
  inboxGroupId: number;
  inboxMessageId: number;
  errorCode: number | null;
}

const saveMultiSendInquiry = async (payload: MultiSendInquiryPayload) => {
  const { data } = await api.post<MultiSendInquiryResponse | undefined>(
    '/api/gig/v1/gig-inquiries',
    payload,
  );

  return data;
};

const getEnterpriseInquiry = async () => {
  const { data } = await api.get<GetEnterpriseInquiryResponse>('/api/v5/enterprise/inquiry');

  return data;
};

const getGigsInquiry = async (PID: number) => {
  const { data } = await api.get<GetGigsInquiryResponse>(`/api/v5/gigs/${PID}/inquiry-questions`);

  return data;
};

const getResidentProfileInquiry = async (PID: number) => {
  const { data } = await api.get<GetResidentProfileInquiryResponse>(`/api/v5/gigs/${PID}/resident-profile-inquiry-questions`);

  return data;
};

export interface ResidentProfileAnswerFile {
  FID: number;
  fname: string;
  preview_url: string;
}

export interface ResidentProfileAnswer {
  id: number;
  value: string;
  files?: ResidentProfileAnswerFile[];
}

export interface PostResidentProfileInquiryParams {
  answers: ResidentProfileAnswer[];
  extras?: {
    isGuestAgreedPrivacyTermsAndConditions?: boolean;
    userId?: number;
    deviceType?: PostResidentProfileInquiryDevice;
  };
}

export type PostResidentProfileInquiryDevice =
  | 'DESKTOP'
  | 'MOBILE_WEB'
  | 'ANDROID'
  | 'IOS';

const postResidentProfileInquiry = async (
  gigId: number,
  params: PostResidentProfileInquiryParams,
) => {
  const { data } = await api.post(
    `/api/msa/enterprise/v1/gigs/${gigId}/inquiry-answers`,
    params,
  );

  return data;
};

interface GetFloatingInquiryQuestionsResponse {
  gigInquiryQuestion: GigInquiryQuestion | null;
}

export interface GigInquiryQuestion {
  gigInquiryQuestionId: number | null;
  inputType: InquiryOptionType.Textarea;
  dataType: 'DESCRIPTION_FLOATING';
  title: string;
  placeholder: string;
  isMandatory: boolean;
  options?: GigInquiryOptions;
}

export interface GigInquiryOptions {
  min: number;
  max: number;
  showFileInput: boolean;
}

const getFloatingInquiryQuestions = async (PID: number) => {
  const { data } = await api.get<GetFloatingInquiryQuestionsResponse>(`/api/display/v1/floating-inquiry-questions/${PID}`);

  return data?.gigInquiryQuestion;
};

interface SimilarGigParams {
  gigId: number;
  takeCount: number;
}

export interface GetSimilarGigsResponse {
  gigs: TempGig[];
}

const getSimilarGigs = async ({ gigId, takeCount }: SimilarGigParams) => {
  const { data } = await api.get<GetSimilarGigsResponse>(`/api/msa/gig-app/gig/v1/gigs/${gigId}/inquiry/similar-gigs?takeCount=${takeCount}`);

  return data?.gigs ?? [];
};

export const inquiryApi = {
  getEnterpriseInquiry,
  getGigsInquiry,
  saveGigsInquiry,
  getResidentProfileInquiry,
  postResidentProfileInquiry,
  getFloatingInquiryQuestions,
  getSimilarGigs,
  saveMultiSendInquiry,
};
