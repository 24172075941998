import { CLOUDFRONT_URL } from '@kmong-service/utils';
import api from '../api';
import type { PackageType } from '../gig-detail';
import type { KmongAnalyticsGigProps, TempKmongAnalyticsGigProps } from '../kmong-analytics';

export type AdvertisementKoType =
  | ''
  | '루키'
  | '클릭업'
  | '플러스'
  | '플러스업'
  | '프리미엄';

export type AdvertisementType =
  | ''
  | 'CPC'
  | 'CPC_RECOMMEND'
  | 'CPC_SEARCH'
  | 'ROOKIE'
  | 'PLUS'
  | 'PLUSUP'
  | 'PREMIUM';

export type GradeType = 'NEW' | 'LEVEL1' | 'LEVEL2' | 'LEVEL3' | 'MASTER' | GradeLegacyType;
export type GradeNewType = 'NEW' | 'LEVEL1' | 'LEVEL2' | 'LEVEL3' | 'MASTER';
export type GradeLegacyType = 'Level 1' | 'Level 2' | 'Level 3';

interface User {
  USERID: number;
  username: string;
  grade: GradeType;
  thumbnail: string;
  online: boolean;
  is_company: boolean;
  is_fast_reaction: boolean;
  contactable?: {
    contactable_status?: 'NO_SETTING' | 'ACCESSIBLE';
    description?: null | '지금 상담 가능';
  };
}

interface Seller {
  userId: number;
  nickname: string;
  grade: GradeType;
  thumbnail: string;
  isOnline: boolean;
  isAvailableTax: boolean;
  isNowContactable: boolean;
  isFastReaction: boolean;
}

/**
 * @deprecated 레거시 라벨타입으로 추후 개선 필요(VODType, EbookType, TemplateType, VODLabel, EBOOKLabel, TemplateLabel, OldLabel)
 */
export type VODType = 'VOD' | 'ORIGINAL' | 'ONECLIP';

export type EbookType = 'EBOOK' | 'ORIGINAL';

export type TemplateType = 'TEMPLATE' | 'ORIGINAL';

export type LabelValueType = VODLabel | EBOOKLabel | TemplateLabel;
export interface VODLabel {
  title: string;
  type: VODType;
}

export interface EBOOKLabel {
  title: string;
  type: EbookType;
}

export interface TemplateLabel {
  title: string;
  type: TemplateType;
}

export interface Label {
  type: string;
  provider: string;
  title?: string;
  values?: LabelValueType[];
}

export type TempLabel = 'KMONG_AWARDS' | 'VOD' | 'ORIGINAL' | 'ONECLIP' | 'EBOOK' | 'TEMPLATE';

export interface TempGigCategory {
  rootCategoryId: number;
  rootCategoryName: string;
  subCategoryId: number | null;
  subCategoryName: string | null;
  thirdCategoryId: number | null;
  thirdCategoryName: string | null;
}

export interface GigReview {
  reviewCount: number;
  reviewAverage: number;
}

interface PortfolioImage {
  id: number;
  url: string;
}

export interface TempGig extends TempKmongAnalyticsGigProps {
  gigId: number;
  currentCategoryId: number;
  category: TempGigCategory;
  title: string;
  images: string[];
  price: number;
  seller: Seller;
  review: GigReview;
  advertisementType: AdvertisementType | null;
  isCurrentUserBookmark: boolean;
  hasVideos: boolean;
  hasPackages: boolean;
  isPrime: boolean;
  labels: TempLabel[];
  isAvailableCalling: boolean;
  packageName?: string;
  portfolioImages?: PortfolioImage[];
}

export interface Gig extends KmongAnalyticsGigProps {
  PID: number;
  category_id: number;
  cat1: number;
  cat1_name: string;
  cat2: number;
  cat2_name: string;
  cat3: number | null;
  cat3_name: string | null;
  card_type?: string;
  is_module_category: boolean;
  link: string;
  title: string;
  image: string;
  additional_images: string[];
  price: number | null;
  user: User;
  ratings_count: number;
  ratings_average: number;
  advertisement: AdvertisementType | null;
  is_bookmark: boolean;
  has_orders: boolean;
  has_video: boolean;
  has_packages: boolean;
  available_calling: boolean;
  is_available_calling: boolean;
  has_calling_package: boolean;
  has_portfolio: boolean;
  completed_order_count?: number;
  is_prime: boolean;
  labels: Label[];
}

export interface GradeConditionInfo {
  grade: GradeNewType;
  satisfactionRate: number;
  averageResponseRate: number;
  orderCompletionRate: number;
  orderComplianceRate: number;
  penaltyCount: number;
  accumulateSoldAmount: number;
  accumulateSoldCount: number;
  accumulateSoldMinAmount: number;
  activationPeriod: number;
}

export interface GetGradeConditionInfoResponse {
  gradeConditions: GradeConditionInfo[];
}

const getGradeConditionInfo = async (): Promise<GradeConditionInfo[]> => {
  const res = await fetch(`${CLOUDFRONT_URL}/assets/seller/sellerGradeConditions.json`);

  const data = await res.json();

  return data.gradeConditions;
};

const saveBookmark = (PID: number) => api.post(`/api/v5/bookmarks/gigs/${PID}`);
const deleteBookmark = (PID: number) => api.delete(`/api/v5/bookmarks/gigs/${PID}`);

interface SaveDirectOrderPayload {
  PID: number;
  price: number;
  total_price: number;
  quantity: number;
  days: number;
  packageType: 'STANDARD' | 'DELUXE' | 'PREMIUM';
  selected_options: unknown;
}

interface SaveDirectOrderResponse {
  DOID: number;
}

const saveDirectOrder = async (payload: SaveDirectOrderPayload) => {
  const { data } = await api.post<SaveDirectOrderResponse>(
    '/payments/types/GIG/direct-orders',
    payload,
  );

  return data;
};

interface GigReviewComment {
  id: number;
  score: number;
  comment: string;
  isAdvertisement: boolean;
}

export interface GetGigRecentReviewsResponse {
  reviews: GigReviewComment[];
}

/**
 * @description 서비스 ID로 최근 작성된 리뷰 목록을 가져옵니다.
 * @param gigId number
 */
const getGigRecentReviews = async (gigId: number) => {
  const { data } = await api.get<GetGigRecentReviewsResponse>(`/api/msa/gig-app/gig/v1/gigs/${gigId}/seller-pick-and-recent-reviews`);

  return data;
};

interface SellerSummary {
  thumbnail: string;
  isNowContactable: boolean;
  grade: GradeNewType;
  nickname: string;
  isAvailableTax: boolean;
  isOnline: boolean;
  isVacation: boolean;
  isFastReaction: boolean;
  userId: number;
}

type UpgradeOptionType = 'CHECKBOX' | 'DROPDOWN' | 'NUMBER';

export interface PackageUpgradeOption {
  extraData: {
    unit: string | null;
    min: number | null;
    max: number | null;
    infinite: boolean | null;
    step: number | null;
    type: string | null;
    isMandatory: boolean | null;
  };
  isPackageUpgradeOption: boolean | null;
  type: UpgradeOptionType | null;
  title: string | null;
  value: number | boolean | null;
}

export interface PackageData {
  upgradeOptions: PackageUpgradeOption[];
  price: number | null;
  description: string | null;
  days: number | null;
  revisions: number | null;
  title: string | null;
}

export interface Package {
  gigPackageId: number;
  data: PackageData | null;
  type: PackageType;
}

export interface ReviewSummary {
  score: number;
  thumbnail: string | null;
  comment: string;
  id: number;
}

export interface PortfolioSummary {
  thumbnail: string;
  portfolioId: number;
  keywords: string[];
  title: string;
}

export interface GetGigSummaryResponse {
  gig: {
    seller: SellerSummary;
    images: TempGig['images'];
    kmongAd: TempGig['kmongAd'];
    isCurrentUserBookmark: TempGig['isCurrentUserBookmark'];
    currentCategoryId: TempGig['currentCategoryId'];
    advertisementType: 'PLATINUM' | 'PLUS' | 'PREMIUM' | 'ROOKIE' | 'PLUSUP' | '';
    title: TempGig['title'];
    labels: TempGig['labels'];
    hasPackages: TempGig['hasPackages'];
    hasVideos: TempGig['hasVideos'];
    isAvailableCalling: TempGig['isAvailableCalling'];
    review: TempGig['review'];
    price: TempGig['price'];
    gigCardUuid: string | null;
    category: TempGig['category'];
    gigId: TempGig['gigId'];
    isPrime: TempGig['isPrime'];
  };
  packages: Package[];
  reviews: ReviewSummary[];
  portfolios: PortfolioSummary[];
  gigMetaGroupItems: string[];
}

/**
 * @description 서비스 ID로 서비스 상세 요약 정보를 가져옵니다.
 * @param gigId number
 */
const getGigSummary = async (gigId: number) => {
  const { data } = await api.get<GetGigSummaryResponse>(`/api/msa/gig-app/gig/v1/gigs/${gigId}/detail-summary`);

  return data;
};

export const gigApi = {
  getGradeConditionInfo,
  saveBookmark,
  deleteBookmark,
  saveDirectOrder,
  getGigRecentReviews,
  getGigSummary,
};
