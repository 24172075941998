import { captureException } from '@sentry/nextjs';
import { getDevice } from '../utils';

declare global {
  interface Window {
    cre: (...args: unknown[]) => void;
  }
}

const internalCresendo = (...args: unknown[]) => {
  if (typeof window === 'undefined') {
    return;
  }

  if (!window.cre) {
    return;
  }

  window.cre(...args);
};

const pageView = () => {
  try {
    internalCresendo('send', 'Pageview');
  } catch (e) {
    captureException(e);
  }
};

const registration = (eventNumber: number = getDevice() === 'PC' ? 1 : 2) => {
  internalCresendo('send', 'Registration', {
    event_number: eventNumber,
  });
};

const consultation = (properties: { [key: string]: unknown }) => {
  internalCresendo('send', 'Consultation', {
    ...properties,
    event_number: getDevice() === 'PC' ? 1 : 2,
  });
};

const sendCustomEvent = (
  eventNumber: number,
  customdata?: Record<string, unknown>,
) => {
  internalCresendo('send', 'Event', {
    event_number: eventNumber,
    ...(customdata ?? {}),
  });
};

export default {
  pageView,
  sendCustomEvent,
  registration,
  consultation,
};
