import api from '../api';
import type {
  ArticleDetailHeaderType, ArticleDetailModule, ArticleDetailPanel, PanelType, OpenGraph, BannerDataType,
} from './types';

// @TODO: 계속 loader 에러나서 gig.type.ts -> article-detail.ts로 옮긴 상태
export enum ArticleDetailGigDesignType {
  FIVE_LIST = 'FIVE_LIST',
  FIVE_CUT = 'FIVE_CUT',
}

export enum ArticleDetailPortfolioDesignType {
  FIVE_LIST = 'FIVE_LIST',
  FIVE_CUT = 'FIVE_CUT',
}

export interface GetArticleDetailResponse {
  articleId: number;
  title: string;
  thumbnail: string;
  openGraph: OpenGraph | null;
  headerType: ArticleDetailHeaderType;
  panelType: PanelType;
  panelData: ArticleDetailPanel;
  moduleData: ArticleDetailModule[];
  bannerData: BannerDataType | null;
}

const getArticleDetail = async (articleId: number, customUA?: string) => {
  const { data } = await api.get<GetArticleDetailResponse>(`/api/msa/community/article/v1/articles/${articleId}`, getHeaders(customUA));

  return data;
};

const getHeaders = (customUA?: string) => {
  if (!customUA) {
    return undefined;
  }

  return {
    headers: {
      'User-Agent': customUA,
    },
  };
};

export const articleDetailApi = {
  getArticleDetail,
};
