import api from '../api';

type PaymentType = 'SC0010' | 'SC0030';

type EasyPaymentType = 'TOSSPAY' | 'NAVERPAY';

/**
 * SC0010: 카드
 * SC0030: 실시간 계좌이체
 */
export type AllCounselPaymentType = PaymentType | EasyPaymentType;

export interface PostPaymentCounselCoinPayload {
  counselCoinChargePolicyId: number;
  /**
   * 간편결제일 땐 payType, easyPayType 둘 다 필요
   * @link https://kmong.slack.com/archives/C02SXE7JJSE/p1727664497986519?thread_ts=1727404667.157099&cid=C02SXE7JJSE
   */
  payType: AllCounselPaymentType;
  easypayType?: EasyPaymentType | null;
  nextPage?: string;
}

export enum PostPaymentCounselCoinErrorCode {
  AUTH_NOT_LOGGED_IN = 'AUTH_NOT_LOGGED_IN',
  TOSS_CHECKOUT_COUNSEL_COIN_ERROR_DOESNT_EXISTS_PAY_TYPE = 'TOSS_CHECKOUT_COUNSEL_COIN_ERROR_DOESNT_EXISTS_PAY_TYPE',
  TOSS_CHECKOUT_COUNSEL_COIN_ERROR_DOESNT_EXIST_CHARGE_POLICY_ID = 'TOSS_CHECKOUT_COUNSEL_COIN_ERROR_DOESNT_EXIST_CHARGE_POLICY_ID',
}

export interface CommonCounselPaymentResponse {
  CST_PLATFORM : string;
  LGD_WINDOW_TYPE: string;
}

interface PaymentCounselResponse {
  counselCoinChargePolicyId: number;
}

type PostPaymentCounselCoinResponse = CommonCounselPaymentResponse & PaymentCounselResponse;

const postPaymentCounselCoin = async (payload: PostPaymentCounselCoinPayload) => {
  const { data } = await api.post<PostPaymentCounselCoinResponse>('/payments/counsel-coin/payment-request', payload);

  return data;
};

export const phoneCounselingOrderApi = {
  postPaymentCounselCoin,
};
