import { captureException } from '@sentry/nextjs';

declare global {
  interface Window {
    /**
     * 참고 문서
     * https://kakaoad.github.io/kakao-pixel/index.html
     */
    kakaoPixel: (
      KAKAO_PIXEL_ID: string
    ) => {
      pageView: () => void;
      viewCart: () => void;
      viewContent: ({ tag }: { tag: string }) => void;
      search: ({ keyword, tag }: KakaoPixelSearch) => void;
      completeRegistration: () => void;
    };
  }
}

export interface KakaoPixelSearch {
  keyword: string;
  tag: string;
}

const internalKakaoPixel = () => {
  if (typeof window === 'undefined') {
    return null;
  }

  if (!window.kakaoPixel) {
    return null;
  }

  const KAKAO_PIXEL_ID = process.env.NEXT_PUBLIC_KAKAO_PIXEL ?? '';

  return window.kakaoPixel(KAKAO_PIXEL_ID);
};

const pageView = () => {
  try {
    internalKakaoPixel()?.pageView();
  } catch (e) {
    captureException(e);
  }
};

const viewCart = () => {
  try {
    internalKakaoPixel()?.viewCart();
  } catch (e) {
    captureException(e);
  }
};

const viewContent = (data: { tag: string }) => {
  try {
    internalKakaoPixel()?.viewContent(data);
  } catch (e) {
    captureException(e);
  }
};

const search = (data: KakaoPixelSearch) => {
  try {
    internalKakaoPixel()?.search(data);
  } catch (e) {
    captureException(e);
  }
};

const completeRegistration = () => {
  try {
    internalKakaoPixel()?.completeRegistration();
  } catch (e) {
    captureException(e);
  }
};

export default {
  pageView,
  viewCart,
  viewContent,
  search,
  completeRegistration,
};
