import { captureException } from '@sentry/nextjs';

declare global {
  interface Window {
    jenniferFront: (...args: unknown[]) => void;
  }
}

const internalJenniferFront = (...args: unknown[]) => {
  if (typeof window === 'undefined') {
    return;
  }

  if (!window.jenniferFront) {
    return;
  }

  window.jenniferFront(...args);
};

const setUserId = (userId: string) => {
  try {
    internalJenniferFront({ set: { userId } });
  } catch (e) {
    captureException(e);
  }
};

const clearUserId = () => {
  internalJenniferFront({ set: { userId: null } });
};

export default {
  setUserId,
  clearUserId,
};
