import type { ErrorData, IntegrationKmongErrorInstance, KmongErrorResponse } from '../../types';
import type { KmongV4ErrorResponse } from '../../types/kmong-error-legacy-instance.type';

/**
 * @param error 원본 에러 객체
 * @return 구 버전의 에러 객체를 새로운 MSA 에러 객체로 변환
 */
export const convertV4ErrorData = (error: IntegrationKmongErrorInstance) => {
  if (!(error?.response?.data)) {
    return defaultErrorInstance;
  }

  if (!error.response) {
    return defaultErrorInstance;
  }

  if (!('errors' in error.response.data)) {
    return defaultErrorInstance;
  }

  const legacyErrorData = error.response.data.errors?.[0];

  if (!legacyErrorData || !legacyErrorData.code || !legacyErrorData.message) {
    return defaultErrorInstance;
  }

  return {
    code: legacyErrorData.code,
    message: legacyErrorData.message,
  } as ErrorData<number>;
};

/**
 * @param error 원본 에러 객체
 * @param errorData MSA 스펙으로 마이그레이션 된 에러 데이터
 * @returns 마이그레이션 된 에러 데이터 새로운 에러 객체를 리턴
 */
export const createErrorInstance = (
  error: IntegrationKmongErrorInstance,
  errorData: ErrorData<string | number>,
) => {
  const errorInstance: IntegrationKmongErrorInstance = { ...error };

  if (!errorInstance.response?.data) {
    return error as KmongV4ErrorResponse;
  }

  if (('code' in errorInstance.response.data && 'message' in errorInstance.response.data)) {
    return error as KmongErrorResponse;
  }

  errorInstance.response.data = errorData as ErrorData<string>;

  return errorInstance as KmongErrorResponse;
};

const defaultErrorInstance: ErrorData<string> = {
  code: '',
  message: '',
};
