import api from './api';

interface CustomProject {
  requestId: number;
  title: string;
  isResident: boolean;
  isGovernment: boolean;
}

interface Buyer {
  userId: number;
  nickname: string;
}

interface Seller {
  userId: number;
  nickname: string;
}

export interface MeetingDetail {
  customProject: CustomProject;
  buyer: Buyer;
  seller: Seller;
}

const getMeetingDetail = async (proposalId: number, userId: number) => {
  const { data } = await api.get<MeetingDetail>(`/api/msa/enterprise/v1/proposal/${proposalId}/custom-project?userId=${userId}`);

  return data;
};

export interface AvailableMeetingSchedule {
  date: string;
  times: string[];
}

export interface AvailableMeetingTimesResponse {
  availableDatesAndTimes: AvailableMeetingSchedule[];
}

const getAvailableMeetingTimes = async (proposalId:number, userId: number, date?: string) => {
  const { data } = await api.get<AvailableMeetingTimesResponse>(`/api/msa/enterprise/v1/proposal/${proposalId}/meeting/available-time?userId=${userId}${date ? `&date=${date}` : ''}`);

  return data;
};

export interface Schedules {
  userId: number;
  firstChoice: string;
  secondChoice: string;
  thirdChoice: string;
}

export interface SaveSchedulePayload {
  proposalId: number;
  schedules: Schedules;
}

export interface UpdateSchedulePayload {
  proposalId: number;
  schedules: Schedules;
  meetingId?: number;
}

const saveSchedule = async ({ proposalId, schedules } : SaveSchedulePayload) => {
  await api.post(`/api/msa/enterprise/v1/proposal/${proposalId}/meeting`, schedules);
};

const updateSchedule = async ({ proposalId, schedules, meetingId }: UpdateSchedulePayload) => {
  await api.put(`/api/msa/enterprise/v1/proposal/${proposalId}/meeting/${meetingId}`, schedules);
};

export interface MeetingChoice {
  meetingAt: string;
  isDisable: boolean;
  message: string | null;
}

export interface MeetingChoices {
  firstChoice: MeetingChoice;
  secondChoice: MeetingChoice;
  thirdChoice: MeetingChoice;
}

const getMeetingChoices = async (proposalId: number, meetingId: number, userId: number) => {
  const { data } = await api.get<MeetingChoices>(`/api/msa/enterprise/v1/proposal/${proposalId}/meeting/${meetingId}?userId=${userId}`);

  return data;
};

export interface AcceptMeetingPayload {
  userId: number;
  meetingAt: string;
}

export interface AcceptMeetingProps {
  proposalId: number;
  meetingId: number;
  acceptMeetingPayload: AcceptMeetingPayload;
}

export interface AcceptMeetingResponse {
  userId: number;
  nickname: string;
  email: string;
}

const acceptMeeting = async ({ proposalId, meetingId, acceptMeetingPayload } : AcceptMeetingProps) => {
  const { data } = await api.post<AcceptMeetingResponse>(`/api/msa/enterprise/v1/proposal/${proposalId}/meeting/${meetingId}/accept`, acceptMeetingPayload);

  return data;
};

export interface CancelMeetingProps {
  proposalId: number;
  meetingId: number;
  userId: number;
}

const cancelMeeting = async ({ proposalId, meetingId, userId }: CancelMeetingProps) => {
  await api.delete(`/api/msa/enterprise/v1/proposal/${proposalId}/meeting/${meetingId}/cancel?userId=${userId}`);
};

export const meetingProposalApi = {
  getMeetingDetail,
  getAvailableMeetingTimes,
  saveSchedule,
  updateSchedule,
  getMeetingChoices,
  cancelMeeting,
  acceptMeeting,
};
