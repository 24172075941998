import { captureException } from '@sentry/nextjs';
import { webviewBridge } from '@kmong-service/utils';
import {
  getConvertedProperties,
  getDevice, logTracker,
} from '../utils';

declare global {
  interface Window {
    amplitude: {
      Identify: () => void;
      getInstance: () => {
        _eventId: number;
        setUserId: (userId: string | null) => void;
        logEvent: (
          eventName: string,
          properties: { [key: string]: unknown }
        ) => void;
        identify: (attributes: unknown) => void;
        getSessionId: () => number | null;
        getDeviceId: () => string | null;
        getUserId: () => string | null;
        regenerateDeviceId: () => void;
      };
    };
  }
}

const internalAmplitude = () => {
  if (typeof window === 'undefined') {
    return null;
  }

  if (!window.amplitude) {
    return null;
  }

  return window.amplitude;
};

const setUserId = (userId: string) => {
  internalAmplitude()?.getInstance()?.setUserId(userId);
};

const clearUserId = () => {
  internalAmplitude()?.getInstance()?.setUserId(null);
};

const sendAmplitudeCustomEvent = (
  eventName: string,
  properties?: { [key: string]: unknown },
) => {
  try {
    const convertedProperties = getConvertedProperties(properties);

    internalAmplitude()
      ?.getInstance()
      ?.logEvent(eventName, {
        ...convertedProperties,
        platform: getPlatform(),
      });
    logTracker({
      eventName,
      vender: 'amplitude',
      properties: JSON.stringify(convertedProperties),
    });
  } catch (e) {
    captureException(e);
  }
};

/**
 * @returns "mobile" | "android" | "ios" | "web"
 */
const getPlatform = () => {
  switch (getDevice()) {
    case 'MOBILE': {
      const webviewPlatform = getWebviewPlatform();

      return webviewPlatform;
    }
    case 'PC':
    default: {
      return 'web';
    }
  }
};

const getWebviewPlatform = () => {
  if (webviewBridge.isKmongAndroidWebView()) {
    return 'android';
  }

  if (webviewBridge.isKmongiOSWebView()) {
    return 'ios';
  }

  return 'mobile';
};

interface Attribute { [key: string]: unknown }

export interface Attributes {
  set?: Attribute;
  setOnce?: Attribute;
  add?: Attribute;
  append?: Attribute;
}

/*
  @example
  attributes: {
    set: {
      PushAllowed: this.isAllTrueCouponAds ? 'on' : 'off',
    },
  },
*/
const sendUserAttributesToAmplitude = (attributes: Attributes) => {
  if (!Object.keys(attributes).length) {
    return;
  }

  if (!internalAmplitude()) {
    return;
  }

  const Amplitude = internalAmplitude();

  if (Amplitude === null || typeof Amplitude.Identify !== 'function') {
    return;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const identifyFactory = new (Amplitude.Identify as any)();
  Object.keys(attributes).forEach((method) => {
    const values = attributes[method as keyof Attributes] ?? {};
    Object.keys(values).forEach((key) => {
      identifyFactory[method](key, values[key]);
    });
  });
  internalAmplitude()?.getInstance().identify(identifyFactory);
};

const getSessionId = () => internalAmplitude()?.getInstance()?.getSessionId?.();

const getDeviceId = () => internalAmplitude()?.getInstance()?.getDeviceId();

const getUserId = () => internalAmplitude()?.getInstance()?.getUserId();

// eslint-disable-next-line no-underscore-dangle
const getEventId = () => internalAmplitude()?.getInstance()?._eventId;

const regenerateDeviceId = () => internalAmplitude()?.getInstance()?.regenerateDeviceId();

export default {
  setUserId,
  clearUserId,
  sendAmplitudeCustomEvent,
  sendUserAttributesToAmplitude,
  getSessionId,
  getDeviceId,
  getUserId,
  getEventId,
  regenerateDeviceId,
};
