export const ANY_TIME_POSSIBLE = '언제나 가능';
export const NOT_SELECTED_TIME = '미설정';

type AvailableTime = number | string | null | undefined;

/**
 * @param from number | string | null | undefined;
 * @param to number | string | null | undefined;
 */
export const availableTime = (from: AvailableTime, to: AvailableTime) => {
  if (typeof from === 'undefined' || from === null || typeof to === 'undefined' || to === null) {
    return NOT_SELECTED_TIME;
  }

  const numFrom = Number(from);
  const numTo = Number(to);

  if (Number.isNaN(numFrom) || Number.isNaN(numTo)) {
    return NOT_SELECTED_TIME;
  }

  // 25이상의 숫자가 하나라도 있으면 오류로 간주, NOT_SELECTED_TIME 출력
  if (numFrom > 24 || numTo > 24) {
    return NOT_SELECTED_TIME;
  }

  //  0~23의 경우 ANY_TIME_POSSIBLE 출력
  if (numFrom === 0 && numTo === 23) {
    return ANY_TIME_POSSIBLE;
  }

  //  0~0의 경우 ANY_TIME_POSSIBLE 출력
  if (numFrom === 0 && numTo === 0) {
    return ANY_TIME_POSSIBLE;
  }

  return `${numFrom}시 ~ ${numTo}시`;
};
