import React from 'react';
import { CLOUDFRONT_URL } from '@kmong-service/utils';
import { defaultKeywords } from '../defaultKeywords';
import { familySites } from '../familySites';
import { buildJsonLdTags } from './jsonLds/buildJsonLdTags';
import type { BuildJsonLdTagsProps } from './jsonLds/buildJsonLdTags';

/**
 * @description 기본 SEO 설정을 위한 컴포넌트로, OpenSearch XML 링크와 기본 JSON-LD 데이터를 제공합니다.
 *
 * - OpenSearch XML 파일 위치를 제공하여 브라우저와 검색 엔진이 사이트 검색을 지원하도록 설정합니다.
 * - JSON-LD 데이터를 `<script>` 태그로 렌더링하여 SEO 최적화를 지원합니다.
 *
  * @example
 * <link rel="search" href="/opensearch.xml" type="..." />
 * <script type="application/ld+json">...</script>
 *
 * @returns {JSX.Element} OpenSearch XML과 JSON-LD 스크립트 태그를 렌더링합니다.
 */
function DefaultSEO() {
  return (
    <>
      <link
        href="/opensearch.xml"
        rel="search"
        title="kmong"
        type="application/opensearchdescription+xml"
      />
      {buildJsonLdTags(defaultJsonLd)}
    </>
  );
}

const {
  organizationDescription,
} = defaultKeywords;

const defaultJsonLd: BuildJsonLdTagsProps = {
  organization: {
    alternateName: 'kmong',
    description: organizationDescription,
    logo: `${CLOUDFRONT_URL}/assets/desktop/pages/main/kmong_wordmark.png`,
    name: '크몽',
    sameAs: familySites,
    url: 'https://kmong.com',
    address: {
      addressCountry: 'KR',
      addressLocality: '서울특별시',
      postalCode: '06625',
      streetAddress: '서울 서초구 사임당로 157 3층',
    },
  },
  website: {
    url: 'https://kmong.com',
    potentialAction: {
      urlTemplate: 'https://kmong.com/search?type=gigs&keyword={searchTerms}',
      queryInput: 'required name=searchTerms',
    },
  },
};

export default DefaultSEO;
