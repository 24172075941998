import { getHostname } from '@kmong/rest-client/src/api/lib/utils';
import {
  setCookie, getCookie, hasCookie, deleteCookie, getCookies,
} from 'cookies-next';
import type { CookiesKeyEnum } from './types/cookiesType';
import type { OptionsType } from 'cookies-next/lib/types';

const setItem = (key: CookiesKeyEnum, value: string, options?: OptionsType) => {
  setCookie(key, value, {
    ...options,
    domain: getHostname(),
  });
};

const getItem = (key: CookiesKeyEnum, options?: OptionsType) => (hasCookie(key, options)
  ? getCookie(key, {
    ...options,
    domain: getHostname(),
  })
  : null);

const getItems = (options?: OptionsType) => getCookies({
  ...options,
  domain: getHostname(),
});

const removeItem = (key: CookiesKeyEnum, options?: OptionsType) => deleteCookie(key, {
  ...options,
  domain: getHostname(),
});

export const cookies = {
  setItem,
  getItem,
  getItems,
  removeItem,
};
