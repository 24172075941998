export const getDevice = () => {
  if (typeof window === 'undefined') {
    return null;
  }
  const filter = 'win16|win32|win64|mac|macintel';
  let device: '' | 'PC' | 'MOBILE' = '';
  if (navigator.platform) {
    if (filter.includes(navigator.platform.toLowerCase())) {
      device = 'PC';
    } else {
      device = 'MOBILE';
    }
  }

  return device;
};

interface LogTrackerProps {
  eventName: string;
  vender: string;
  category?: string;
  properties: string;
}

export const logTracker = ({
  eventName,
  vender,
  category,
  properties,
}: LogTrackerProps) => {
  if (process.env.NEXT_PUBLIC_EVENT_LOGGER === 'ON') {
    // eslint-disable-next-line no-console
    console.info(`[eventLogger] ${eventName}[${vender}]${category ? ` (${category})` : ''}: ${properties}`);
  }

  return null;
};

const refineValue = (item: {
  [key: string]: unknown;
}) => Object.entries(item).reduce((props, [key, value]) => {
  switch (typeof value) {
    case 'boolean':
      return {
        ...props,
        [key]: value ? 'T' : 'F',
      };
    case 'undefined':
      return {
        ...props,
        [key]: '',
      };
    default:
      return {
        ...props,
        [key]: value,
      };
  }
}, {});

const getTypeCastingProperties = (properties?: { [key: string]: unknown }) => ({
  ...properties,
  ...(properties?.cat1 ? { cat1: String(properties.cat1) } : {}),
  ...(properties?.cat2 ? { cat2: String(properties.cat2) } : {}),
  ...(properties?.cat3 ? { cat3: String(properties.cat3) } : {}),
  ...(properties?.price ? { price: String(properties.price) } : {}),
  ...(properties?.pid ? { pid: String(properties.pid) } : {}),
  ...(properties?.PID ? { PID: String(properties.PID) } : {}),
  ...(properties?.cid ? { cid: String(properties.cid) } : {}),
  ...(properties?.CID ? { CID: String(properties.CID) } : {}),
  ...(properties?.seller_user_id ? { seller_user_id: String(properties.seller_user_id) } : {}),
});

export const getConvertedProperties = (properties?: { [key: string]: unknown }) => {
  const refinedProperties = properties ? refineValue(properties) : {};

  return getTypeCastingProperties(refinedProperties);
};

export default {};
