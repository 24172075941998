import api from '../api';

export type CategoryBannerLinkInfo = MDPickLinkType | PortfolioLinkType;

export interface MDPickLinkType {
  id: number;
  type: 'MD_PICK_PAGE';
}

export interface PortfolioLinkType {
  type: 'PORTFOLIO_LIST';
  rootCategoryId: number;
  subCategoryId: number | null;
}

export interface RootCategoryWithIcon {
  categoryId: number;
  name: string;
  icon: CategoryIcons;
}

interface CategoryIcons {
  mobile: string;
  desktop: string;
}

interface GetRootCategoryGroupsCategoriesResponse {
  categoryGroups: RootCategoriesGroup[];
}

export interface RootCategoriesGroup {
  groupId: number;
  name: string;
  categories: RootCategoryWithIcon[];
}

const getRootCategoryGroupsCategories = async () => {
  const { data } = await api.get<GetRootCategoryGroupsCategoriesResponse>('/api/msa/gig-app/category/v1/root-category-groups-categories');

  return data.categoryGroups;
};

interface GetRootCategoriesResponse {
  categories: RootCategoryWithIcon[];
}

const getRootCategories = async () => {
  const { data } = await api.get<GetRootCategoriesResponse>('/api/msa/gig-app/category/v1/root-categories');

  return data.categories;
};

export interface SubPlace {
  subPlaceId: number;
  placeName: string;
}

export interface FilterPlace {
  rootPlaceId: number;
  placeName: string;
  subPlaces: SubPlace[];
}

export interface GetPlacesResponse {
  places: FilterPlace[];
}

export const getPlaces = async (): Promise<GetPlacesResponse> => {
  const { data } = await api.get<GetPlacesResponse>('/api/msa/gig-app/place/v1/places');

  return data;
};

export interface CuratedContents {
  id: number;
  image: string;
  link: string;
  title: string;
}

export interface SubCategoryGroupCategory {
  categoryId: number;
  name: string;
  isNew: boolean;
  categoryGroups?: SubCategoryGroup[];
}
export interface SubCategoryGroup {
  id: number;
  parentCategoryId: number;
  name: string;
  isNew: boolean;
  categories?: SubCategoryGroupCategory[];
}

export interface GetSubCategoryGroupsResponse {
  categoryGroups: SubCategoryGroup[];
}

const getSubCategoryGroups = async (categoryId: number) => {
  const { data } = await api.get<GetSubCategoryGroupsResponse>(`/api/msa/gig-app/category/v1/categories/${categoryId}/side-navigation-bar`);

  return data;
};

export interface CategorySEOResponse {
  gigInfo: {
    totalGigCount: number;
    minGigPrice: number;
  };
  keywords: string;
  categoryInfo: {
    rootCategoryId: number;
    rootCategoryName: string;
    subCategoryId: number | null;
    subCategoryName: string | null;
    thirdCategoryId: number | null;
    thirdCategoryName: string | null;
  };
}

const getCategorySEO = async (categoryId: number) => {
  const { data } = await api.get<CategorySEOResponse>(`/api/msa/gig-app/category/v1/categories/${categoryId}/SEO`);

  return data;
};

interface GetPopularCategoriesResponse {
  items: PopularCategory[];
}

interface PopularCategory {
  categoryId: number;
  name: string;
}

const getPopularCategories = async (categoryId: number) => {
  const { data } = await api.get<GetPopularCategoriesResponse>(`/api/msa/gig-app/category/v1/categories/${categoryId}/popular-categories`);

  return data;
};

export const categoryApi = {
  getRootCategoryGroupsCategories,
  getRootCategories,
  getPlaces,
  getSubCategoryGroups,
  getCategorySEO,
  getPopularCategories,
};
