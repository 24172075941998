import api from '../api';

export type ScheduleColorType = 'blue' | 'purple' | 'yellow' | 'red' | 'green' | 'orange';

export interface SaveSchedulesPayload {
  title: string;
  scheduleDateTime: string;
  orderId: number | null;
  color: ScheduleColorType;
  memo: string | null;
}

export interface SaveSchedulesResponse {
  userScheduleAssigneeIds: number[];
}

const postSchedules = async (payload: SaveSchedulesPayload) => {
  const { data } = await api.post<SaveSchedulesResponse>(
    '/api/msa/user-app/user/schedule/v2/schedules',
    payload,
  );

  return data;
};

export type ModifySchedulesPayload = Omit<SaveSchedulesPayload, 'orderId'>;

const putSchedules = async (
  userScheduleId: number,
  payload: ModifySchedulesPayload,
) => {
  const { data } = await api.put<SaveSchedulesResponse>(
    `/api/msa/user-app/user/schedule/v2/schedules/${userScheduleId}`,
    payload,
  );

  return data;
};

const deleteSchedules = async (userScheduleId: number) => {
  const { data } = await api.delete<SaveSchedulesResponse>(`/api/msa/user-app/user/schedule/v2/schedules/${userScheduleId}`);

  return data;
};

export interface UserScheduleOrdersParams {
  page: number;
  perPage: number;
}

interface User {
  userId: number;
  nickname: string;
  image: string;
}

interface OrderProduct {
  productType: string;
  productId: number;
  category: {
    rootCategoryId: number;
    subCategoryId: number;
    thirdCategoryId: number;
  };
  title: string;
  image: string;
}

export interface ScheduleItem {
  orderId: number;
  orderCreatedDateTime: string;
  orderType: string;
  totalAmount: number;
  seller: User;
  buyer: User;
  orderProduct: OrderProduct;
}

export interface UserScheduleOrdersResponse {
  totalItemCount: number;
  lastPage: number;
  currentPage: number;
  perPage: number;
  items: ScheduleItem[];
}

const getUserScheduleOrders = async (params: UserScheduleOrdersParams) => {
  const { data } = await api.get<UserScheduleOrdersResponse>(
    '/api/msa/order-app/order/v1/orders/user-schedule-orders',
    {
      params,
    },
  );

  return data;
};

export interface ScheduleDetailResponse {
  userScheduleId: number;
  title: string;
  scheduleDateTime: string;
  orderInfo: {
    orderId: number;
    totalAmount: number;
    orderProduct: OrderProduct;
    buyer: {
      userId: number;
      nickname: string;
      thumbnail: string;
    };
    orderStartDateTime: string;
  } | null;
  color: ScheduleColorType;
  memo: string | null;
}

const getScheduleDetail = async (userScheduleId: number) => {
  const { data } = await api.get<ScheduleDetailResponse>(`/api/msa/user-app/user/schedule/v1/schedules/${userScheduleId}`);

  return data;
};

export interface SchedulesParams {
  page: number;
  perPage: number;
  sortType?: 'SCHEDULE_DATE_TIME_ASC';
  scheduleStatusType?: 'PROGRESS';
  scheduleDate?: string;
  isExcludeToday?: boolean;
}

export interface SchedulesItem {
  userScheduleId: number;
  title: string;
  scheduleDateTime: string;
  orderInfo: {
    orderId: number;
    orderProduct: {
      productType: string;
      productId: number;
      title: string;
      image: string;
    };
  } | null;
  color: ScheduleColorType;
  memo: string | null;
}

export interface SchedulesResponse {
  totalItemCount: number;
  lastPage: number;
  currentPage: number;
  perPage: number;
  items: SchedulesItem[];
}

const getSchedules = async (params: SchedulesParams) => {
  const { data } = await api.get<SchedulesResponse>('/api/msa/user-app/user/schedule/v1/schedules', {
    params,
  });

  return data?.items ?? [];
};

interface SchedulesSummariesParams {
  startedDate: string;
  endedDate: string;
}

export interface ScheduleSummariesSchedule {
  scheduleId: number;
  scheduleDateTime: string;
  title: string;
  color: ScheduleColorType;
  orderId: number | null;
}

export interface ScheduleSummary {
  date: string;
  totalCount: number;
  schedules: ScheduleSummariesSchedule[];
  groupedColors: ScheduleColorType[];
}

interface SchedulesSummariesResponse {
  summaries: ScheduleSummary[];
}

const getSchedulesSummaries = async (params: SchedulesSummariesParams) => {
  const { data } = await api.get<SchedulesSummariesResponse>('/api/msa/user-app/user/schedule/v1/schedules/summaries', {
    params,
  });

  return data.summaries ?? [];
};

export const scheduleApi = {
  postSchedules,
  putSchedules,
  deleteSchedules,
  getUserScheduleOrders,
  getScheduleDetail,
  getSchedules,
  getSchedulesSummaries,
};
