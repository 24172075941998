'use client';

import { useEffect } from 'react';
import type { HackleReactSDKClient } from '@hackler/react-sdk/lib/client';

declare global {
  interface Window {
    hackleClient?: HackleReactSDKClient;
  }
}

function useHackleUserExplorerInDevelopment() {
  useEffect(() => {
    if (isDevelopment) {
      window.hackleClient?.showUserExplorer();
    }

    return () => {
      if (isDevelopment) {
        window.hackleClient?.hideUserExplorer();
      }
    };
  }, []);
}

const isDevelopment = process.env.NEXT_PUBLIC_MODE !== 'production';

export default useHackleUserExplorerInDevelopment;
