import { CLOUDFRONT_URL } from '@kmong-service/utils';

export type BannerItemType = 'NONE' | 'CATEGORY' | 'ARTICLE' | 'MD_PICK';

export interface BannerItem {
  id?: number;
  title: string;
  description?: string;
  type: BannerItemType;
  imageUrl: string;
  bgHexColor?: string;
}

interface BannerGroup {
  title: string;
  items: Array<BannerItem>;
}

export interface BannersResponse {
  hero: BannerGroup;
  curationBanners: BannerGroup[];
  banners: BannerItem[];
}

const getBanners = async (categoryId: number): Promise<BannersResponse | null> => {
  try {
    const data = await fetch(`${CLOUDFRONT_URL}/assets/gateway/categories/${categoryId}/banners.json`);

    return data.json();
  } catch {
    return null;
  }
};

export const bannersApi = {
  getBanners,
};
