import { captureMessage } from '@sentry/nextjs';
import { minifyJsonLd } from '@kmong/utils';
import escape from 'lodash/escape';
import type {
  AddressJsonLd, FAQJsonLd, ItemListElement, BreadcrumbItemListJsonLd, LastItemListElement, OrganizationJsonLd, PersonJsonLd, PotentialActionJsonLd, ProductJsonLd, WebSiteJsonLd,
} from './buildJsonLdTags';

export const getMinifyJsonLd = (jsonld: string) => {
  try {
    return minifyJsonLd(jsonld);
  } catch {
    captureMessage('minify jsonld 실패');

    return jsonld;
  }
};

export const getBreadcrumbJsonLd = (itemListElements: BreadcrumbItemListJsonLd) => {
  const breadcrumbJsonLd = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: itemListElements.map((element) => ({
      '@type': 'ListItem',
      position: element.position,
      ...(isItemListElement(element) && { item: element.item }),
      name: element.name,
    })),
  };

  return JSON.stringify(breadcrumbJsonLd);
};

function isItemListElement(element: ItemListElement | LastItemListElement): element is ItemListElement {
  return 'item' in element;
}

export const getFaqJsonLd = (faqs: FAQJsonLd[]) => {
  const faqJsonLd = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: faqs.map((faq) => ({
      '@type': 'Question',
      name: escape(faq.question),
      acceptedAnswer: {
        '@type': 'Answer',
        text: escape(faq.answer),
      },
    })),
  };

  return JSON.stringify(faqJsonLd);
};

const getAddress = (address: AddressJsonLd) => ({
  '@type': 'PostalAddress',
  streetAddress: address.streetAddress,
  addressLocality: address.addressLocality,
  ...(address.addressRegion && { addressRegion: address.addressRegion }),
  postalCode: address.postalCode,
  addressCountry: address.addressCountry,
});

export const getOrganizationJsonLd = ({
  name,
  url,
  alternateName,
  description,
  logo,
  sameAs,
  address,
}: OrganizationJsonLd) => {
  const jsonld = {
    '@context': 'http://schema.org',
    '@type': 'Organization',
    name,
    url,
    alternateName,
    description,
    logo,
    sameAs,
    ...(address && {
      address: Array.isArray(address)
        ? address.map(getAddress)
        : getAddress(address),
    }),
  };

  return JSON.stringify(jsonld);
};

export const getPersonJsonLd = ({
  name,
  url,
  alternateName,
  description,
  sameAs,
}: PersonJsonLd) => {
  const jsonld = {
    '@context': 'http://schema.org',
    '@type': 'Person',
    name,
    url,
    alternateName,
    description,
    sameAs,
  };

  return JSON.stringify(jsonld);
};

export const getProductJsonLd = ({
  name,
  image,
  description,
  sku,
  offers,
  reviews,
  aggregateRating,
}: ProductJsonLd) => {
  const jsonld = {
    '@context': 'https://schema.org',
    '@type': 'Product',
    name: escape(name),
    image,
    description: escape(description),
    ...(sku && { sku }),
    ...(offers && {
      offers: {
        '@type': 'AggregateOffer',
        ...(offers.offerCount && { offerCount: offers.offerCount }),
        lowPrice: offers.lowPrice,
        highPrice: offers.highPrice,
        priceCurrency: offers.priceCurrency,
      },
    }),
    ...(reviews && {
      review: reviews.map((review) => ({
        '@type': 'Review',
        reviewBody: escape(review.reviewBody),
        reviewRating: {
          '@type': 'Rating',
          ratingValue: review.reviewRating.ratingValue,
          bestRating: review.reviewRating.bestRating,
          worstRating: review.reviewRating.worstRating,
        },
        author: {
          '@type': 'Person',
          name: escape(review.author.name),
        },
      })),
    }),
    ...(aggregateRating && {
      aggregateRating: {
        '@type': 'AggregateRating',
        bestRating: aggregateRating.bestRating,
        worstRating: aggregateRating.worstRating,
        ratingValue: aggregateRating.ratingValue,
        ratingCount: aggregateRating.ratingCount,
        reviewCount: aggregateRating.reviewCount,
      },
    }),
  };

  return JSON.stringify(jsonld);
};

const getPotentialAction = ({
  urlTemplate,
  queryInput,
}: PotentialActionJsonLd) => ({
  '@type': 'SearchAction',
  target: {
    '@type': 'EntryPoint',
    urlTemplate,
  },
  'query-input': queryInput,
});

export const getWebSiteJsonLd = ({
  url,
  potentialAction,
}: WebSiteJsonLd) => {
  const jsonld = {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    url,
    potentialAction: Array.isArray(potentialAction)
      ? potentialAction.map(getPotentialAction)
      : getPotentialAction(potentialAction),
  };

  return JSON.stringify(jsonld);
};
