import { CookiesKeyEnum } from '@kmong-service/utils';
import { getCookie } from 'cookies-next';
import { isKmongV4Error, KmongErrorInstance } from '../../types';
import { logout } from '../../utils/logout';
import { JWT_HEADER_STATUS_KEY, JWTTokenStatus } from '../constants';
import { convertV4ErrorData, createErrorInstance } from './errorInstance';
import { getTokenByTokenRefresh } from './jwt';
import { addPrefixWithToken, KMONG_AUTHORIZATION_HEADER_KEY } from './utils';
import type { IntegrationKmongErrorInstance, KmongErrorResponse } from '../../types';
import type { AxiosError, AxiosInstance, InternalAxiosRequestConfig } from 'axios';

let isRedirectingPage = false;

let isRefreshingAccessToken = false;

let newAccessToken = '';

export function axiosResponseInterceptor(api: AxiosInstance) {
  api.interceptors.response.use(undefined, async (error: AxiosErrorWithRetry) => {
    const {
      config,
      response,
    } = error;

    if (isRedirectingPage) {
      return Promise.reject(error);
    }

    if (!isUnAuthorizedToken(response?.status)) {
      const migratedError = handleError(error);

      return Promise.reject(migratedError);
    }

    if (isInvalidateToken(response?.headers[JWT_HEADER_STATUS_KEY])) {
      logoutWithRedirect();

      return Promise.reject(error);
    }

    if (isExpiredToken(response?.headers[JWT_HEADER_STATUS_KEY])) {
      const refreshToken = getCookie(CookiesKeyEnum.KMONG_REFRESH_TOKEN) as string;

      if (!refreshToken) {
        return Promise.reject(error);
      }

      if (isFirstRequestAccessToken()) {
        newAccessToken = await refreshAccessToken(refreshToken);
      }

      if (isBelowMaxRetryCount(config?.retryCount)) {
        return retryApiRequestPromise(api, config);
      }

      return api({
        ...config,
        headers: {
          ...config.headers,
          [KMONG_AUTHORIZATION_HEADER_KEY]: addPrefixWithToken(newAccessToken),
        },
      });
    }

    return Promise.reject(error);
  });
}

const logoutWithRedirect = async () => {
  setIsRedirectingPage(true);
  logout();
};

const setIsRedirectingPage = (newValue: boolean) => {
  isRedirectingPage = newValue;
};

const refreshAccessToken = async (refreshToken: string) => {
  isRefreshingAccessToken = true;

  try {
    const accessToken = await getTokenByTokenRefresh(refreshToken);

    return accessToken;
  } catch {
    logoutWithRedirect();

    return '';
  } finally {
    isRefreshingAccessToken = false;
  }
};

interface CodeWithMessage {
  code: string;
  message: string;
}

export interface AxiosErrorWithRetry extends AxiosError<CodeWithMessage> {
  config: InternalAxiosRequestConfig & {
    retryCount?: number;
  };
}

const retryApiRequestPromise = (
  api: AxiosInstance,
  config: AxiosErrorWithRetry['config'],
) => {
  const configWithRetryCount = {
    ...config,
    retryCount: increaseRetryCount(config.retryCount),
  };

  return new Promise((resolve) => {
    setTimeout(() => resolve(api.request(configWithRetryCount)), 2000);
  });
};

const increaseRetryCount = (count: number = 0) => count + 1;

const isBelowMaxRetryCount = (retryCount: number = 0) => retryCount < MAX_RETRY_COUNT;

const isFirstRequestAccessToken = () => !isRefreshingAccessToken;

const isExpiredToken = (status = '') => status === JWTTokenStatus.EXPIRED;

const isInvalidateToken = (status = '') => status === JWTTokenStatus.INVALIDATE;

const isUnAuthorizedToken = (status = 0) => status === 401;

const MAX_RETRY_COUNT = 3;

function handleError(error: IntegrationKmongErrorInstance) {
  if (!error?.response?.data) {
    return error;
  }

  /**
   * @description data에서 404, 502 error 페이지 자체가 string 으로 오는 케이스 예외처리
   */
  if (typeof error.response?.data === 'string') {
    return error;
  }

  if ('meta' in error.response.data) {
    return error;
  }

  const errorData = convertV4ErrorData(error);

  const migrateError = createErrorInstance(error, errorData);

  if (!migrateError.response) {
    return error;
  }

  if (isKmongV4Error(migrateError)) {
    return error;
  }

  if (!(migrateError.response.data?.message) || !(migrateError.response?.data)) {
    return error;
  }

  return new KmongErrorInstance(migrateError.response) as KmongErrorResponse;
}
