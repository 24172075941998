declare global {
  interface Window {
    karrotPixel: {
      init: (kakaoKey: string) => void;
      track: typeof track;
    };
  }
}

type TrackName = 'ViewPage' | 'Login' | 'CompleteRegistration';

type ViewContent = 'ViewContent';

type Purchase = 'Purchase';

type TrackNames = TrackName | ViewContent | Purchase;

export interface ViewContentProperty {
  id: string;
}

export interface PurchaseProperty {
  total_price: string;
  total_quantity: string;
  products: [{
    id: string;
    name: string;
    quantity: string;
    price: string;
  }];
}

const internalDanggn = () => {
  if (typeof window === 'undefined') {
    return null;
  }

  if (!window.karrotPixel) {
    return null;
  }

  return window.karrotPixel;
};

const init = () => {
  internalDanggn()?.init(process.env.NEXT_PUBLIC_DANGGN_KEY ?? '');
};

function track(trackName: TrackName): void;
function track(trackName: ViewContent, property: ViewContentProperty): void;
function track(trackName: Purchase, property: PurchaseProperty): void;

function track(trackName: TrackNames, property?: PurchaseProperty | ViewContentProperty) {
  try {
    if (trackName === 'ViewContent') {
      internalDanggn()?.track(trackName, property as ViewContentProperty);

      return;
    }

    if (trackName === 'Purchase') {
      internalDanggn()?.track(trackName, property as PurchaseProperty);

      return;
    }

    internalDanggn()?.track(trackName);
  } catch {
    // do nothing
  }
}

export default {
  init,
  track,
};
