import api from './api';
import type { Gig } from './gig';
import type { PortfolioCardBase } from './portfolio';

export interface GetBookmarksResponse {
  isBookmarked: boolean;
  bookmarkedPortfolioIds: number[];
}

const getBookmarks = async (PID: number) => {
  try {
    const { data } = await api.get<GetBookmarksResponse>(`/api/msa/gig-app/gig/v1/gigs/${PID}/bookmarks`);

    return data;
  } catch {
    return {
      isBookmarked: false,
      bookmarkedPortfolioIds: [],
    };
  }
};

export interface BookmarksCategory {
  id: number;
  name: string;
  count: number;
}

export interface GetBookmarksGigsCountResponse {
  categories: BookmarksCategory[];
}

const getBookmarksGigsCount = async () => {
  const { data } = await api.get<GetBookmarksGigsCountResponse>('/api/v5/bookmarks/gigs/count');

  return data;
};

export interface PortfolioCategoryCount {
  root_category_id: number;
  root_category_name: string;
  count: number;
}
export interface GetBookmarksPortfoliosCountResponse {
  total: number;
  category_list: PortfolioCategoryCount[];
}

const getBookmarksPortfoliosCount = async () => {
  const { data } = await api.get<GetBookmarksPortfoliosCountResponse>('/api/portfolio/v1/buyer/bookmark/categories');

  return data;
};

export interface GetBookmarksGigsParams {
  root_category_id?: number;
  page?: number;
}

export interface GetBookmarksGigsResponse {
  gigs: Gig[];
  last_page: number;
  total: number;
  next_page_link: string;
  previous_page_link: string;
}

const getBookmarksGigs = async (params?: GetBookmarksGigsParams) => {
  const { data } = await api.get<GetBookmarksGigsResponse>(
    '/api/v5/bookmarks/gigs',
    {
      params,
    },
  );

  return data;
};

export interface GetBookmarksPortfoliosParams {
  page?: number;
  rootCategoryId?: number;
  limit?: number;
}

export interface GetBookmarksPortfoliosResponse {
  total: number;
  lastPage: number;
  rootCategoryId: number;
  bookmarks: PortfolioCardBase[];
}

const getBookmarksPortfolios = async (params?: GetBookmarksPortfoliosParams) => {
  const { data } = await api.get<GetBookmarksPortfoliosResponse>(
    '/api/portfolio/v3/buyer/bookmarks',
    {
      params: {
        ...params,
        limit: 16,
      },
    },
  );

  return data;
};

export interface GetPopularGigsResponse {
  title: string;
  gigs: Gig[];
}

const getPopularGigs = async () => {
  const { data } = await api.get<GetPopularGigsResponse>('/api/v5/gigs/popular-gigs');

  return data;
};

export const bookmarkApi = {
  getBookmarks,
  getBookmarksGigsCount,
  getBookmarksPortfoliosCount,
  getBookmarksGigs,
  getBookmarksPortfolios,
  getPopularGigs,
};
