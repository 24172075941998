import { authApi, isLocal } from '@kmong/rest-client';
import { vendors } from '@kmong/trackers';
import { getRefreshToken, removeTokenCookies } from '../api/lib/jwt';

/**
 * @description 로그아웃 시 amplitude UserId를 초기화 해주어야 하므로 로그아웃 시 반드시 이 함수를 사용해야 합니다.
 */
export async function logout() {
  if (isLocal()) {
    await authApi.logout(getRefreshToken());
    removeTokenCookies();
  }

  const { amplitude } = vendors;

  amplitude.clearUserId();
  amplitude.regenerateDeviceId();
  window.location.assign('/logout');
}
