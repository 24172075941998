import api from '../api';
import resolutionProblemApis from './resolution-problem';
import type {
  TaxRequest,
  OrderDateTimes,
  OrderItemProductType,
  OrderStatusType,
  SellerOrderStatusLabel,
  SellerOrderStatusType,
  SellerStatusSearchOptions,
  OrderUserInfo,
  OrderHistoryCount,
} from '../types';

export interface OrderItemPayments {
  taxRequest: TaxRequest | null;
}

export type SellerOrderItemStatus = OrderStatusType<SellerOrderStatusType, SellerOrderStatusLabel>;

export interface SellerOrderItem {
  orderId: number;
  orderProgressId: number | null;
  seller: OrderUserInfo;
  buyer: OrderUserInfo;
  orderProduct: OrderItemProductType;
  totalAmount: number;
  orderStatus: SellerOrderItemStatus;
  payments: OrderItemPayments;
  orderDateTimes: OrderDateTimes;
}

export interface GetOrdersResponse {
  total: number;
  lastPage: number;
  page: number;
  pageSize: number;
  contents: SellerOrderItem[];
}

export const SELLER_SORT_TYPE = {
  UPDATED_AT: 'UPDATED_AT',
  EXPECTED_COMPLETED_AT: 'EXPECTED_COMPLETED_AT',
  CREATED_AT: 'CREATED_AT',
  COMPLETED_AT: 'COMPLETED_AT',
} as const;

export type SellerSortType = keyof typeof SELLER_SORT_TYPE;

export const SELLER_SEARCH_KIND_TYPE = {
  NICKNAME: 'NICKNAME',
  ORDER_ID: 'ORDER_ID',
  PRODUCT_ID: 'PRODUCT_ID',
} as const;

export type SellerSearchKindType = keyof typeof SELLER_SEARCH_KIND_TYPE;

export interface GetSellerOrdersParams {
  statusType: SellerStatusSearchOptions;
  searchKindType: SellerSearchKindType;
  sortType: SellerSortType;
  hasTaxRequest: boolean;
  keyword: string;
  page: number;
  startDate?: string;
  endDate?: string;
}

const getSellerOrders = async (params: GetSellerOrdersParams) => {
  // TODO 추후 jwt 완료되면 jwt 토큰 값 넣고 X-Kmong-Authorization 헤더 붙여야 함
  const { data } = await api.get<GetOrdersResponse>('/api/msa/order-app/order/v1/orders/seller/histories', {
    params: {
      ...params,
      pageSize: 10,
    },
  });

  return data;
};

const getSellerOrderCount = async () => {
  // TODO 추후 jwt 완료되면 jwt 토큰 값 넣고 X-Kmong-Authorization 헤더 붙여야 함
  const { data } = await api.get<OrderHistoryCount>('/api/msa/order-app/order/v1/orders/seller/histories/count');

  return data;
};

export interface ProgressDeliveryRequestFile {
  fileId: number;
  fileName: string;
  previewUrl: string;
}

export interface PostOrderProgressDeliveryRequestFormdata {
  message: string;
  files: ProgressDeliveryRequestFile[];
}

const postOrderProgressDeliveryRequest = async (
  orderId: number,
  formData: PostOrderProgressDeliveryRequestFormdata,
) => {
  await api.post(`/api/order/v1/orders/${orderId}/progress/delivery/request`, formData);
};

const putOrderProgressDeliveryRetract = async (orderId: number, orderProgressId: number | null) => {
  if (!orderProgressId) {
    throw new Error();
  }

  await api.put(`/api/order/v1/orders/${orderId}/progress/delivery/retract`, {
    orderProgressId,
  });
};

const postSkipInstructions = async (orderId: number) => {
  const { data } = await api.post(`/api/order/v1/orders/${orderId}/progress/instructions/skip`);

  return data;
};

interface GetDownloadTaxRequestExcel {
  date: string;
}

export const DOWNLOAD_TAX_REQUEST_ERROR = {
  AUTH_NOT_LOGGED_IN: '세션이 만료되었습니다.',
  EXCEL_ERROR_CURRENTLY_RUNNING_EXCEL: '다운로드 진행중입니다.',
} as const;

type ExcelDownloadErrorCode = keyof typeof DOWNLOAD_TAX_REQUEST_ERROR;

export interface DownloadTaxRequestExcelErrorData {
  result: string;
  errorCode: ExcelDownloadErrorCode;
  message: string;
  data: null;
}

interface GetDownloadTaxRequestExcelResponse {
  link: string;
}

const getDownloadTaxRequestExcel = async ({ date }: GetDownloadTaxRequestExcel) => {
  const { data } = await api.get<GetDownloadTaxRequestExcelResponse>('/api/order/v1/orders/seller/tax-requests/excel', {
    params: { date },
  });

  return data.link;
};

export const orderListApi = {
  getSellerOrders,
  getSellerOrderCount,
  postOrderProgressDeliveryRequest,
  putOrderProgressDeliveryRetract,
  postSkipInstructions,
  getDownloadTaxRequestExcel,
  ...resolutionProblemApis,
};
