/**
 * 문자열로 된 HTML 컨텐츠를 파싱하여 DOM 객체로 변환한 후, 해당 내용의 텍스트 컨텐츠를 반환합니다
 * @param message
 * @returns
 */
function stringToDomTextContent(message: string | null) {
  try {
    const parser = new DOMParser();

    return parser.parseFromString(message ?? '', 'text/html').documentElement.textContent || '';
  } catch {
    return message || '';
  }
}

export default stringToDomTextContent;
