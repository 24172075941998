declare global {
  interface Window {
    fbq: (
      eventType: string,
      eventName: string,
      properties?: { [key: string]: unknown }
    ) => void;
  }
}

const internalFacebookPixel = (
  eventType: string,
  eventName: string,
  properties?: { [key: string]: unknown },
) => {
  if (typeof window === 'undefined') {
    return;
  }

  if (!window.fbq) {
    return;
  }

  window.fbq(eventType, eventName, properties);
};

const pageView = () => {
  internalFacebookPixel('track', 'PageView');
};

const search = (keyword: string) => {
  internalFacebookPixel('track', 'Search', {
    search_string: keyword,
  });
};

const completeSignUp = (USERID: string) => {
  internalFacebookPixel('track', 'CompleteRegistration', {
    USERID,
  });
};

export interface AddToBookmarkProps {
  content_name: string;
  content_type: number;
  content_ids: number;
  value: number;
}

const addToBookmark = (params: AddToBookmarkProps) => {
  internalFacebookPixel('track', 'AddToWishlist', {
    ...params,
    currency: 'KRW',
  });
};

export interface ViewContentProps {
  value: number;
  content_type: number;
  content_name: string;
  content_ids: number;
}

const viewContent = (params: ViewContentProps) => {
  internalFacebookPixel('track', 'ViewContent', {
    ...params,
    currency: 'KRW',
  });
};

export interface TrackCustomProps {
  [key: string]: unknown;
}

const trackCustom = (eventName: string, params?: TrackCustomProps) => {
  internalFacebookPixel('trackCustom', eventName, {
    ...params,
  });
};

export default {
  pageView,
  search,
  completeSignUp,
  addToBookmark,
  viewContent,
  trackCustom,
};
