import api from '../../api';

export type SellerProfileSellerType = 'ORGANIZATION' | 'INDIVIDUAL';
export type SellerProfileWorkType = 'RESIDENT' | 'OUTSOURCING' | 'ALL';
export type SellerProfileState = 'NO_PROFILE' | 'DRAFTED' | 'PUBLISHED';
export type SellerProfileSkillLevel = 'BEGINNER' | 'INTERMEDIATE' | 'ADVANCED';
export type SellerProfileSectionName =
  | 'sellerType'
  | 'workType'
  | 'summary'
  | 'skills'
  | 'headline'
  | 'sellerWorkType'
  | 'serviceCategories'
  | 'workPlace'
  | 'projectHistories'
  | 'contacts'
  | 'experienceLength'
  | 'companyProfile'
  | 'portfolios'
  | 'portfoliosOff'
  | 'desiredSalary'
  | 'resume'
  | 'licenses'
  | 'licensesOff'
  | 'education'
  | 'careers'
  | 'careersOff'
  | 'finalEducation'
  | 'company';

export interface ServiceCategory {
  categoryId: number;
  categoryName?: string;
  subcategories: ServiceSubcategory[];
}

export interface ServiceSubcategory {
  subcategoryId: number;
  subcategoryName?: string;
}

export interface WorkPlace {
  availableWorkPlaces: {
    placeId: number;
    placeName?: string;
  }[];
  workRemotely: boolean;
  workOnsite: boolean;
}

interface Skill {
  skillId: number;
  skillName?: string;
}

export interface ProjectHistories {
  id?: number;
  title: string;
  startYearMonth: string;
  endYearMonth: string | null;
  skills: Skill[];
  description: string;
  isKmongProject: boolean;
}

export interface Contacts {
  id?: number;
  name: string;
  positionId: number;
  positionName?: string;
  phone: string;
  email: string;
  isPaymentOfficer: boolean;
}

export interface ExperienceLength {
  years: number | null;
  months: number | null;
}

export interface SellerProfileFile {
  fileId: number;
  originalFilename: string;
  fileSize: number;
  thumbnailUrl: string | null;
}

export type CompanyProfile = SellerProfileFile;

export interface SellerProfileSkill extends Skill {
  id?: number;
  level: SellerProfileSkillLevel | null;
}

export interface Portfolios {
  id?: number;
  title: string;
  startYearMonth: string;
  endYearMonth: string | null;
  skills: Skill[];
  participationRate: number | null;
  role: string;
  amountRangeId: number;
  amountRangeName?: string;
  file: SellerProfileFile | null;
}

export interface EstablishedYear {
  value: number;
  leap?: boolean;
}

export interface Licenses {
  id?: number;
  title: string;
  organization: string | null;
  issuedYearMonth: string | null;
}

export interface SellerProfileEducation {
  id?: number;
  schoolName: string;
  schoolTypeCode?: number | null;
  schoolTypeName?: string | null;
  major: string | null;
  startYearMonth: string | null;
  endYearMonth: string | null;
}

export interface Careers {
  id?: number;
  companyName: string;
  startYearMonth: string;
  endYearMonth: string | null;
  skills: Skill[];
  description: string;
}

export interface FinalEducation {
  educationTypeCode: number;
  educationTypeName?: string | null;
}

export interface Company {
  name: string;
  scaleCode: number | null;
  scaleName?: string | null;
  establishedYear: number | null;
}

export interface GetSellerProfileResponse {
  state?: SellerProfileState | null;
  skills?: SellerProfileSkill[] | null;
  sellerType?: SellerProfileSellerType | null;
  workType?: SellerProfileWorkType | null;
  summary?: string | null;
  headline?: string | null;
  sellerWorkType?: {
    sellerType: SellerProfileSellerType | null;
    establishedYear: SellerProfileWorkType | null;
  };
  serviceCategories?: ServiceCategory[] | null;
  workPlace?: WorkPlace | null;
  projectHistories?: ProjectHistories[] | null;
  experienceLength?: ExperienceLength | null;
  contacts?: Contacts[] | null;
  companyProfile?: SellerProfileFile | null;
  resume?: SellerProfileFile | null;
  portfolios?: Portfolios[] | null;
  portfoliosOff: boolean;
  desiredSalary?: number | null;
  licenses?: Licenses[] | null;
  licensesOff: boolean;
  education?: SellerProfileEducation[] | null;
  careers?: Careers[] | null;
  careersOff: boolean;
  finalEducation?: FinalEducation | null;
  company?: Company | null;
}

const getSellerProfile = async () => {
  const { data } = await api.get<GetSellerProfileResponse>('/api/msa/enterprise/v1/seller-profile');

  return data;
};

export interface GetSellerProfileStateResponse {
  state: SellerProfileState;
}

const getSellerProfileState = async () => {
  const { data } = await api.get<GetSellerProfileStateResponse>('/api/msa/enterprise/v1/seller-profile/state');

  return data;
};

export type PatchSellerProfileSectionParams = Partial<GetSellerProfileResponse>;

const patchSellerProfileSection = async (payload: PatchSellerProfileSectionParams) => {
  const { data } = await api.patch(
    '/api/msa/enterprise/v1/seller-profile/sections',
    payload,
  );

  return data;
};

export type PutSellerProfileSectionParams = Partial<GetSellerProfileResponse>;

const putSellerProfileSection = async (payload: PutSellerProfileSectionParams) => {
  const { data } = await api.put(
    '/api/msa/enterprise/v1/seller-profile/sections',
    payload,
  );

  return data;
};

const postSellerProfilePublish = async () => {
  const { data } = await api.post('/api/msa/enterprise/v1/seller-profile');

  return data;
};

type DeleteSectionName = Extract<
  SellerProfileSectionName,
  | 'skills'
  | 'serviceCategories'
  | 'projectHistories'
  | 'contacts'
  | 'portfolios'
  | 'licenses'
  | 'education'
  | 'careers'
  >;

const deleteSectionNameUrlMap: Record<DeleteSectionName, string> = {
  projectHistories: 'project-histories',
  licenses: 'licenses',
  education: 'education',
  serviceCategories: 'service-categories',
  portfolios: 'portfolios',
  careers: 'careers',
  contacts: 'contacts',
  skills: 'skills',
};

export interface DeleteSellerProfileSectionParams {
  sectionName: DeleteSectionName;
  id: number;
}

const deleteSellerProfileSection = async ({
  sectionName,
  id,
}: DeleteSellerProfileSectionParams) => {
  const { data } = await api.delete(`/api/msa/enterprise/v1/seller-profile/sections/${deleteSectionNameUrlMap[sectionName]}/${id}`);

  return data;
};

export type SellerProfileSelectionSectionName =
  | 'job-position'
  | 'amount-range'
  | 'person-scale-range'
  | 'school-type';

export interface GetSellerProfileSelectionsResponse {
  selectionName: string;
  options: {
    id: number;
    name: string;
  }[];
}

const getSellerProfileSelections = async (selectionSectionName: SellerProfileSelectionSectionName) => {
  const { data } = await api.get<GetSellerProfileSelectionsResponse>(`/api/msa/enterprise/v1/seller-profile/selections/${selectionSectionName}`);

  return data;
};

export const enterpriseSellerProfileApi = {
  getSellerProfile,
  getSellerProfileState,
  getSellerProfileSelections,
  patchSellerProfileSection,
  postSellerProfilePublish,
  putSellerProfileSection,
  deleteSellerProfileSection,
};
