import React from 'react';
import { buildJsonLdTags } from './jsonLds/buildJsonLdTags';
import type { BuildJsonLdTagsProps, BreadcrumbItemListJsonLd } from './jsonLds/buildJsonLdTags';

/**
 * @description JSON-LD 데이터를 `<script>` 태그로 렌더링하는 컴포넌트입니다.
 * `breadcrumbItemList`가 없을 경우 기본값을 사용하도록 설정합니다.
 *
 * @param {BuildJsonLdTagsProps} jsonLd - JSON-LD 데이터를 포함하는 객체.
 * `breadcrumbItemList`를 포함하지 않을 경우 기본값으로 대체됩니다.
 * @returns {JSX.Element} JSON-LD 스크립트 태그를 렌더링합니다.
 */
function JsonLdSEO(jsonLd: BuildJsonLdTagsProps) {
  const mergedJsonLd = {
    ...jsonLd,
    breadcrumbItemList: jsonLd?.breadcrumbItemList || defaultBreadcrumbItemList,
  };

  return (
    <>
      {buildJsonLdTags(mergedJsonLd)}
    </>
  );
}

const defaultBreadcrumbItemList: BreadcrumbItemListJsonLd = [
  {
    item: 'https://kmong.com',
    name: '홈',
    position: 1,
  },
];

export default JsonLdSEO;
