import type { SocialProviderType } from '@kmong/rest-client';

export enum BusinessUserStatus {
  WAITING = 'WAITING',
  REQUESTED = 'REQUESTED',
  ONGOING = 'ONGOING',
  REJECTED = 'REJECTED',
  ACTIVE = 'ACTIVE',
}

interface BaseAlreadySignupUser {
  email: string;
  signedAt: string;
  isBusinessUser: boolean;
  businessUserStatus: BusinessUserStatus | null;
}

export interface SocialAlreadySignupUser extends BaseAlreadySignupUser {
  socialProvider: SocialProviderType;
  connectSocialProvider: SocialProviderType;
  connectSocialProviderId: number;
}

export interface AlreadyMobileAuthUser extends BaseAlreadySignupUser {
  socialProvider: SocialProviderType;
}

export type AlreadySignupUser = SocialAlreadySignupUser | AlreadyMobileAuthUser;
