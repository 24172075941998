/* eslint-disable @typescript-eslint/ban-ts-comment */
import type { AlreadyMobileAuthUser } from './getAlreadySignupUsersCookie';

type OpenProfile = (UID: number) => void;

type HistoryBack = () => void;

type OnSimpleProfileRegisterSuccess = () => void;

type OpenSignIn = () => void;

type ShowEnterpriseRequestProject = () => void;

type CopyToClipboard = (text: string) => void;

type SendAndroidWebViewCustomEvent = (eventName: string, eventData: string) => void;

type SendIOSWebViewCustomEvent = (event: { name: string; param: string }) => void;

type OnProjectRegisterSubmit = () => void;

type OnProjectRegisterSuccess = () => void;

type ShowAlreadySignup = (stringifyEventData: string) => void;

type ShowAlreadySignupUsers = (stringifyEventData: string) => void;

type OnCounselAuth = (nextPage: string) => void;

type OnCounselRefundAccountRegister = (fullname: string) => void;

type OnCounselWithdrawalAccountRegister = () => void;

declare global {
  interface Window {
    kmongAndroid?: {
      openProfile: OpenProfile;
      historyBack: HistoryBack;
      onSimpleProfileRegisterSuccess: OnSimpleProfileRegisterSuccess;
      showEnterpriseRequestProject: ShowEnterpriseRequestProject;
      openSignIn: OpenSignIn;
      copyToClipboard: CopyToClipboard;
      sendWebViewCustomEvent: SendAndroidWebViewCustomEvent;
      onProjectRegisterSubmit: OnProjectRegisterSubmit;
      onProjectRegisterSuccess: OnProjectRegisterSuccess;
      showAlreadySignup: ShowAlreadySignup;
      showAlreadySignupUsers: ShowAlreadySignupUsers;
      onCounselAuth: OnCounselAuth;
      onCounselRefundAccountRegister: OnCounselRefundAccountRegister;
      onCounselWithdrawalAccountRegister: OnCounselWithdrawalAccountRegister;
    };

    webkit?: {
      messageHandlers: {
        openProfile: {
          postMessage: OpenProfile;
        };
        historyBack: {
          postMessage: HistoryBack;
        };
        onSimpleProfileRegisterSuccess: {
          postMessage: OnSimpleProfileRegisterSuccess;
        };
        showEnterpriseRequestProject: {
          postMessage: ShowEnterpriseRequestProject;
        };
        openSignIn: {
          postMessage: OpenSignIn;
        };
        sendWebViewCustomEvent: {
          postMessage: SendIOSWebViewCustomEvent;
        };
        onProjectRegisterSubmit: {
          postMessage: OnProjectRegisterSubmit;
        };
        onProjectRegisterSuccess: {
          postMessage: OnProjectRegisterSuccess;
        };
        showAlreadySignup: {
          postMessage: ShowAlreadySignup;
        };
        showAlreadySignupUsers: {
          postMessage: ShowAlreadySignupUsers;
        };
        onCounselAuth: {
          postMessage: OnCounselAuth;
        };
        onCounselRefundAccountRegister: {
          postMessage: OnCounselRefundAccountRegister;
        };
        onCounselWithdrawalAccountRegister: {
          postMessage: OnCounselWithdrawalAccountRegister;
        };
      };
    };
  }
}

const ua = typeof window !== 'undefined'
  && window.navigator
  && window.navigator.userAgent
  ? window.navigator.userAgent
  : '';

export const isKmongAndroidWebView = () => ua.match(/KmongAndroidApp/i) !== null;
export const isKmongiOSWebView = () => ua.match(/KmongiOSApp/i) !== null;
export const isKmongWebView = () => isKmongAndroidWebView() || isKmongiOSWebView();

const openProfile = (UID: number) => {
  if (!UID) {
    return;
  }

  if (isKmongAndroidWebView()) {
    window.kmongAndroid?.openProfile(UID);
  }

  if (isKmongiOSWebView()) {
    window.webkit?.messageHandlers.openProfile.postMessage(UID);
  }
};

const historyBack = () => {
  if (isKmongAndroidWebView()) {
    window.kmongAndroid?.historyBack();
  }

  if (isKmongiOSWebView()) {
    // @ts-ignore
    window.webkit?.messageHandlers.historyBack.postMessage('');
  }
};

const onSimpleProfileRegisterSuccess = () => {
  if (isKmongAndroidWebView()) {
    window.kmongAndroid?.onSimpleProfileRegisterSuccess();
  }

  if (isKmongiOSWebView()) {
    // @ts-ignore
    window.webkit?.messageHandlers.onSimpleProfileRegisterSuccess.postMessage('');
  }
};

const showEnterpriseRequestProject = () => {
  if (isKmongAndroidWebView()) {
    window.kmongAndroid?.showEnterpriseRequestProject();
  }

  if (isKmongiOSWebView()) {
    // @ts-ignore
    window.webkit?.messageHandlers.showEnterpriseRequestProject.postMessage('');
  }
};

const openSignIn = () => {
  if (isKmongAndroidWebView()) {
    window.kmongAndroid?.openSignIn();
  }

  if (isKmongiOSWebView()) {
    // @ts-ignore
    window.webkit?.messageHandlers.openSignIn.postMessage('');
  }
};

const kmongAndroidCopyToClipboard = (text: string) => {
  window.kmongAndroid?.copyToClipboard(text);
};

const sendWebViewCustomEvent = (eventName: string, eventData: { [key: string]: unknown }) => {
  const refinedEventData = refineEventData(eventData);

  if (isKmongAndroidWebView()) {
    window.kmongAndroid?.sendWebViewCustomEvent(eventName, JSON.stringify(refinedEventData));
  }

  if (isKmongiOSWebView()) {
    window.webkit?.messageHandlers.sendWebViewCustomEvent.postMessage({ name: eventName, param: JSON.stringify(refinedEventData) });
  }
};

const onProjectRegisterSubmit = () => {
  if (isKmongAndroidWebView()) {
    window.kmongAndroid?.onProjectRegisterSubmit();
  }

  if (isKmongiOSWebView()) {
    // @ts-ignore
    window.webkit?.messageHandlers.onProjectRegisterSubmit.postMessage('');
  }
};

const onProjectRegisterSuccess = () => {
  if (isKmongAndroidWebView()) {
    window.kmongAndroid?.onProjectRegisterSuccess();
  }

  if (isKmongiOSWebView()) {
    // @ts-ignore
    window.webkit?.messageHandlers.onProjectRegisterSuccess.postMessage('');
  }
};

const showAlreadySignup = (eventData: AlreadyMobileAuthUser) => {
  if (isKmongAndroidWebView()) {
    window.kmongAndroid?.showAlreadySignup?.(JSON.stringify(eventData));
  }

  if (isKmongiOSWebView()) {
    window.webkit?.messageHandlers.showAlreadySignup?.postMessage(JSON.stringify(eventData));
  }
};

const showAlreadySignupUsers = (eventData: { alreadySignupUsers: AlreadyMobileAuthUser[] }) => {
  if (isKmongAndroidWebView()) {
    window.kmongAndroid?.showAlreadySignupUsers?.(JSON.stringify(eventData));
  }

  if (isKmongiOSWebView()) {
    window.webkit?.messageHandlers.showAlreadySignupUsers?.postMessage(JSON.stringify(eventData));
  }
};

const onCounselAuth = (nextPage: string) => {
  if (isKmongAndroidWebView()) {
    window.kmongAndroid?.onCounselAuth(nextPage);
  }

  if (isKmongiOSWebView()) {
    window.webkit?.messageHandlers.onCounselAuth.postMessage(nextPage);
  }
};

const onCounselRefundAccountRegister = (fullname: string) => {
  if (isKmongAndroidWebView()) {
    window.kmongAndroid?.onCounselRefundAccountRegister(fullname);
  }

  if (isKmongiOSWebView()) {
    window.webkit?.messageHandlers.onCounselRefundAccountRegister.postMessage(fullname);
  }
};

const onCounselWithdrawalAccountRegister = () => {
  if (isKmongAndroidWebView()) {
    window.kmongAndroid?.onCounselWithdrawalAccountRegister();
  }

  if (isKmongiOSWebView()) {
    // @ts-ignore
    window.webkit?.messageHandlers.onCounselWithdrawalAccountRegister.postMessage('');
  }
};

export const webviewBridge = {
  kmongAndroidCopyToClipboard,
  isKmongAndroidWebView,
  isKmongiOSWebView,
  isKmongWebView,
  openProfile,
  openSignIn,
  historyBack,
  onSimpleProfileRegisterSuccess,
  showEnterpriseRequestProject,
  sendWebViewCustomEvent,
  onProjectRegisterSubmit,
  onProjectRegisterSuccess,
  showAlreadySignup,
  showAlreadySignupUsers,
  onCounselAuth,
  onCounselRefundAccountRegister,
  onCounselWithdrawalAccountRegister,
};

export const refineEventData = (eventData: { [key: string]: unknown }) => Object
  .entries(eventData)
  .reduce<{ [key: string]: unknown }>(
    (acc, [key, value]) => {
      acc[key] = transferType(value);

      return acc;
    },
    {},
  );

export const transferType = (value: unknown) => {
  const type = typeof value;

  switch (type) {
    case 'number':
      return String(value);
    case 'boolean':
      return value ? 'T' : 'F';
    case 'string':
      return value;
    case 'undefined':
      return '';
    default:
      return '';
  }
};
