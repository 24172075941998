import api from '../api';
import type { GradeType } from '../gig';

export enum InboxPartnerUserTypeEnum {
  SELLER = 'SELLER',
  BUYER = 'BUYER',
}

export type ColorChipType = 'RED' | 'YELLOW' | 'ORANGE' | 'GREEN' | 'BLUE'| 'PURPLE';

export interface GetInboxPartnersResponse {
  user: InboxPartnerUser;
  seller: InboxPartnerSeller;
  /**
  * @description max 3개
  */
  orders: InboxPartnerOrder[];
  /**
  * @description max 3개
  */
  gigs: InboxPartnerGig[];
}

interface InboxPartnerUser {
  userId: number;
  nickname: string;
  /**
   * 메시지 발송 내역/ 주문 내역에 따라 의뢰인 / 전문가 구분
   * @description 판매관리 / 구매관리 링크 연결에 사용
   */
  userType: InboxPartnerUserTypeEnum;
  memo: string;
  colorChip: ColorChipType | null;
  thumbnail: string;
  lastLoggedAt: string;
  isOnline: boolean;
  availableTimeFrom: number;
  availableTimeTo: number;
  avgResponseTime: string;
  isStarred: boolean;
  ongoingOrderCount: number;
  /**
   * @description 현재 인박스 그룹에 있는 전문가와 의뢰인 사이의 주문 건수
   */
  soldOrderCount: number;
  boughtOrderCount: number;
  /**
   * userId로 전문가 영역 노출 여부 판별
   * @description 전문가 영역 노출 여부
   */
  isSeller: boolean;
}

interface InboxPartnerSeller {
  reviewAverage: number;
  reviewCount: number;
  grade: GradeType;
  isFastReaction: boolean;
  vacatedAt: string | null;
  isCompanyUser: boolean;
  safeNumber: string | null;
  satisfactionPoint: number;
  /**
   * @description 총 판매완료 건수
   */
  soldOrderCount: number;
}

export interface InboxPartnerOrder {
  orderId: number;
  totalAmount: number;
  buyerUserId: number;
  sellerUserId: number;
  product: InboxPartnerProduct;
}

interface InboxPartnerProduct {
  productId: number;
  content: string;
  image: string;
}

interface InboxPartnerGig {
  gigId: number;
  userId: number;
  title: string;
  image: string;
  price: number;
  isPrime: boolean;
}

const inboxPartners = async (inboxGroupId: number, partnerUserId: number) => {
  const { data } = await api.get<GetInboxPartnersResponse>(`/api/inbox/v1/inbox-groups/${inboxGroupId}/partners/${partnerUserId}`);

  return data;
};

export interface InboxPartnersColorChipPayload {
  memo: string;
  color: ColorChipType;
}

const postInboxPartnersMemoColorChip = async (inboxGroupId: number, partnerUserId: number, payload: InboxPartnersColorChipPayload) => {
  const { data } = await api.post<{ inboxGroupId: number }>(`/api/msa/inbox-app/inbox-group/v1/inbox-groups/${inboxGroupId}/partners/${partnerUserId}/utilities`, payload);

  return data;
};

const deleteInboxPartnersColorChip = async (inboxGroupId: number, partnerUserId: number) => {
  const { data } = await api.delete<{ inboxGroupId: number }>(`/api/msa/inbox-app/inbox-group/v1/inbox-groups/${inboxGroupId}/partners/${partnerUserId}/utilities`);

  return data;
};

export type InboxPartnersColorChipColorPayload = Pick<InboxPartnersColorChipPayload, 'color'>;

export const partnersApi = {
  inboxPartners,
  postInboxPartnersMemoColorChip,
  deleteInboxPartnersColorChip,
};
