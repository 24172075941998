import { CookiesKeyEnum } from '@kmong-service/utils';
import {
  setCookie,
  getCookie, deleteCookie,
} from 'cookies-next';
import dayjs from 'dayjs';
import { authApi } from '../../auth';
import { asyncHttpContext } from '../../utils/asyncHttpContext';
import type { TokenRefreshResponse } from '../../auth';
import type { CookieValueTypes } from 'cookies-next';
import type { IncomingMessage, ServerResponse } from 'http';

interface HttpContextProps {
  req?: IncomingMessage;
  res?: ServerResponse;
}

export const getTokenByTokenRefresh = async (refreshToken: CookieValueTypes, context?: HttpContextProps) => {
  if (typeof refreshToken !== 'string') {
    return '';
  }

  const { data } = await authApi.tokenRefresh(refreshToken);
  const { req, res } = context ?? {};

  // 새로운 토큰 저장
  setJWTToken(data, {
    req,
    res,
  });

  return data.accessToken;
};

const setJWTToken = (
  {
    accessToken,
    refreshToken,
  }: TokenRefreshResponse,
  { req, res }: HttpContextProps,
) => {
  setCookie(CookiesKeyEnum.KMONG_ACCESS_TOKEN, accessToken, {
    expires: dayjs().add(1, 'year').toDate(),
    sameSite: 'strict',
    secure: true,
    ...(req && { req }),
    ...(res && { res }),
  });

  if (refreshToken) {
    setCookie(CookiesKeyEnum.KMONG_REFRESH_TOKEN, refreshToken, {
      expires: dayjs().add(1, 'year').toDate(),
      sameSite: 'strict',
      secure: true,
      ...(req && { req }),
      ...(res && { res }),
    });
  }
};

export const getAccessToken = () => {
  const asyncRequest = asyncHttpContext.req.get?.() ?? {} as IncomingMessage;

  const cookie = getCookie(CookiesKeyEnum.KMONG_ACCESS_TOKEN, { req: asyncRequest });

  return typeof cookie === 'string' ? cookie : '';
};

export const getRefreshToken = () => {
  const asyncRequest = asyncHttpContext.req.get?.() ?? {} as IncomingMessage;

  const cookie = getCookie(CookiesKeyEnum.KMONG_REFRESH_TOKEN, { req: asyncRequest });

  return typeof cookie === 'string' ? cookie : '';
};

export const removeTokenCookies = () => {
  const asyncResponse = asyncHttpContext.res.get?.() ?? {} as ServerResponse;

  deleteCookie(CookiesKeyEnum.KMONG_ACCESS_TOKEN, { res: asyncResponse });
  deleteCookie(CookiesKeyEnum.KMONG_REFRESH_TOKEN, { res: asyncResponse });
};
