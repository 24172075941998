import api from '../api';
import type {
  BuyerOrderStatusLabel,
  BuyerOrderStatusType,
  BuyerStatusSearchOptions,
  OrderDateTimes,
  OrderItemProductType,
  OrderStatusType,
  ProductType,
  OrderUserInfo,
  OrderHistoryCount,
} from '../types';

export const PRODUCT_THUMBNAIL_LABEL = {
  VOD: 'VOD',
  EBOOK: '전자책',
  TEMPLATE: '템플릿',
} as const;

export type ProductThumbnailLabelType = keyof typeof PRODUCT_THUMBNAIL_LABEL;

export type BuyerOrderItemStatus = OrderStatusType<BuyerOrderStatusType, BuyerOrderStatusLabel>;

export interface BuyerOrderItem {
  orderId: number;
  orderProgressId: number | null;
  seller: OrderUserInfo;
  buyer: OrderUserInfo;
  orderProduct: OrderItemProductType;
  totalAmount: number;
  orderStatus: BuyerOrderItemStatus;
  orderDateTimes: OrderDateTimes;
  totalPlatformFeeAmount: number;
}

interface PaginationDataType<T> {
  total: number;
  lastPage: number;
  page: number;
  pageSize: number;
  pageOption: {
    previous: boolean | null;
    next: boolean | null;
    hasPrevious: boolean | null;
  };
  contents: T[];
}

type OrderListResponse = PaginationDataType<BuyerOrderItem>;

export const BUYER_SEARCH_KIND_TYPE = {
  NICKNAME: 'NICKNAME',
  PRODUCT_TITLE: 'PRODUCT_TITLE',
} as const;

export type BuyerSearchKindType = keyof typeof BUYER_SEARCH_KIND_TYPE;

export interface GetBuyerOrderListParams {
  page: number;
  orderProductType?: ProductType;
  statusType?: BuyerStatusSearchOptions;
  keyword?: string;
  searchKindType?: BuyerSearchKindType;
  startDate?: string;
  endDate?: string;
}

const getBuyerOrderList = async (params: GetBuyerOrderListParams) => {
  // TODO 추후 jwt 완료되면 jwt 토큰 값 넣고 X-Kmong-Authorization 헤더 붙여야 함
  const { data } = await api.get<OrderListResponse>('/api/msa/order-app/order/v1/orders/buyer/histories', {
    params: {
      ...params,
      pageSize: 10,
    },
  });

  return data;
};

const getBuyerOrderCount = async () => {
  // TODO 추후 jwt 완료되면 jwt 토큰 값 넣고 X-Kmong-Authorization 헤더 붙여야 함
  const { data } = await api.get<OrderHistoryCount>('/api/msa/order-app/order/v1/orders/buyer/histories/count');

  return data;
};

interface PutMutualCancellationRetractProps {
  orderId: number;
  orderProgressId: number | null;
}

interface PutMutualCancellationRetractResponse {
  orderId: number;
  status: string;
  updated_at: string;
}

const putMutualCancellationRetract = async ({
  orderId,
  orderProgressId,
}: PutMutualCancellationRetractProps) => {
  const { data } = await api.put<PutMutualCancellationRetractResponse>(
    `/api/order/v1/orders/${orderId}/progress/mutual-cancellation/retract`,
    { orderProgressId },
  );

  return data;
};

export const buyerListApi = {
  getBuyerOrderList,
  getBuyerOrderCount,
  putMutualCancellationRetract,
};
