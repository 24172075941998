import https from 'https';
import Axios from 'axios';
import { axiosRequestInterceptor } from './lib/axios-interceptor-request';
import { axiosResponseInterceptor } from './lib/axios-interceptor-response';
import { isLocal } from './lib/utils';

const api = Axios.create({
  withCredentials: !isLocal(),
  ...(isLocal() && {
    httpsAgent: new https.Agent({
      rejectUnauthorized: false,
    }),
  }),
});

axiosRequestInterceptor(api);
axiosResponseInterceptor(api);

export default api;
