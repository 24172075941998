import api from '../api';
import type { CounselAreaType } from './my-kmong';
import type { PageParams, PageResponse } from '../types';

export interface CounselProduct {
  id: number;
  sellerUserId: number;
  title: string;
  extensionNumber: string;
  oneLiner: string;
  labels: string[];
  costPerInterval: number;
  thumbnail: string;
  counselStatus: CounselProductStatus;
  reviewCount: number;
}

export type CounselProductStatus = 'AVAILABLE' | 'COUNSELING' | 'ABSENT';

export type GetCounselProductsResponse = PageResponse<CounselProduct>;

interface GetCounselProductsPayload extends PageParams {
  categoryId: number;
}

const getCounselProducts = async ({ page = 1, perPage = 16, categoryId }: GetCounselProductsPayload) => {
  const { data } = await api.get<GetCounselProductsResponse>('/api/msa/gig-app/counsel/v1/products', {
    params: {
      page,
      perPage,
      categoryId,
    },
  });

  return data;
};

type GetCounselProductReviewsResponse = PageResponse<CounselReviewItem>;

export interface CounselReviewItem {
  id: number;
  comment: string;
  counselDurationSeconds: number;
  counselArea: CounselAreaType;
  createdDateTime: string;
  user: {
    userId: number;
    nickname: string;
    thumbnail: string;
  };
}

const getCounselProductReviews = async ({
  counselProductId,
  page = 1,
  perPage = 5,
}: GetCounselProductReviewsParams) => {
  const { data } = await api.get<GetCounselProductReviewsResponse>(`/api/msa/order-app/counsel/review/v1/products/${counselProductId}/reviews`, {
    params: {
      page,
      perPage,
    },
  });

  return data;
};

interface GetCounselProductReviewsParams extends PageParams {
  counselProductId: number;
}

export interface GetCounselStatusResponse {
  productId: number;
  counselStatus: CounselProductStatus;
}

const getCounselStatus = async (counselProductId: number) => {
  const { data } = await api.get<GetCounselStatusResponse>(`/api/msa/gig-app/counsel/v1/products/${counselProductId}/counsel-status`);

  return data;
};

export interface GetExistCounselMobileNumberResponse {
  isExistMobileNumber: boolean;
}

const getExistCounselMobileNumber = async () => {
  const { data } = await api.get<GetExistCounselMobileNumberResponse>('/api/msa/user-app/counsel/user/v1/users/mobile-number/exists');

  return data;
};

export interface GetCounselAuthResponse {
  counselUser: CounselUser | null;
  /**
   * 전화 상담 이용 여부
   */
  isCounselUser: boolean;
  /**
   * 전화 상담 전문가 여부
   */
  isCounselSeller: boolean;
}

interface CounselUser {
  coinAmount: number;
}

const getCounselAuth = async () => {
  const { data } = await api.get<GetCounselAuthResponse>('/api/msa/user-app/counsel/user/v1/users/me');

  return data;
};

const postCounselDirectConnection = async (extensionNumber: string) => {
  const { data } = await api.post<{ userid: number }>('/api/msa/user-app/counsel/user/v1/users/direct-connection', {}, {
    params: {
      extensionNumber,
    },
  });

  return data;
};

export interface GetCounselEventMileageGivenResponse {
  isGiven: boolean;
}

const getCounselEventMileageGiven = async (coinMileageProviderId: number) => {
  const { data } = await api.get<GetCounselEventMileageGivenResponse>(`/api/msa/order-app/counsel/coin/v1/event-mileages/${coinMileageProviderId}/given`);

  return data;
};

export enum PostCounselCoinEventMileagesErrorCode {
  ALREADY_GIVEN_COIN_MILEAGE_ERROR = 'ALREADY_GIVEN_COIN_MILEAGE_ERROR',
}

export interface PostCounselCoinEventMileagesPayload {
  coinAmount: number;
  coinCheckoutTypeId: number;
}

const postCounselCoinEventMileages = async (payload: PostCounselCoinEventMileagesPayload) => api.post<{ id: number }>('/api/msa/order-app/counsel/coin/v1/event-mileages', payload);

export const phoneCounselingApi = {
  getCounselProducts,
  getCounselProductReviews,
  getCounselStatus,
  getCounselAuth,
  postCounselDirectConnection,
  getCounselEventMileageGiven,
  postCounselCoinEventMileages,
  getExistCounselMobileNumber,
};
