import { clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import type { ClassValue } from 'clsx';

/**
 * clsx와 tailwind-merge를 사용하여 클래스를 합칩니다.
 * 사용법은 clsx와 동일합니다.
 * 다만, 동일한 class를 여러번 사용할 경우 가장 마지막에 사용한 class가 적용됩니다.
 * @param inputs
 * @returns
 */
export const cn = (...inputs: ClassValue[]) => twMerge(clsx(inputs));
