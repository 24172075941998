import api from '../api';
import {
  postReviewModal,
  putReviewModal,
  postReviewModalForSeller,
  postReviewModalAfterCancelForBuyer,
  postReviewModalAfterCancelForSeller,
  putReviewModalForSeller,
} from './review-modal';
import type { QuestionType } from '../buyer/buyerRequest';
import type {
  InstantDeliveryProductType,
  BuyerOrderStatusType,
  ActionUserType,
  OrderUserType,
  OrderUserInfo,
  SellerOrderStatusType,
  SellerOrderStatusLabel,
  BuyerOrderStatusLabel,
  OrderItemProductType,
  OrderStatusType,
  ResolutionMethodData,
  TaxRequest,
} from '../types';

export interface GetOrderDetailResponse {
  orderId: number;
  directOrderId: number;
  userType: OrderUserType;
  seller: OrderUserInfo;
  buyer: OrderUserInfo;
  orderProduct: OrderDetailProduct;
  orderPrices: OrderDetailPrices;
  orderStatus: OrderDetailStatus;
  orderInstAnswers: OrderInstAnswer[] | null;
  payments: OrderDetailPayments;
  userOrderAddress: OrderDetailUserOrderAddress | null;
  review: OrderDetailReview | null;
  reviewBuyer: OrderDetailReviewBuyer | null;
  orderEvaluations: OrderUserType | null;
  revision: OrderDetailRevision | null;
  orderDateTimes: OrderDetailDateTimes;
  orderProgresses: OrderDetailProgress[];
  deliveryFile: ReviewModalDeliveryFile | null;
}

export interface OrderInstAnswer {
  id: number;
  orderId: number;
  data: OrderInstAnswerData;
  status: boolean;
  createdDateTime: string;
  updatedDateTime: string;
}

export interface OrderInstAnswerData {
  title: string;
  type: QuestionType;
  mandatory: 'true' | 'false';
  contents: OrderInstAnswerContent[];
}

export interface OrderInstAnswerContent {
  filePath: string | null;
  fileName: string | null;
  value: string | number | null;
}

export interface OrderDetailRevision {
  availableCount: number;
  revisedCount: number;
  orderId: number;
  id: number;
  createdDateTime: string;
  updatedDateTime: string;
}

export interface OrderDetailReviewBuyer {
  rate: number;
  orderId: number;
  repliedDateTime: string;
  comment: string;
  sellerUserId: number;
  buyerUserId: number;
  gigId: number;
  reviewId: number;
  updatedDateTime: string;
  status: 'ACTIVE' | 'DELETED' | 'BLINDED';
}

export interface OrderDetailReview {
  modifiedFlag: boolean;
  orderId: number;
  rateModifiedDateTime: string;
  repliedDateTime: string;
  createdDateTime: string;
  sellerUserId: number;
  updatedDateTime: string;
  rate: number;
  isBoughtSellerPick: boolean;
  isAvailableToBuySellerPick: boolean;
  files: ReviewModalDeliveryFile[] | null;
  writtenFlag: boolean;
  comment: string;
  buyerUserId: number;
  gigId: number;
  reviewId: number;
  status: 'ACTIVE' | 'DELETED' | 'BLINDED';
  reviewScores: ReviewScore[];
}

export interface ReviewModalDeliveryFile {
  fileId: number;
  fileName: string;
  previewUrl: string | null;
  originFileName: string | null;
  time: string | null;
  fileDownloadUrl: string | null;
  fileExtensionFormat: string | null;
  fileSize: string | null;
}

export interface OldReviewModalDeliveryFile {
  FID: number;
  ori_fname: string | null;
  time: string | null;
  fname: string | null;
  preview_url: string | null;
}

export interface ReviewScore {
  type: 'SATISFACTION' | 'MANNER' | 'RESPONSE_TIME';
  title?: string;
  rate: number;
}

export interface VODSummaryType {
  totalTimelapseForHumans: string | null;
  totalLessonCount: number | null;
  expirationDays: number | null;
  vodType: string | null;
  totalTimelapseSec: number | null;
  title: string | null;
}

interface OrderDetailInstantDeliveryProductType extends InstantDeliveryProductType {
  vod: VODSummaryType | null;
}

interface CustomProject {
  requestId: number;
  proposalId: number;
}

export interface OrderDetailProduct extends OrderItemProductType {
  quantity: number | null;
  gigPackage: OrderDetailGigPackage | null;
  gigOptions: OrderDetailGigOptions;
  rootCategoryId: number;
  instantDeliveryProduct: OrderDetailInstantDeliveryProductType | null;
  customProject: CustomProject | null;
}

export interface OrderDetailGigOptions {
  additionalOptions: OrderDetailGigOption[] | null;
  options: OrderDetailGigOption[] | null;
}

export interface OrderDetailGigOption {
  quantity: number | null;
  price: string | null;
  days: number | null;
  description: string | null;
  id: string | null;
  type: string | null;
  title: string | null;
}

export interface OrderDetailGigPackage {
  packageType: string | null;
  title: string | null;
  description: string | null;
  price: string | null;
  days: number | null;
  revisions: number | null;
  upgradeOptions: OrderDetailUpgradeOption[];
}

interface UpgradeOptionExtraData {
  unit: string | null;
  max: number | null;
  min: number | null;
  infinite: boolean | null;
  step: number | null;
  isMandatory: boolean | null;
}

interface UpgradeOptionDescription {
  descriptionForBuyer: string;
  descriptionForSeller: string;
  title: string;
}

export interface OrderDetailUpgradeOption {
  id: number | null;
  extraData: UpgradeOptionExtraData | null;
  optionDescription: UpgradeOptionDescription | null;
  description: string | null;
  type: string | null;
  isShow: boolean | null;
  packageDescription: UpgradeOptionDescription | null;
  title: string;
  value: number | string | boolean;
  unit: string | null;
  upgradeOptionId: number | null;
  isPackageUpgradeOption: boolean | null;
  order: number | null;
}

export interface OrderDetailPrices {
  totalAmount: number;
  totalPrice: number;
  couponAmount: number;
  mileageAmount: number;
  platformFeeAmount: number | null;
}

export interface OrderDetailStatus extends OrderStatusType {
  status: SellerOrderStatusType | BuyerOrderStatusType;
  statusLabel: SellerOrderStatusLabel | BuyerOrderStatusLabel;
  isAvailableWritePhotoReview: boolean;
  isDirectlyDelivered: boolean;
  isWrittenCancelReview: boolean;
  visibleTaxRequestType: VisibleTaxRequestType;
}

type VisibleTaxRequestType = 'SERVICE_PLATFORM_FEE_TAX_REQUEST' | 'SERVICE_TAX_REQUEST' | 'PLATFORM_FEE_TAX_REQUEST' | 'NONE';

interface OrderDetailRecipient {
  startedDateTime: string;
  totalAmount: number;
  couponAmount: number;
  sellerUsername: string;
  quantity: number;
  mileageAmount: number;
  orderId: number;
  completedDateTime: string;
  title: string;
  paymentAmount: number;
  platformFeeAmount: number | null;
}

export interface DirectOrdersForPayment {
  directOrderId: number;
  parentDirectOrderId: number;
  payMethod: string;
  payMethodLabel: string;
  agreedFlag: boolean;
  status: number;
  applyTaxFlag: boolean;
  additionalPayFlag: boolean;
  amount: number;
  paidDateTime: string;
  orderPlatformFee: DirectOrderPlatformFee | null;
}

interface DirectOrderPlatformFee {
  directOrderId: number | null;
  orderId: number | null;
  platformFeeAmount: number | null;
  gigId: number | null;
  parentDirectOrderId: number | null;
  appliedType: PlatformFeeAppliedType | null;
  status: boolean | number | null;
}

type PlatformFeeAppliedType = 'INIT' | 'IMPOSED' | 'EXCEEDED_IMPOSED' | 'ZERO_IMPOSED' | 'PASS_IMPOSED' | 'EXCEEDED' | 'EXEMPTION' | 'EXCEPTED' | 'BUYER_FIXED_EXEMPTION' | 'PROMOTION_EXEMPTION' | 'MEMBER_BENEFIT_EXEMPTION';

export interface OrderDetailPayments {
  taxRequest: TaxRequest | null;
  platformFeeTaxRequest: TaxRequest | null;
  recipient: OrderDetailRecipient | null;
  directOrdersForPayment: DirectOrdersForPayment[] | null;
}

export interface OrderDetailUserOrderAddress {
  userId: number;
  orderId: number;
  receiverName: string;
  phoneNumber: string;
  postCode: string;
  address: string;
  addressExtra: string;
  message: string;
}

export interface OrderDetailDateTimes {
  startedAt: string | null;
  completedAt: string | null;
  completedDotAt: string | null;
  deliveredAt: string | null;
  expectedCompletedAt: string | null;
  expectedSysCanceledAt: string | null;
  expectedSysCompletedAt: string | null;
  createdAt: string;
  updatedAt: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const OrderDetailProgressActionTypes = {
  ONGOING: '진행중',
  READY_ORDER: '요청사항 미작성',
  DELIVERY: '작업물 도착',
  DELIVERY_REJECTION: '작업물 거절',
  DELIVERY_RETRACTION: '작업물 발송 철회',
  REVISION: '수정 요청',
  LATE_DELIVERY: '진행중',
  MUTUAL_CANCELLATION: '취소 요청',
  MUTUAL_CANCELLATION_REJECTION: '취소 거절',
  MUTUAL_CANCELLATION_ACCEPTATION: '취소 수락',
  MUTUAL_CANCELLATION_RETRACTION: '취소 철회',
  CANCELED_BY_BUYER: '주문 취소',
  CANCELED_BY_SELLER: '주문 취소',
  SYS_CANCELED: '주문 취소',
  ADDITIONAL_PAY_REQUEST: '추가 결제 요청',
  ADDITIONAL_PAY_RETRACTION: '추가 결제 철회',
  ADDITIONAL_PAY_ACCEPTATION: '추가 결제 수락',
  ADDITIONAL_PAY_REJECTION: '추가 결제 거절',
  ADDITIONAL_PAY_SUCCEED: '추가 결제 완료',
  TIME_MODIFICATION: '작업일 연장 요청',
  TIME_MODIFICATION_REJECTION: '작업일 연장 거절',
  TIME_MODIFICATION_ACCEPTATION: '작업일 연장 수락',
  TIME_MODIFICATION_RETRACTION: '작업일 연장 철회',
  RATING_SELLER: '리뷰 작성',
  RATING_SELLER_MODIFICATION: '리뷰 수정',
  RATING_REPLY: '평가 답변',
  ACTION_RATING_REPLY_MODIFICATION: '평가 답변 수정',
  COMPLETE_INSTRUCTION_ANSWERS: '작업전 요청사항 작성',
  IGNORE_INSTRUCTION_ANSWERS: '작업전 요청사항 작성 스킵',
  ORDER_EVALUATION: '주문 취소 평가',
  TROUBLESHOOTING: '분쟁 조정 중',
  COMPLETED: '거래 완료',
} as const;

export type OrderDetailProgressActionType = keyof typeof OrderDetailProgressActionTypes;

/**
 * @description DELIVERY 상태에는 여러 actionTypeLabel이 달릴수 있습니다.
 * 추후 다른 ActionType도 비슷한 케이스가 있다면 type 추가하여 union 으로 넣어주세요
 */
type ExtraDeliveryLabel = '작업물 발송' | '수강권 도착' | '수강권 발송' | '전자책 도착' | '전자책 발송';

type CompletedLabel = '구매 확정';

export type OrderDetailProgressActionTypeLabel =
  typeof OrderDetailProgressActionTypes[OrderDetailProgressActionType]
  | ExtraDeliveryLabel
  | CompletedLabel;

export interface AdditionalOptions {
  quantity: number | null;
  price: string | null;
  id: number | null;
  type: string | null;
  days: number | null;
  title: string | null;
}

export interface ResolutionSubContentData {
  description: string | null;
  days: number | null;
  resolutionProblemId: string | null;
  id: number | null;
  isSelected: boolean | null;
  name: string | null;
  situationalResolutionProblemId: number | null;
}

export interface ResolutionData {
  resolutionProblemMessage: string | null;
  changedExpectedDeliveredAt: string | null;
  expectedDeliveredAt: string | null;
  resolutionProblemId: number | null;
  days: number | null;
  message: string | null;
  subContent: ResolutionSubContentData | null;
  resolutionMethod: ResolutionMethodData | null;
  situationalResolutionProblemId: number | null;
}

export interface ExtraData {
  resolutionData: ResolutionData;
  additionalOptions: AdditionalOptions[] | null;
  selectedOptions: AdditionalOptions[] | null;
}

export interface OrderDetailProgress {
  orderProgressId: number | null;
  userId: number;
  message: string | null;
  orderId: number;
  actedUserType: ActionUserType;
  files: ReviewModalDeliveryFile[] | null;
  unReadFlag: boolean;
  actionType: OrderDetailProgressActionType | null;
  actionTypeLabel: OrderDetailProgressActionTypeLabel;
  originActionType: OrderDetailProgressActionType | null;
  actionStatus: number | null;
  actionUserId: number | null;
  actionUserType: ActionUserType;
  statusGuideText: string;
  actedDateTime: string | null;
  extraData: ExtraData | null;
  spamFlag: boolean;
  createdDateTime: string | null;
  updatedDateTime: string | null;
  readDateTime: string | null;
  statusGuideAdditionalText: string | null;
  fileRemainingDays: number | null;
}

const getOrderDetail = async (orderId: number) => {
  // TODO 추후 jwt 완료되면 jwt 토큰 값 넣고 X-Kmong-Authorization 헤더 붙여야 함
  const { data } = await api.get<GetOrderDetailResponse>(`/api/msa/order-app/order/v1/orders/${orderId}/detail`);

  return data;
};

export interface TaxRequestFormDataProps {
  userId: number;
  directOrderId: number;
  orderId: number;
  companyLicenseNumber: string;
  companyName: string;
  companyRepresentativeName: string;
  companyAddress: string;
  companyType: string;
  companyKind: string;
  companyEmail: string;
  companyManagerName: string;
  companyManagerContact: string;
  amount: number;
  isAuthAvailableTaxRequest: boolean;
}

const postTaxRequest = async (formData: TaxRequestFormDataProps) => {
  // TODO 추후 jwt 완료되면 jwt 토큰 값 넣고 X-Kmong-Authorization 헤더 붙여야 함
  await api.post(`/api/msa/order-app/order/v1/orders/${formData.orderId}/progress/tax?isAuthAvailableTaxRequest=${formData.isAuthAvailableTaxRequest}`, formData);
};

const putTaxRequest = async (formData: TaxRequestFormDataProps) => {
  // TODO 추후 jwt 완료되면 jwt 토큰 값 넣고 X-Kmong-Authorization 헤더 붙여야 함
  await api.put(`/api/msa/order-app/order/v1/orders/${formData.orderId}/progress/tax?isAuthAvailableTaxRequest=${formData.isAuthAvailableTaxRequest}`, formData);
};

export interface PutTaxIssueCodeProps {
  taxIssueCode: string;
  orderId: number;
}

const putTaxIssueCode = async ({ taxIssueCode, orderId }: PutTaxIssueCodeProps) => {
  // TODO 추후 jwt 완료되면 jwt 토큰 값 넣고 X-Kmong-Authorization 헤더 붙여야 함
  await api.put(`/api/msa/order-app/order/v1/orders/${orderId}/progress/tax/issue-code`, { taxIssueCode });
};

interface PutRequestModifyDeliveryProps extends Pick<OrderDetailProgress, 'orderProgressId'> {
  files: ReviewModalDeliveryFile[];
  message: string;
}

const putRequestModifyDelivery = async (orderId: number, formData: PutRequestModifyDeliveryProps) => {
  await api.put(`/api/order/v1/orders/${orderId}/progress/delivery/reject`, formData);
};

export * from './review-modal';

export interface RefundDetail {
  amount: number;
  hasRefundAccount: boolean;
  refundMethodTypeLabel: string;
  refundMethodType: string;
  paymentMethodTypeLabel: string;
  paymentMethodType: string;
}

export interface GetRefundDetailsResponse {
  refundDetails: RefundDetail[];
}

const getRefundDetails = async (orderId: number) => {
  const { data } = await api.get<GetRefundDetailsResponse>(`/api/msa/order-app/payment/v1/payments/buyer/${orderId}/refund-details`);

  return data;
};

export const orderDetailApi = {
  getOrderDetail,
  postTaxRequest,
  putTaxRequest,
  putTaxIssueCode,
  postReviewModal,
  putReviewModal,
  putRequestModifyDelivery,
  postReviewModalForSeller,
  putReviewModalForSeller,
  postReviewModalAfterCancelForBuyer,
  postReviewModalAfterCancelForSeller,
  getRefundDetails,
};
