import api from '../api';

export type RegisterProjectType = 'OUTSOURCING' | 'RESIDENT';

export interface ProjectRegisterItem {
  itemId: number;
  contents: string;
}

export interface ProjectRegisterResponseItem extends ProjectRegisterItem {
  isEtc: boolean;
}

export interface ProjectRegisterRequestItem extends ProjectRegisterItem {
  etc?: string | null;
}

interface Categories {
  id: number;
  name: string;
  type: RegisterProjectType;
  childCategories: ChildCategories[];
}

export interface ChildCategories {
  id: number;
  name: string;
}

export interface CategoriesResponse {
  categories: Categories[];
}

const getCategories = async () => {
  const { data } = await api.get<CategoriesResponse>('/api/custom-project/v2/categories');

  return data;
};

export interface CustomProjectSkillResponse {
  skills: ProjectRegisterItem[];
}

const getCustomProjectSkills = async () => {
  const { data } = await api.get<CustomProjectSkillResponse>('/api/msa/enterprise/v1/custom-project/skills');

  return data;
};

export type DefaultRequestFormatType = 'CATEGORY' | 'PROJECT_TYPE' | 'BUSINESS_TYPE' | 'TITLE';
export type OptionalRequestFormatType = 'POSITION' | 'PROJECT_TERM' | 'CAREER' | 'WORK_HOUR' | 'WORK_TYPE' | 'SUPPORT_EQUIPMENT' | 'PROJECT_CALENDAR' | 'AMOUNT' | 'PROJECT_PREPARATION' | 'PROJECT_DETAIL' | 'DEADLINE_CALENDAR' | 'REFERENCE' | 'SKILL' | 'PRIVATE';
export type RequestFormatType = DefaultRequestFormatType | OptionalRequestFormatType;

export interface RequestFormat<T = RequestFormatType> {
  formatId: number;
  type: T;
  isRequired: boolean;
  items: ProjectRegisterResponseItem[];
}

export interface RequestFormatParams {
  rootCategory: number;
  projectType: RegisterProjectType;
}

interface PlaceItem extends ProjectRegisterItem {
  childPlaces: ProjectRegisterItem[];
}

export interface CustomProjectWorkTypeResponse {
  places: PlaceItem[];
  meetingType: ProjectRegisterItem[];
}

const getCustomProjectWorkTypes = async () => {
  const { data } = await api.get<CustomProjectWorkTypeResponse>('/api/msa/enterprise/v1/custom-project/work-types');

  return data;
};

export interface RequestFormatResponse {
  formats: RequestFormat<OptionalRequestFormatType>[];
}

const getRequestFormat = async (params: RequestFormatParams) => {
  const { data } = await api.get<RequestFormatResponse>('/api/msa/enterprise/v1/custom-project/request-format', {
    params,
  });

  return data;
};

export interface RequestFormatFormResponse {
  formats: RequestFormat[];
}

const getRequestFormatForm = async (requestId: number, buyerId: number) => {
  const { data } = await api.get<RequestFormatFormResponse>(`/api/msa/enterprise/v1/custom-project/request-format/${requestId}/form`, {
    params: {
      buyerId,
    },
  });

  return data;
};

export interface DefaultRequestFormatResponse {
  formats: RequestFormat<DefaultRequestFormatType>[];
}

const getDefaultRequestFormat = async () => {
  const { data } = await api.get<DefaultRequestFormatResponse>('/api/msa/enterprise/v1/custom-project/request-format/default');

  return data;
};

export type TermType = 'MONTH' | 'DAY';

interface RequestsTerm {
  type: TermType | null;
  value: number | null;
}

interface RequestsCareer {
  careerItem: ProjectRegisterItem | null;
  count: number | null;
  monthlyPay: number | null;
}

export interface RequestsWorkType {
  meetingItem: ProjectRegisterItem | null;
  place: {
    rootPlace: ProjectRegisterItem | null;
    childPlace: ProjectRegisterItem | null;
    detail: string;
  } | null;
}

export interface RequestsFile {
  fileId: number;
  fname?: string;
  previewUrl?: string;
  download?: string;
}

export interface RequestsAnswerFormData {
  formatId: number;
  type: RequestFormatType;
  rootCategory?: number;
  childCategory?: number;
  projectType?: RegisterProjectType;
  answer?: string;
  items?: ProjectRegisterRequestItem[];
  term?: RequestsTerm;
  careers?: RequestsCareer[] | null;
  isGovernment?: boolean;
  workType?: RequestsWorkType;
  inputDate?: string;
  startDate?: string;
  endDate?: string;
  deadlineDate?: string;
  amount?: number;
  links?: string[];
  files?: RequestsFile[];
  isPrivate?: boolean;
}

interface RequestsFormData {
  buyerId: number;
  answers: RequestsAnswerFormData[];
}

export interface PostRequestsResponse {
  requestId: number;
}

const postRequests = async (formData: RequestsFormData) => {
  const { data } = await api.post<PostRequestsResponse>('/api/msa/enterprise/v1/custom-project/requests', formData);

  return data;
};

export interface RequestFormatAnswers {
  answers: RequestsAnswerFormData[];
}

const getRequestFormatAnswers = async (requestId: number, buyerId: number) => {
  const { data } = await api.get<RequestFormatAnswers>(`/api/msa/enterprise/v1/custom-project/request-format/${requestId}/answers`, {
    params: {
      buyerId,
    },
  });

  return data;
};

interface RequestFormatEditFormData extends RequestsFormData {
  requestId: number;
}

const patchRequestFormatEdit = async ({ requestId, ...payload }: RequestFormatEditFormData) => {
  const { data } = await api.patch(`/api/msa/enterprise/v1/custom-project/request-format/${requestId}/edit`, payload);

  return data;
};

export const projectRegisterApi = {
  getCategories,
  getCustomProjectWorkTypes,
  getCustomProjectSkills,
  getRequestFormat,
  getDefaultRequestFormat,
  getRequestFormatForm,
  getRequestFormatAnswers,
  postRequests,
  patchRequestFormatEdit,
};
