import type { BrowserOptions } from '@sentry/nextjs';

const isProduction = typeof process !== 'undefined'
  && (
    process.env?.NEXT_PUBLIC_KMONG_API_ENDPOINT === 'https://kmong.com'
    && process.env?.NEXT_PUBLIC_MODE === 'production'
  );

const KNOWN_CRAWLER_LIST = ['yeti', 'wordpress', 'python'];

const options: BrowserOptions = {
  environment: isProduction ? 'next-production' : 'next-local',
  tracesSampleRate: isProduction ? 0.04 : 1.0,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0.01,
  ignoreTransactions: [/.*check-latest-app-version.*/],
  denyUrls: [
    /google-analytics\.com/i, // Google Analytics 오류 무시
    /analytics\.google\.com/i, // Google Analytics Collect 관련 오류 무시
    /gtag\/collect/i, // gtag 관련 오류 무시
    /googletagmanager\.com/i, // Google Tag Manager 오류 무시
  ],
  beforeSend(event) {
    const userAgent = (
      event.request?.headers?.['User-Agent']
      || event.request?.headers?.['user-agent']
    ) ?? '';
    const agent = userAgent.toLowerCase();

    if (KNOWN_CRAWLER_LIST.some((crawler) => agent.indexOf(crawler) > -1)) {
      return null;
    }

    return event;
  },
};

export default {
  options,
};
