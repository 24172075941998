/* eslint-disable @typescript-eslint/no-explicit-any */

import { getDevice } from '../utils';

const isProduction = process.env.NEXT_PUBLIC_MODE === 'production';

declare global {
  interface Window {
    enp: any;
    ENP_VAR: unknown;
  }
}

const internalMobon = (
  eventType: string,
  eventKind: string,
  provider: 'kmong',
  properties?: {
    device: string;
    convType?: string;
    productName?: string;
    btnSelector?: string;
  },
) => {
  if (typeof window === 'undefined') {
    return null;
  }

  if (!isProduction) {
    return null;
  }

  if (!window.enp) {
    return null;
  }

  return window.enp(eventType, eventKind, provider, properties);
};

const pageView = () => {
  const device = getDevice() === 'PC' ? 'W' : 'M';
  internalMobon('create', 'common', 'kmong', { device });
  internalMobon('send', 'common', 'kmong');
};

export interface GigDataProps {
  PID: number;
  gig_title: string;
  price: number;
  dcPrice: number;
  main_image: string;
  cat1_name: string;
  cat2_name: string;
  cat3_name: string | null;
}

const viewMarketGigDetailMobon = (gigData: GigDataProps) => {
  window.ENP_VAR = {
    collect: {
      productCode: gigData.PID,
      productName: gigData.gig_title,
      price: gigData.price,
      dcPrice: gigData.dcPrice,
      imageUrl: gigData.main_image,
      topCategory: gigData.cat1_name,
      firstSubCategory: gigData.cat2_name,
      secondSubCategory: gigData.cat3_name,
    },
    conversion: { product: [] },
  };

  const device = getDevice() === 'PC' ? 'W' : 'M';

  /* 상품수집 */
  internalMobon('create', 'collect', 'kmong', { device });

  /* 찜 버튼 타겟팅 */
  internalMobon('create', 'wish', 'kmong', {
    device,
    btnSelector: '#cartBtn',
  });
};

const newMemberConv = () => {
  internalMobon('create', 'conversion', 'kmong', {
    device: 'b',
    convType: 'join',
    productName: 'join',
  });
  internalMobon('send', 'conversion', 'kmong', {
    device: 'b',
    convType: 'join',
    productName: 'join',
  });
};

export default {
  pageView,
  viewMarketGigDetailMobon,
  newMemberConv,
};
