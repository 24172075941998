import api from '../api';

export interface GetAvailableSelfMarketingResponse {
  isEnabled: boolean;
}

const getSelfMarketingIsAvailable = async () => {
  const { data } = await api.get<GetAvailableSelfMarketingResponse>('/api/v1/my-kmong/SELLER/available-self-marketing');

  return data?.isEnabled ?? false;
};

export interface SelfMarketingGig {
  gigId: number;
  title: string;
  image: string;
  category: {
    rootCategory: number;
    rootCategoryName: string;
    subCategory: number;
    subCategoryName: string;
    thirdCategory: number | null;
    thirdCategoryName: string | null;
  };
  selfMarketingCode: string | null;
}
export interface GetSelfMarketingGigsResponse {
  gigs: SelfMarketingGig[];
  lastPage: number;
  total: number;
}

export interface GetSelfMarketingGigsParams {
  page: number;
}

const getSelfMarketingGigs = async ({ page }: GetSelfMarketingGigsParams) => {
  const { data } = await api.get<GetSelfMarketingGigsResponse>('/api/event/self-marketing/v1/gigs', {
    params: {
      page,
    },
  });

  return data;
};

export interface SaveSelfMarketingEventResponse {
  gigId: number;
  selfMarketingCode: string;
}

const saveSelfMarketingEvent = async (gigId: number) => {
  const { data } = await api.post<SaveSelfMarketingEventResponse>(`/api/event/self-marketing/v1/${gigId}`);

  return data?.selfMarketingCode ?? '';
};

export interface GetSelfMarketingCodeParams {
  gigId: number;
  code: string;
}

export interface GetSelfMarketingCodeResponse {
  gigId: number;
  selfMarketingCode: string;
}

const getSelfMarketingCode = async ({ gigId, code }: GetSelfMarketingCodeParams) => {
  const { data } = await api.get<GetSelfMarketingCodeResponse>(`/api/event/self-marketing/v1/${gigId}/${code}`);

  return data;
};

export interface GetSelfMarketingExpectedProfitParams {
  categoryId: number;
  amount: number;
}

export interface Commission {
  amount: number;
  totalExpectedProfit: number;
  totalCommission: number;
  totalValueAddedTaxAmount: number;
  pgCommissionAmount: number;
  totalBenefitProfit: number | null;
}

export interface GetSelfMarketingExpectedProfitResponse {
  commission: Commission;
  selfMarketingCommission: Commission;
}

const getSelfMarketingExpectedProfit = async ({ categoryId, amount }: GetSelfMarketingExpectedProfitParams) => {
  const { data } = await api.get<GetSelfMarketingExpectedProfitResponse>('/api/msa/order-app/order/v1/payments/seller/commission/calculation/self-marketing', {
    params: {
      categoryId,
      amount,
    },
  });

  return data;
};

export const selfMarketingApi = {
  getSelfMarketingIsAvailable,
  getSelfMarketingGigs,
  saveSelfMarketingEvent,
  getSelfMarketingCode,
  getSelfMarketingExpectedProfit,
};
