import type { RelatedService } from '@kmong/rest-client';

const getRelatedServiceParams = (services: RelatedService) => Object.keys(services).map((key) => {
  if (key === 'PID' && 'PID' in services) {
    return `&related_gig_id=${services[key]}`;
  }

  if (key === 'portfolioId' && 'portfolioId' in services) {
    return `&related_portfolio_id=${services[key]}`;
  }

  if (key === 'proposalId' && 'proposalId' in services) {
    return `&related_custom_project_proposal_id=${services[key]}`;
  }

  if (key === 'resendProposal' && 'resendProposal' in services) {
    return '&re_proposal';
  }

  if (key === 'orderId' && 'orderId' in services) {
    return `&related_order_id=${services[key]}`;
  }

  return '';
}).join('') ?? '';

export default getRelatedServiceParams;
