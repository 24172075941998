import { captureException } from '@sentry/nextjs';
import { logTracker } from '../utils';
/* eslint-disable @typescript-eslint/no-explicit-any */

declare global {
  interface Window {
    gtag: any;
  }
}

export interface Gtag {
  (
    command: 'config' | 'set',
    target: string,
    config?: ControlParams | EventParams | CustomParams
  ): void;
  (command: 'set', config: CustomParams): void;
  (command: 'js', config: Date): void;
  (
    command: 'event',
    eventName: EventNames | string,
    eventParams?: ControlParams | EventParams | CustomParams
  ): void;
}

export interface CustomParams {
  [key: string]: any;
}

export interface ControlParams {
  groups?: string | string[];
  send_to?: string | string[];
  event_callback?: () => void;
  event_timeout?: number;
}

type EventNames =
  | 'add_payment_info'
  | 'add_to_cart'
  | 'add_to_wishlist'
  | 'begin_checkout'
  | 'checkout_progress'
  | 'exception'
  | 'generate_lead'
  | 'login'
  | 'page_view'
  | 'purchase'
  | 'refund'
  | 'remove_from_cart'
  | 'screen_view'
  | 'search'
  | 'select_content'
  | 'set_checkout_option'
  | 'share'
  | 'sign_up'
  | 'timing_complete'
  | 'view_item'
  | 'view_item_list'
  | 'view_promotion'
  | 'view_search_results';

export interface EventParams {
  checkout_option?: string;
  checkout_step?: number;
  content_id?: string;
  content_type?: string;
  coupon?: string;
  currency?: string;
  description?: string;
  fatal?: boolean;
  items?: Item[];
  method?: string;
  number?: string;
  promotions?: Promotion[];
  screen_name?: string;
  search_term?: string;
  shipping?: Currency;
  tax?: Currency;
  transaction_id?: string;
  value?: number;
  event_label?: string;
  event_category?: string;
}

type Currency = string | number;

export interface Item {
  brand?: string;
  category?: string;
  creative_name?: string;
  creative_slot?: string;
  id?: string;
  location_id?: string;
  name?: string;
  price?: Currency;
  quantity?: number;
}

export interface Promotion {
  creative_name?: string;
  creative_slot?: string;
  id?: string;
  name?: string;
}

const refineType = (item: { [key: string]: any }) => Object.values(item).map((value) => {
  switch (typeof value) {
    case 'boolean':
      return value ? 'T' : 'F';
    case 'undefined':
      return '';
    default:
      return value === null ? '' : value.toString();
  }
});

const internalGtag: Gtag = (...args) => {
  if (typeof window === 'undefined') {
    return;
  }

  if (!window.gtag) {
    return;
  }

  window.gtag(...args);
};

const pageview = (url: string) => {
  try {
    const GA_ID = process.env.NEXT_PUBLIC_GA_ID ?? '';
    const config: CustomParams = {
      page_path: url,
    };
    internalGtag('config', GA_ID, config);
  } catch (e) {
    captureException(e);
  }
};

export interface GAProperties {
  category: string;
  action: string;
  label?: string | { [key: string]: any };
  value?: string;
  nonInteraction?: boolean;
}
const sendGAEvent = ({
  category,
  action,
  label = '',
  value = '',
  nonInteraction = false,
}: GAProperties) => {
  try {
    const transformedLabel = typeof label !== 'string'
      ? refineType(label).join('/')
      : label;

    internalGtag('event', action, {
      event_category: category,
      ...(transformedLabel ? { event_label: transformedLabel } : {}),
      ...(value ? { value } : {}),
      nonInteraction,
    });
    logTracker({
      eventName: action,
      vender: 'ga',
      category,
      properties: transformedLabel,
    });
  } catch (e) {
    captureException(e);
  }
};

const sendGAConversion = (sendTo: string) => {
  if (process.env.NEXT_PUBLIC_MODE === 'production') {
    internalGtag('event', 'conversion', {
      send_to: sendTo,
    });
  }
};

const sendGAViewEvent = (items: Array<{ [key: string]: number | string }>) => {
  try {
    internalGtag('event', 'view_item', {
      items,
    });
  } catch (e) {
    captureException(e);
  }
};

export interface GtagOptions {
  optimize_id: string;
  allow_enhanced_conversions: boolean;
  user_id?: string;
}

const gtagInitialize = (GA_ID: string, options?: GtagOptions) => {
  if (typeof GA_ID === 'string') {
    try {
      internalGtag('config', GA_ID, options || {});
    } catch (e) {
      captureException(e);
    }
  }
};

const setUserData = (email: string) => {
  internalGtag('set', 'user_data', { email });
};

export default {
  pageview,
  sendGAEvent,
  sendGAViewEvent,
  gtagInitialize,
  sendGAConversion,
  setUserData,
};
