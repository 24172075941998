export const JWT_HEADER_STATUS_KEY = 'x-jwt-token-status';

export enum JWTTokenStatus {
  // 토큰이 만료됨 → 토큰 재발급 → 성공: 실패한 API 재호출 / 실패: 로그아웃
  EXPIRED = 'EXPIRED',
  // 토큰이 유효하지 않거나 변조 되었음 → 로그아웃
  INVALIDATE = 'INVALIDATE',
  // 인증 서버 문제 발생 → 로그아웃
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
}
